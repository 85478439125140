import React, { useRef, useState, useEffect } from 'react'

import Slider from 'react-slick'
// import BIRDS from 'vanta/dist/vanta.birds.min'
import { useAuth, useI18n, useDocument } from 'hooks'
import { styled, useTheme } from '@mui/styles'
import { Box, Typography, Stack, useMediaQuery, Button } from '@mui/material'
import { MButton } from 'components/_material-extend'

import { useNavigate, Link } from 'react-router-dom'
import { PATH_AUTH, PATH_PAGE } from 'routes/paths'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import docImg from 'assets/images/landing/doc.png'
import expertiseImg from 'assets/images/landing/expertise.png'
import forumImg from 'assets/images/landing/forum.png'
import pipelineImg from 'assets/images/landing/pipeline.png'
import pipelineImg2 from 'assets/images/landing/pipeline2.png'
import pipe1 from 'assets/images/landing/pipe1.png'
import pipe2 from 'assets/images/landing/pipe2.png'
import newsImg from 'assets/images/landing/news.png'

import docsTitleJSON from 'assets/data/docsTitle'

const Rect = ({ d, index }) => {
  const { t, getLanguage } = useI18n({ keyPrefix: 'home' })
  const theme = useTheme()
  const matches1355 = useMediaQuery(theme.breakpoints.down(1355))
  const matches1410 = useMediaQuery(theme.breakpoints.down(1410))
  const [hovered, setHovered] = useState(false)

  const handleOnHover = () => {
    setHovered(true)
  }
  const handleOnOut = () => {
    setHovered(false)
  }

  return (
    <Section
      onMouseOver={handleOnHover}
      onMouseLeave={handleOnOut}
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: `url(${d.banner}) center no-repeat`,
        backgroundSize: 'cover',
        gridArea: `grid-${index}`,
        borderRadius: 1,
        height: '100%',
        alignItems: 'center',
      }}
      // ref={hoverRef}
      // bg={d.banner}
      // sx={{ gridArea: `grid-${index}`, borderRadius: 1, height: '100%' }}
      // eslint-disable-next-line react/no-array-index-key
      key={`${d.key}-${index}`}
      className={`grid-${index}`}
    >
      <Stack
        sx={{
          height: '100%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        alignItems="center"
      >
        <Stack
          sx={{
            '&.landing-grid': {
              paddingBottom: '15px',
            },
            '&.special-landing-grid': {
              paddingBottom: '30px',
            },
            width: '100%',
            mt: 0,
            pt: index === 2 || index === 3 ? 1 : 1,
            pb: index !== 0 || index !== 1 ? 4 : 4,
            paddingLeft: index !== 1 ? '15px' : '40px',
            paddingRight: index !== 1 ? '15px' : '40px',
            height:
              index === 0 || index === 1
                ? '50%'
                : getLanguage === 'en'
                ? matches1410
                  ? '80%'
                  : '65%'
                : matches1410
                ? '80%'
                : '70%',
            background: 'radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)',
            boxShadow: 'inset -5px -5px 250px rgba(255, 255, 255, 0.02)',
            backdropFilter: 'blur(42px)',
            borderRadius: 'border-radius: 0px 0px 5px 5px',
          }}
          justifyContent="space-between"
          className={`grid-child-${index} ${index === 1 || index === 0 ? 'special-landing-grid' : 'landing-grid'}`}
        >
          <div>
            <Typography
              component={Link}
              to={d.path}
              sx={{
                color: '#fff',
                fontWeight: 700,
                // fontSize: index === 0 || index === 1 ? '36px' : '28px',
                // fontSize: matches1066 ? '18px' : matches1350 ? '20px' : '28px',
                lineHeight: '36px',
              }}
              variant="h4"
            >
              {t(`${d.key}.title`)}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                mt: 1,
                color: '#fff',
                fontWeight: 500,
                fontSize: getLanguage === 'vn' ? (matches1355 ? '12px' : '14px') : matches1355 ? '12px' : '14px',
              }}
            >
              {t(`${d.key}.title_des`)}
            </Typography>
          </div>
          {hovered && (
            <Button
              to={d.path}
              component={Link}
              sx={{
                // mt: 4,
                background: 'rgba(255, 255, 255, 0.86)',
                maxWidth: '100px',
                px: 1,
                py: 1,
                borderRadius: '2px',
                '&:hover': { background: 'rgba(255, 255, 255, 0.86)' },
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 900, fontSize: 10 }}>
                {t('explore')}
              </Typography>
            </Button>
          )}
        </Stack>
      </Stack>
    </Section>
  )
}

const banners = [
  {
    key: 'pipeline',
    banner: expertiseImg,
    path: '/pipeline-tracker',
  },
  {
    key: 'legal',
    banner: docImg,
    path: '/documents',
  },
  {
    key: 'forum',
    banner: forumImg,
    path: '/forums',
  },

  {
    key: 'boilerTemplate',
    banner: newsImg,
    path: '/contract',
  },
]

const banners2 = [
  {
    key: 'legal',
    banner: docImg,
    path: '/documents',
  },
  {
    key: 'boilerTemplate',
    banner: newsImg,
    path: '/contract',
  },
  {
    key: 'forum',
    banner: forumImg,
    path: '/forums',
  },
  {
    key: 'pipeline',
    banner: expertiseImg,
    path: '/pipeline-tracker',
  },
  {
    key: 'legalNews',
    banner: newsImg,
    path: '/news',
  },
]

const settings = {
  adaptiveHeight: true,
}

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  // overflow: 'hidden',
  padding: theme.spacing(0, 10),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 5),
  },
}))

const Section = styled(Box)(({ bg }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: `url(${bg}) center no-repeat`,
  backgroundSize: 'cover',
}))

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid!important',
  gridTemplateColumns: 'repeat(4,1fr)',
  gridTemplateRows: '50% 50%',

  gridTemplateAreas: `
    'grid-0 grid-1 grid-1 grid-2'
    'grid-0 grid-1 grid-1 grid-3'
  `,
  gap: 7,
  // '& .grid-child-2': {
  //   '& .MuiTypography-root': {
  //     textAlign: 'center',
  //   },
  // },
}))

const GridContainer2 = styled(Box)(() => ({
  display: 'grid!important',
  justifyContent: 'center',
  alignItems: 'center',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gridTemplateRows: 'auto',

  gridTemplateAreas: `
    'grid-0'
    'grid-1'
    'grid-2'
    'grid-3'
    'grid-4'
  `,
}))

export default function LandingBanner() {
  const navigate = useNavigate()
  const { t, getLanguage } = useI18n({ keyPrefix: 'home' })
  const theme = useTheme()
  const matches1350 = useMediaQuery(theme.breakpoints.down(1360))
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const matches1066 = useMediaQuery(theme.breakpoints.down(1066))

  const { isAuthenticated } = useAuth()
  const { handleUpdateType } = useDocument()

  const handleRedirect = type => {
    navigate(PATH_PAGE.documents)
    handleUpdateType(type)
  }

  const handleClick = path => {
    navigate(path)
  }

  return (
    <Root
      data-aos="fade-down-right"
      style={
        matches
          ? {
              minHeight: '60vh',
            }
          : {
              flexDirection: !matches1350 && 'row',
              display: !matches1350 && 'flex',
              justifyContent: !matches1350 && 'center',
              marginBottom: '40px',
              maxHeight: '500px',
            }
      }
    >
      <Box sx={{ maxWidth: '1285px' }}>
        {/* layout 2 */}
        {!matches ? (
          <GridContainer sx={{ minHeight: '500px', maxWidth: '1285px' }}>
            {banners.map((d, index) => {
              // if (d.key === 'pipeline') return
              return <Rect d={d} index={index} />
            })}
          </GridContainer>
        ) : (
          <GridContainer2 sx={{ width: '100%' }}>
            {banners2.map((d, index) => {
              // if (d.key === 'pipeline' || d.key === 'Areas') return
              return (
                <Section
                  bg={d.banner}
                  sx={{
                    gridArea: `grid-${index}`,
                    mt: 2,
                    mb: 2,
                    ml: 2,
                    mr: 2,
                    width: '95vw',
                    // height: '90vw',
                    borderRadius: '5px',
                  }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${d.key}-${index}`}
                  className={`grid-${index}`}
                >
                  <Stack sx={{ height: '100%' }} alignItems="center" justifyContent="space-between">
                    <Stack
                      sx={{ width: '80%', mb: 5, mt: 5, flex: 1 }}
                      // justifyContent={index === 0 ? 'center' : 'flex-start'}
                      className={`grid-child-${index}`}
                    >
                      <Typography variant="h2" sx={{ color: '#fff', mb: 4 }}>
                        {t(`${d.key}.title`)}
                      </Typography>
                      <Typography variant="h6" sx={{ color: '#fff' }}>
                        {t(`${d.key}.title_des`)}
                      </Typography>

                      <Box sx={{ mt: 4 }}>
                        {/* <Stack direction="row" spacing={1}>
                            {docsTitleJSON.map(item => (
                              <Typography
                                key={item.title}
                                onClick={() => handleRedirect(item.title)}
                                sx={{ color: '#fff', cursor: 'pointer' }}
                                variant="h6"
                              >
                                {getLanguage === 'en' ? item.title : item.titleVn}
                              </Typography>
                            ))}
                          </Stack> */}
                        <Stack>
                          <Button
                            href={d.path}
                            sx={{
                              background: 'rgba(255, 255, 255, 0.86)',
                              width: 120,
                              py: 1.5,
                              // px: 2,
                              borderRadius: '50px',
                              '&:hover': { background: 'rgba(255, 255, 255, 0.86)' },
                            }}
                          >
                            {/* {index === 0 ? ( */}
                            <Typography variant="subtitle1" sx={{ fontWeight: 900, fontSize: 10 }}>
                              {t('explore')}
                            </Typography>
                            {/* ) : null} */}
                          </Button>
                        </Stack>
                      </Box>
                    </Stack>
                  </Stack>
                </Section>
              )
            })}
          </GridContainer2>
        )}

        {/* // layout 1 */}
        {/* {!matches ? (
            <Section bg={pipelineImg2} sx={{ height: '92vh' }}>
              <Stack sx={{ height: '100%', margin: '0 auto' }} alignItems="center" justifyContent="space-between">
                <Stack sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', my: 5, flex: 1 }}>
                  <Typography variant="h2" sx={{ color: '#fff', mb: 2, textAlign: 'center' }}>
                    {t(`pipeline.title`)}
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#fff', textAlign: 'center', maxWidth: '50%' }}>
                    {t(`pipeline.title_des`)}
                  </Typography>
                </Stack>
                <Stack sx={{ height: '37vh' }} direction="row" justifyContent="center" alignItems="flex-end">
                  <Box sx={{ width: '40%' }}>
                    <img src={pipe2} alt="pipe2" />
                  </Box>
                  <Box sx={{ height: '100%', ml: -20 }}>
                    <img src={pipe1} alt="pipe1" />
                  </Box>
                </Stack>
              </Stack>
            </Section>
          ) : null} */}
      </Box>
      {/* <Typography variant="h2" sx={{ fontWeight: 900, fontSize: matches420 && '1.5rem' }} color="primary">
          VIETNAMLAWINSIGHT
        </Typography>
        <Typography variant="32" sx={{ fontWeight: 700, fontSize: matches420 && '1rem' }} color="primary">
          {t('banner.create-connect-share')}
        </Typography>
        <Typography sx={{ color: '#5A5A5A', mt: 2.5, mb: !matches ? 7.5 : 6 }} variant="body2">
          {t('banner.description')}
        </Typography> */}
    </Root>
  )
}
