import { Navigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
// routes
import { PATH_PAGE } from 'routes/paths'

// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Navigate to={PATH_PAGE.root} />
  }

  return <>{children}</>
}
