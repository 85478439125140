import React from 'react'
import { useComment, useAuth } from 'hooks'
import { Stack, Box, Typography, AvatarGroup, Avatar, IconButton } from '@mui/material'
import { MButton } from 'components/_material-extend'
import { ForumInputField } from './index'
import { useNavigate, useLocation } from 'react-router'

import CloseIcon from '@mui/icons-material/Close'

export default function ForumCommentInput({ postID, handleSubmitComments, commentID = null, inputReply = '' }) {
  const [sources, setSources] = React.useState([])
  const [form, setForm] = React.useState({ content: '' })

  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const location = useLocation().pathname

  React.useEffect(() => {
    if (!inputReply) return
    setForm({ ...form, content: `@${inputReply} ` })
  }, [inputReply])

  const handleClickImageUpload = (sources, files) => {
    setSources(sources)
    setForm({ ...form, files })
  }

  const handleRemove = id => {
    const getIndex = sources.findIndex(source => source.id === id)
    const newSource = sources.filter(source => source.id !== id)
    const newFiles = form.files
    newFiles.splice(getIndex, 1)

    setSources(newSource)
    setForm({ ...form, newFiles })
  }

  const handleChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleNotLogin = () => {
    if (window.confirm('You need to be authenticated to comment. Login now?')) {
      localStorage.setItem('prevPath', location)
      navigate('/auth/login')
    }
  }

  const handleSubmit = () => {
    if (commentID) {
      handleSubmitComments({ ...form, postID }, commentID)
    } else {
      handleSubmitComments({ ...form, postID })
    }
    setForm({ content: '' })
    setSources([])
  }
  return (
    <Box sx={{ my: 1, width: '100%' }}>
      <ForumInputField
        handleClickImageUpload={handleClickImageUpload}
        handleChange={handleChange}
        fieldValue={form.content}
        handleSubmit={isAuthenticated ? handleSubmit : handleNotLogin}
        isSingleFile={true}
      />

      <AvatarGroup
        max={5}
        variant="rounded"
        sx={{
          justifyContent: 'flex-end',
          ml: 7,
          mt: 1,
          '& .MuiAvatar-root': {
            width: 100,
            height: 100,
          },
        }}
      >
        {sources?.map(s => (
          <Box sx={{ position: 'relative' }} key={s.id}>
            <IconButton
              sx={{
                position: 'absolute',
                top: 5,
                right: 5,
                zIndex: 1,
                p: 0,
                backgroundColor: '#bfbfbf',
                '& svg': {
                  width: 18,
                  height: 18,
                  fill: '#fff',
                },
              }}
              onClick={() => handleRemove(s.id)}
            >
              <CloseIcon />
            </IconButton>
            <Avatar alt="img comment" src={s.source} variant="rounded" />
          </Box>
        ))}
      </AvatarGroup>
    </Box>
  )
}
