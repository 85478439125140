import React from 'react'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'

import { styled } from '@mui/styles'
import { Box, Avatar, Stack, Typography, Divider, IconButton } from '@mui/material'

import lineClamp from 'components/LineClamp'
import { MButton } from 'components/_material-extend'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import useAuth from 'hooks/useAuth'

const Container = styled(Stack)(() => ({}))

export default function GroupItem(props) {
  const { matchesMobile, lastItem, t } = props
  const { handleClick, title, groupID, numMembers, userID, members } = props
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const location = useLocation().pathname
  const join = userID ? (members.length ? members.filter(member => member === userID).length : false) : false

  const handleNotLogin = () => {
    if (window.confirm('You need to be authenticated to join a group. Login now?')) {
      localStorage.setItem('prevPath', location)
      navigate('/auth/login')
    }
  }

  const handleOnClick = () => {
    if (isAuthenticated) {
      handleClick({ id: groupID, value: join })
    } else {
      handleNotLogin()
    }
  }

  return (
    <Container direction={!matchesMobile ? 'row' : 'column'} alignItems="center" spacing={1}>
      <Box>
        <IconButton>
          <PersonIcon />
        </IconButton>
      </Box>
      <Stack
        direction={!matchesMobile ? 'row' : 'column'}
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        sx={{ width: '100%' }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            to={`${PATH_PAGE.group}/${groupID}`}
            component={RouterLink}
            variant="subtitle2"
            sx={{ flexGrow: 1, ...lineClamp(1) }}
            color="primary"
          >
            {title}
          </Typography>

          <Stack direction="row" alignItems="center" justifyContent={matchesMobile && 'center'} spacing={0.5}>
            <PeopleAltIcon sx={{ fontSize: 12 }} />
            <Typography variant="caption">
              {numMembers} {t('members')}
            </Typography>
          </Stack>
        </Box>
        <MButton variant={!join ? 'contained' : 'outlined'} sx={{ px: 2 }} onClick={handleOnClick}>
          <Typography variant="caption">{!join ? t('join') : t('joined')}</Typography>
        </MButton>
      </Stack>

      {matchesMobile && !lastItem ? (
        <Box sx={{ width: '100%' }}>
          <Divider />
        </Box>
      ) : (
        ''
      )}
    </Container>
  )
}
