import React from 'react'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { TitleField, Root } from './styles'
import { useI18n } from 'hooks'

const Container = styled(Box)(() => ({
  width: '100%',
  position: 'relative',
}))

const videoPipeline = 'https://lnt-store-new.s3.us-east-2.amazonaws.com/document/pipeline720p.mp4'

export default function LandingPipeline() {
  const { t } = useI18n({ keyPrefix: 'home.pipeline' })

  return (
    <Root sx={{ mt: 13 }}>
      <TitleField title={t('title')}>{t('title_des')}</TitleField>

      <Container>
        <a href="https://www.lntinternal.com/" target="_blank" rel="noreferrer">
          <video autoPlay muted loop style={{ width: '100%' }}>
            <source src={videoPipeline} type="video/mp4" />
          </video>
        </a>
      </Container>
    </Root>
  )
}
