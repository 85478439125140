import React from 'react'
import { styled } from '@mui/styles'
import { Box, Stack, Typography } from '@mui/material'
import { ForumActions } from './index'
import LineClamp from 'components/LineClamp'
import { Link } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'

const Container = styled(Box)(() => ({
  width: '100%',
}))

export default function ForumRelatedTopicItem(props) {
  const { post, handleSharePost, handleNavigate } = props
  const { _id, title, numComments } = post

  return (
    <Container sx={{ p: 0 }}>
      <Stack spacing={0.5}>
        <Typography
          variant="subtitle2"
          sx={{ mb: -0.5, ...LineClamp(1) }}
          component={Link}
          to={`${PATH_PAGE.forums}/${_id}`}
        >
          {title}
        </Typography>
        <Box sx={{ ml: 0.5 }}>
          <ForumActions
            handleNavigate={() => handleNavigate(_id)}
            numComments={numComments}
            handleShare={() => handleSharePost(_id)}
          />
        </Box>
      </Stack>
    </Container>
  )
}
