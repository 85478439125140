import * as React from 'react'
import {
  Stack,
  Box,
  Typography,
  ListItemIcon,
  IconButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useI18n } from 'hooks'

const ActionItem = ({ icon, children, ...props }) => {
  return (
    <MenuItem {...props}>
      <ListItemIcon sx={{ minWidth: 20 }}>{icon}</ListItemIcon>
      <Typography variant="caption">{children}</Typography>
    </MenuItem>
  )
}
export default function ForumCommentActions(props) {
  const { comment, isReply = false, anchor, handleToggle, handleEditComment, handleDeleteComment } = props
  const { t } = useI18n({ keyPrefix: 'forum' })

  const open = Boolean(anchor)

  const handleEditCustom = e => {
    console.log('handleEditCustom', props)
    handleToggle(e)
    handleEditComment(comment, isReply)
  }
  const handleDeleteCustom = e => {
    handleToggle(e)
    handleDeleteComment(comment, isReply)
  }

  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ position: 'relative' }}>
        <IconButton
          className="BtnActions"
          onClick={e => handleToggle(e, isReply)}
          sx={{ p: 0, opacity: 0, transition: 'all 300ms ease', '& svg': { fontSize: 12 } }}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchor}
          open={open}
          onClose={e => handleToggle(e, isReply)}
        >
          <MenuList autoFocusItem={open} sx={{ py: 0 }}>
            <ActionItem icon={<EditIcon sx={{ fontSize: 14 }} />} onClick={handleEditCustom}>
              {t('input.edit')}
            </ActionItem>
            <ActionItem icon={<DeleteIcon sx={{ fontSize: 14 }} />} onClick={handleDeleteCustom}>
              {t('input.delete')}
            </ActionItem>
          </MenuList>
        </Menu>
      </Box>
    </Stack>
  )
}
