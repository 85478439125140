import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
// import './fonts/Lato_Black.tff'
// import './fonts/Lato_Bold.tff'
// import './fonts/Lato_Hairline.tff'
// import './fonts/Lato_Heavy.tff'
// import './fonts/Lato_Italic.tff'
// import './fonts/Lato_Light.tff'
// import './fonts/Lato_Medium.tff'
// import './fonts/Lato_Regular.tff'
// import './fonts/Lato_Semibold.tff'
// import './fonts/Lato_Thin.tff'
import App from './App'
import store from 'store'

import 'locales/i18n'
import { Provider } from 'react-redux'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
