import React from 'react'
import { useAuth, usePost } from 'hooks'
import { useSnackbar } from 'notistack'
import { PATH_PAGE } from 'routes/paths'
import Aos from 'aos'
import { Outlet, useLocation } from 'react-router-dom'
import { styled, useTheme } from '@mui/styles'
import { Box } from '@mui/material'

// component
import HeaderLayout from './Header'
import FooterLayout from './Footer'
import 'aos/dist/aos.css'
import 'assets/css/LazyLoad.css'

const RootStyle = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  margin: 'auto',
  // alignItems: 'center',
  // flexDirection: 'column',
  // display: 'flex',
  // marginTop: 85,
  marginTop: 67,
}))

export default function index() {
  const location = useLocation()
  const { messages } = usePost()
  const { enqueueSnackbar } = useSnackbar()
  const { handleGetAuthentication } = useAuth()

  React.useEffect(() => {
    handleGetAuthentication()
  }, [])

  React.useEffect(() => {
    Aos.init({ duration: 500 })
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  React.useEffect(() => {
    if (!messages) return
    enqueueSnackbar(messages?.message, { variant: messages?.status })
  }, [messages])

  return (
    <RootStyle>
      <HeaderLayout />
      <div>
        <Outlet />
      </div>
      {location.pathname !== PATH_PAGE.forums ? <FooterLayout /> : ''}
    </RootStyle>
  )
}
