// routes
import { PATH_PAGE } from 'routes/paths'
// components
// import { ReactComponent as SearchIcon } from 'assets/images/dashboard/IconsSidebar/search.svg'

const navigateMain = [
  { titleVn: 'Trang chủ', title: 'Home', path: '/' },
  { titleVn: 'Tài liệu pháp lý', title: 'Your Toolkit', path: PATH_PAGE.documents },
  { titleVn: 'Chuyên ngành', title: 'Areas', path: '' },
  // { hidden: true, titleVn: 'Hợp đồng', title: 'Contract', path: PATH_PAGE.contract },
  { hidden: true, titleVn: 'Diễn dàn', title: 'Forums', path: PATH_PAGE.forums },
  { titleVn: 'Tin tức', title: 'News', path: PATH_PAGE.news },
  { titleVn: 'Chức năng', title: 'Innovation', path: '/forums' },
  { hidden: true, titleVn: 'Liên hệ', title: 'Contact Us', path: PATH_PAGE.contact },
  { titleVn: 'Thông tin', title: 'About Us', path: PATH_PAGE.about },
]

export default navigateMain
