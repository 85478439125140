import React from 'react'
import { useSnackbar } from 'notistack'

import { useDispatch, useSelector } from 'react-redux'
import { SET_ALERT_JOINED_GROUP } from 'store/reducers/GroupSlice'
import { PATH_PAGE } from 'routes/paths'

import {
  GET_POSTS,
  GET_POST_BY_ID,
  GET_POST_TOPICS,
  GET_POSTS_BY_USER,
  GET_POSTS_RELATED_BY_GROUP,
  GET_POSTS_TRENDING,
  CREATE_POSTS,
  EDIT_POSTS,
  DELETE_POSTS,
  UP_DOWN_VOTE,
  HANDLE_CREATE_POST,
  HANDLE_EDIT_POST,
  HANDLE_DELETE_POST,
  HANDLE_MESSAGE,
  HANDLE_SAVE_POST_TEMPORARY,
  HANDLE_JOIN_GROUP,
} from 'store/reducers/PostSlice'

export default function usePost() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  // redux
  const posts = useSelector(state => state.post.posts)
  const postsTrending = useSelector(state => state.post.postsTrending)
  const postsGroup = useSelector(state => state.post.postsGroup)
  const postsTopic = useSelector(state => state.post.postsTopic)
  const postDetail = useSelector(state => state.post.postDetail)
  const postsProfile = useSelector(state => state.post.postsProfile)
  const postsRelatedByGroup = useSelector(state => state.post.postsRelatedByGroup)

  const alertJoinedGroup = useSelector(state => state.group.alertJoinedGroup)

  // --------
  const isLoading = useSelector(state => state.post.isLoading)
  const isNotFound = useSelector(state => state.post.isNotFound) // status delete post detail
  const messages = useSelector(state => state.post.messages)

  const isDeletePost = useSelector(state => state.post.isDeletePost)
  const isEditPost = useSelector(state => state.post.isEditPost)
  const isCreatePost = useSelector(state => state.post.isCreatePost)
  const savePostAction = useSelector(state => state.post.savePostTemporary)

  const handleOpenAlertJoinedGroup = (open, id) => dispatch(SET_ALERT_JOINED_GROUP(open, id))
  const handleGetPosts = () => dispatch(GET_POSTS())
  const handleGetTopics = () => dispatch(GET_POST_TOPICS())
  const handleGetPostById = id => dispatch(GET_POST_BY_ID(id))
  const handleGetPostByUser = userID => dispatch(GET_POSTS_BY_USER(userID))
  const handleGetPostRelatedByGroup = groupID => dispatch(GET_POSTS_RELATED_BY_GROUP(groupID))
  const handleGetTrendingToday = () => dispatch(GET_POSTS_TRENDING())

  const handleActionPost = (nameState, actionName, id = null) => {
    if (!actionName) return dispatch(HANDLE_MESSAGE(''))
    actionName === 'delete' && dispatch(HANDLE_DELETE_POST())
    actionName === 'edit' && dispatch(HANDLE_EDIT_POST())
    if (actionName === 'create') return dispatch(HANDLE_CREATE_POST())

    if (nameState === 'posts') {
      const getPost = posts.filter(post => post?._id === id)
      dispatch(HANDLE_SAVE_POST_TEMPORARY(getPost[0]))
    }
    if (nameState === 'postsGroup') {
      const getPost = postsGroup.filter(post => post?._id === id)
      dispatch(HANDLE_SAVE_POST_TEMPORARY(getPost[0]))
    }
    if (nameState === 'postsProfile') {
      const getPost = postsProfile.filter(post => post?._id === id)
      dispatch(HANDLE_SAVE_POST_TEMPORARY(getPost[0]))
    }
    if (nameState === 'postDetail') {
      dispatch(HANDLE_SAVE_POST_TEMPORARY(postDetail.post))
    }
  }

  const handlePostsSubmit = (nameState, actionName, form) => {
    if (actionName === 'delete') {
      const { _id } = savePostAction
      return dispatch(DELETE_POSTS(nameState, _id))
    }

    if (actionName === 'edit') {
      const { _id, files, ...oldForm } = form
      return dispatch(EDIT_POSTS(nameState, _id, oldForm))
    }

    // create
    dispatch(CREATE_POSTS(nameState, form))
  }

  const handleUpDownVote = (nameState, postID, type) => dispatch(UP_DOWN_VOTE(nameState, postID, type))

  const handleSharePost = id => {
    navigator.clipboard.writeText(`${window.location.origin}${PATH_PAGE.forums}/${id}`)
    enqueueSnackbar('Copy', { variant: 'success' })
  }

  const handleJoin = async ({ id, value, acceptJoined }) => {
    // value = true: joined
    if (!value) return dispatch(HANDLE_JOIN_GROUP(id, false))

    // alert if out group
    if (!acceptJoined) return dispatch(SET_ALERT_JOINED_GROUP({ open: true, id }))

    dispatch(HANDLE_JOIN_GROUP(id, value))
  }

  return {
    posts,
    postsGroup,
    postsProfile,
    postsTopic,
    postDetail,
    postsRelatedByGroup,
    postsTrending,

    alertJoinedGroup,

    isNotFound,
    isLoading,
    messages,

    isDeletePost,
    isEditPost,
    isCreatePost,
    savePostAction,

    handleGetPostById,
    handleGetTrendingToday,
    handleGetPostByUser,
    handleGetPostRelatedByGroup,
    handleGetPosts,
    handleGetTopics,
    handleActionPost,
    handlePostsSubmit,
    handleUpDownVote,
    handleSharePost,
    handleJoin,
    handleOpenAlertJoinedGroup,
  }
}
