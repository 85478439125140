import React, { useState } from 'react'
import LazyLoad from 'react-lazyload'
import { styled, useTheme } from '@mui/styles'
import { Box, Typography, useMediaQuery, Grid, Stack } from '@mui/material'
import { TitleField, Root } from './styles'
import { PATH_PAGE } from 'routes/paths'

import forums from 'assets/images/demo/forums.png'
import contract from 'assets/images/demo/contract.png'
import pipeline from 'assets/images/demo/pipeline.png'

import Rectangle1 from 'assets/images/contact/banner.png'
import Rectangle2 from 'assets/images/landing/Rectangle14.png'
import Rectangle3 from 'assets/images/landing/Rectangle15.png'
import Rectangle4 from 'assets/images/landing/auth.png'

import { useDocument, useI18n } from 'hooks'
import { useNavigate } from 'react-router-dom'

import innovation from 'assets/data/innovation'
import Button from '@material-ui/core/Button'

const bg = [forums, pipeline, contract, '']
const bg2 = [Rectangle1, Rectangle2, Rectangle3, Rectangle4]

const Underline = styled(Box)(({ theme }) => ({
  width: 80,
  height: 6,
  background: theme.palette.primary.main,
  marginBottom: theme.spacing(1.75),
}))

const GridContainer = styled(Box)(({ theme }) => ({
  height: 510,
  display: 'grid!important',
  gridTemplateColumns: '9fr 2fr 9fr',
  gridTemplateRows: '15px 15px 15px 15px',

  gridTemplateAreas: `
    'grid-0 grid-9 grid-1'
    'grid-0 grid-9 grid-2'
    'grid-0 grid-9 grid-3'
    'grid-0 grid-9 grid-4'
  `,
  gap: 15,
}))

export default function LandingInnovation({ closeDropdown }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down(1300))
  const matchesMobile = useMediaQuery(theme.breakpoints.down('md'))
  const resMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [hoveredIndex, setHoveredIndex] = useState(0)

  const navigate = useNavigate()
  const { t, getLanguage } = useI18n({ keyPrefix: 'home.legal' })
  const { handleUpdateType } = useDocument()

  const handleHover = index => {
    setHoveredIndex(index)
  }

  const handleRedirect = path => {
    if (path) {
      navigate(path)
      closeDropdown()
    }
  }

  return (
    <Root sx={{ padding: '0px !important', justifyContent: 'center', height: '100%' }}>
      <GridContainer>
        <TitleField isDropdown grid={0} title={innovation[hoveredIndex].title}>
          {innovation[hoveredIndex].description}
        </TitleField>
        <div
          style={{ gridArea: 'grid-9', height: 510, marginRight: 30, alignItems: 'center', justifyContent: 'center' }}
        >
          <div style={{ marginRight: 1, borderRight: '1px solid #eeeded', height: 510 }} />
        </div>
        {innovation.map((item, index) => {
          return (
            // <LegalItem
            //   index={index + 1}
            //   key={d.title}
            //   title={getLanguage === 'en' ? d.title : d.titleVn}
            //   text={d.text}
            //   mobile={matchesMobile}
            //   resMobile={resMobile}
            //   bg={bg[index]}
            //   xs={(matchesMobile && 12) || 6}
            //   float={!matchesMobile && d.float}
            //   handleRedirect={() => handleRedirect(d.title)}
            // >
            //   {getLanguage === 'en' ? d.text : d.textVn}
            // </LegalItem>
            <Button
              onMouseOver={() => handleHover(index)}
              // className="option"
              onClick={() => handleRedirect(item.path)}
              sx={{
                color: hoveredIndex === index ? '#ca3636 !important' : 'black !important',
                marginTop: 5,
                gridArea: `grid-${index + 1}`,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                fontWeight: 'bold',
                backgroundColor: 'white',
                padding: '0 !important',
              }}
            >
              <Typography
                sx={{
                  borderRadius: 1,
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: 'black',
                }}
              >
                {item.title}
              </Typography>
            </Button>
          )
        })}
      </GridContainer>
    </Root>
  )
}
