import React from 'react'
import { useI18n } from 'hooks'
import { styled } from '@mui/styles'
import { Stack, CircularProgress, Typography } from '@mui/material'
import { ForumCommentItem, ForumCommentDialogDelete } from './index'
import { useLocation } from 'react-router-dom'

const Container = styled(Stack)(() => ({}))

export default function ForumComments(props) {
  const { t } = useI18n({ keyPrefix: 'forum' })
  const {
    comments,
    user,
    handleNumLikeComment,
    handleEditComment,
    handleDeleteComment,
    handleSubmitComments,
    handleFocusCommentByState,
    // page
    totalPageComment,
    postID,
    currentPage,
    handleCurrentPage,
    handleGetComments,

    // reply
    handleMoreReply,
    handlerToggleReply,
    handleEditReply,
    handleDeleteReply,
    handleNumLikeReply,
  } = props

  // const [replyInput, setReplyInput] = React.useState(null)
  const [inputReply, setInputReply] = React.useState('')
  const location = useLocation()
  const refs = comments.length
    ? comments.reduce((acc, value) => {
        acc[value._id] = React.createRef()
        return acc
      }, {})
    : ''

  // scroll
  React.useEffect(() => {
    if (!comments.length) return
    if (!location?.state) return
    console.log('comments', comments, location)
    // const timer = setTimeout(() => {
    //   window.scrollTo({ top: refs[location?.state]?.current?.offsetTop - 100, behavior: 'smooth' })
    //   handleFocusCommentByState(location?.state)
    // }, 1000)
    // return () => clearTimeout(timer)
  }, [location])

  const handleReplySameLevel = reply => {
    setInputReply(reply?.createByName)
    handlerToggleReply()
  }

  const [disabledComment, setDisabledComment] = React.useState(false)
  // edit
  const [isEditIndex, setIsEditIndex] = React.useState({ id: null, content: '' })
  const [isEditReplyIndex, setIsEditReplyIndex] = React.useState({ id: null, content: '' })

  // delete
  const [isDeleteID, setIsDeleteID] = React.useState(null)
  const [isDeleteReply, setIsDeleteReply] = React.useState(false)
  const [isDisabledForm, setIsDisabledForm] = React.useState(false)
  const open = Boolean(isDeleteID)

  const handleToggleEdit = (comment, isReply) => {
    if (isReply) {
      const reply = comment
      console.log('handleToggleEdit reply', reply, isReply)

      if (!reply) return setIsEditReplyIndex({ id: null, content: '' })
      return setIsEditReplyIndex({ id: reply?._id, content: reply?.content })
    }
    if (!comment) return setIsEditIndex({ id: null, content: '' })
    setIsEditIndex({ id: comment?._id, content: comment?.content })
  }

  const handleToggleDelete = (comment, isReply = false) => {
    isReply && setIsDeleteReply(!isDeleteReply)
    setIsDeleteID(isDeleteID ? null : comment?._id)
  }

  const handleChangeFormEdit = (e, isReply) => {
    !isReply
      ? setIsEditIndex({ ...isEditIndex, content: e.target.value })
      : setIsEditReplyIndex({ ...isEditReplyIndex, content: e.target.value })
  }

  const handleSubmitFormEdit = async (isReply = false) => {
    setIsDisabledForm(true)
    if (!isReply) {
      await handleEditComment({ ...isEditIndex, PostId: comments[0]?.PostId })
      await handleToggleEdit()
    } else {
      await handleEditReply(isEditReplyIndex)
      await handleToggleEdit(null, true)
    }

    await setIsDisabledForm(false)
  }

  const handleDeleteCommentCustom = async isReply => {
    console.log('handleDeleteCommentCustom s', isReply)
    setIsDisabledForm(true)
    if (!isReply) {
      await handleDeleteComment({ id: isDeleteID, PostId: comments[0]?.PostId })
      await handleToggleDelete()
    } else {
      await handleDeleteReply({ id: isDeleteID })
      await handleToggleDelete(null, true)
    }

    await setIsDisabledForm(false)
  }

  const handleMoreComment = () => {
    setDisabledComment(true)
    setTimeout(async () => {
      await handleCurrentPage(currentPage + 1)
      await handleGetComments(postID, currentPage + 1)
      await setDisabledComment(false)
    }, 500)
  }

  return (
    <Container spacing={1.5}>
      {comments.length
        ? comments.map((comment, ind) => (
            <ForumCommentItem
              key={comment?._id}
              ind={ind}
              comment={comment}
              user={user}
              inputReply={inputReply}
              handleSubmitComments={handleSubmitComments}
              handleReplySameLevel={handleReplySameLevel}
              handleNumLikeComment={handleNumLikeComment}
              handleEditComment={handleEditComment}
              handleDeleteComment={handleDeleteComment}
              // reply
              handlerToggleReply={handlerToggleReply}
              handleMoreReply={handleMoreReply}
              handleEditReply={handleEditReply}
              handleDeleteReply={handleDeleteReply}
              handleNumLikeReply={handleNumLikeReply}
              // other
              isEditIndex={isEditIndex}
              isEditReplyIndex={isEditReplyIndex}
              handleToggleEdit={handleToggleEdit}
              handleToggleDelete={handleToggleDelete}
              handleChangeFormEdit={handleChangeFormEdit}
              handleSubmitFormEdit={handleSubmitFormEdit}
              isDisabledForm={isDisabledForm}
              // i18
              t={t}
              ref={refs[comment?._id]}
            />
          ))
        : ''}
      {totalPageComment !== currentPage ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          {disabledComment && <CircularProgress size={16} />}

          <Typography
            variant="caption"
            sx={{
              color: '#65676b',
              cursor: 'pointer',
              fontWeight: 700,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleMoreComment}
          >
            {t('showMore')} {t('comments')}
          </Typography>
        </Stack>
      ) : (
        ''
      )}

      {open ? (
        <ForumCommentDialogDelete
          open={open}
          isDeleteID={isDeleteID}
          isDeleteReply={isDeleteReply}
          isDisabled={isDisabledForm}
          handleToggleDelete={handleToggleDelete}
          handleDelete={handleDeleteCommentCustom}
        />
      ) : (
        ''
      )}
    </Container>
  )
}
