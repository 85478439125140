import React from 'react'
import axios from 'utils/axios'
import { UPDATE_API } from 'routes/api'
import {
  GET_NEWS,
  CREATE_NEWS,
  GET_NEWS_BY_ID,
  GET_NEWS_RELATED,
  RESET_NEWS,
  EDIT_NEWS,
} from 'store/reducers/NewsSlice'
import { useDispatch, useSelector } from 'react-redux'

export default function useNews() {
  const dispatch = useDispatch()

  const news = useSelector(state => state.news.news)
  const newsDetail = useSelector(state => state.news.newsDetail)
  const newsRelated = useSelector(state => state.news.newsRelated)
  const isReset = useSelector(state => state.news.isReset)
  const isLoading = useSelector(state => state.news.isLoading)

  const handleSubmitNews = form => dispatch(CREATE_NEWS(form))
  const handleEditNews = form => dispatch(EDIT_NEWS(form))

  const handleGetNews = () => dispatch(GET_NEWS())
  const handleGetNewsByID = newsID => dispatch(GET_NEWS_BY_ID(newsID))
  const handleGetNewsRelated = newsID => dispatch(GET_NEWS_RELATED(newsID))
  const handleReset = () => dispatch(RESET_NEWS())

  const handleGetLink = async (id, file) => {
    try {
      const formData = new FormData()

      formData.append('file', file)
      const response = await axios.patch(UPDATE_API(id).getLinkBanner, formData)

      return response
    } catch (error) {
      console.log('error', error)
    }
  }
  return {
    handleEditNews,
    handleSubmitNews,
    handleGetNewsByID,
    handleGetNews,
    handleGetNewsRelated,
    handleReset,
    handleGetLink,
    news,
    newsDetail,
    newsRelated,
    isReset,
    isLoading,
  }
}
