import React, { useState } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'

// hooks
import { useAuth, useSetupData } from 'hooks'
import { PATH_AUTH, PATH_PAGE } from 'routes/paths'
// ----------------------------------------------------------------------

const requestedLocation = localStorage.getItem('path')

export default function AuthGuard({ children }) {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()
  const { pathname } = useLocation()
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (isAuthenticated === null) return <Navigate to={PATH_PAGE.root} />

    // if (!isAuthenticated) {
    if (pathname === requestedLocation) {
      localStorage.removeItem('path')
      return <Navigate to={PATH_PAGE.root} />
    }

    //   if (pathname !== requestedLocation) {
    //     localStorage.setItem('path', pathname)
    //     return navigate(PATH_AUTH.login)
    //   }
    // }

    if (requestedLocation && requestedLocation !== pathname) {
      localStorage.removeItem('path')
      return <Navigate to={requestedLocation} />
    }

    setLoading(false)
  }, [pathname, isAuthenticated])
  if (loading) {
    return <div />
  }
  return <>{children}</>
}
