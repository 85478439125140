import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

export default function useI18n(key) {
  const { t, i18n } = useTranslation('translations', key)
  const getLanguage = localStorage.getItem('i18nextLng')

  return { t, i18n, i18next, getLanguage }
}
