import React from 'react'
import { styled } from '@mui/styles'
import { SwipeableDrawer, Typography, Box, List, ListItem, Avatar, Divider, Stack } from '@mui/material'
import navigateMain from 'routes/navigateMain'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks'
import { PATH_AUTH } from 'routes/paths'

const linksRouter = [
  {
    name: 'Profile',
    path: '/profile',
  },
  {
    name: 'Edit Profile',
    path: '/profile/edit',
  },
  {
    name: 'Account Setting',
    path: '/profile/setting',
  },
  {
    name: 'Logout',
    path: '/auth/logout',
  },
]

const RouterLinkStyle = styled(Link)(({ active, theme }) => ({
  color: active ? theme.palette.primary.main : '#000',
  fontWeight: active ? 700 : 400,
  transition: 'all 300ms ease',
  '&:hover': {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}))

const NavLinkField = ({ children, to, active }) => {
  return (
    <ListItem button>
      <Typography component={RouterLinkStyle} variant="subtitle2" color="primary" to={to} active={active}>
        {children}
      </Typography>
    </ListItem>
  )
}
export default function Hamburger(props) {
  const { pathname } = useLocation()
  const { open, toggleOpen } = props
  const { user, isAuthenticated } = useAuth()

  return (
    <SwipeableDrawer anchor="top" open={open} onClose={toggleOpen} onOpen={toggleOpen}>
      <Box sx={{ width: '100%' }} role="presentation" onClick={toggleOpen} onKeyDown={toggleOpen}>
        <List>
          {navigateMain.map((link, ind) => {
            if (link.title === 'Areas' || link.title === 'Innovation') return
            return (
              <NavLinkField
                key={Math.random(1231231212312312)}
                to={link.path}
                active={pathname && link.path && pathname === link.path}
                sx={{ color: 'black !important' }}
              >
                {link.title}
              </NavLinkField>
            )
          })}
        </List>
        <Divider />
        <List>
          {isAuthenticated ? (
            linksRouter.map(link => {
              if (link.name === 'Profile') {
                return (
                  <ListItem button>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar sx={{ width: 32, height: 32 }} src={user?.image_url} alt="Remy Sharp" />
                      <Typography component={RouterLinkStyle} to={link.path} variant="subtitle2">
                        {user?.fullname}
                      </Typography>
                    </Stack>
                  </ListItem>
                )
              }
              return (
                <ListItem button key={link.name}>
                  <Typography component={RouterLinkStyle} to={link.path} variant="subtitle2">
                    {link.name}
                  </Typography>
                </ListItem>
              )
            })
          ) : (
            <>
              <ListItem button>
                <Typography component={RouterLinkStyle} to={PATH_AUTH.login} variant="subtitle2">
                  Login
                </Typography>
              </ListItem>
              <ListItem sx={{ pr: 0, mr: 0 }}>
                <Typography sx={{ pr: 0, mr: 0 }} component={RouterLinkStyle} to={PATH_AUTH.signup} variant="subtitle2">
                  Sign Up
                </Typography>
              </ListItem>
            </>
          )}
        </List>
      </Box>
    </SwipeableDrawer>
  )
}
