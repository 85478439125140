import { Suspense, lazy, useEffect } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'

// layouts
import MainLayout from 'layouts/main'
import AuthLayout from 'layouts/authentication'
import ProfileEditLayout from 'pages/main/ProfileEdit'

// guards
import GuestGuard from 'guards/GuestGuard'
import AuthGuard from 'guards/AuthGuard'

// components
import LoadingScreen from 'components/LoadingScreen'

import { useDocument } from 'hooks'

// ----------------------------------------------------------------------

const Loadable = Component => props => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )
}

const AuthGuardStyle = ({ children }) => {
  return <AuthGuard>{children}</AuthGuard>
}

export default function Router() {
  const { pathname } = useLocation()
  const { handleReset } = useDocument()

  useEffect(() => {
    console.log(pathname)
    if (pathname !== '/documents') {
      localStorage.removeItem('area')
      localStorage.removeItem('areaName')
      handleReset()
    }
  }, [pathname])
  return useRoutes([
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },

        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'logout', element: <Logout /> },
      ],
    },

    // ERROR PAGE
    // {
    //   path: '*',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: 'coming-soon', element: <ComingSoon /> },
    //     { path: 'maintenance', element: <Maintenance /> },
    //     { path: 'pricing', element: <Pricing /> },
    //     { path: 'payment', element: <Payment /> },
    //     { path: '500', element: <Page500 /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" replace /> },
    //   ],
    // },

    // Main Routes
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: '/home', element: <Navigate to="/" replace /> },
        { path: '/about-us', element: <AboutPage /> },
        { path: '/contact', element: <ContactPage /> },
        { path: '/pipeline-tracker', element: <PipelineTracker /> },
        // {
        //   path: '/contract',
        //   element: (
        //     <AuthGuardStyle>
        //       <ContractsPage />
        //     </AuthGuardStyle>
        //   ),
        // },
        { path: '/Areas', element: <ExpertisePage /> },
        {
          path: '/news',
          element: (
            <AuthGuardStyle>
              <NewsPage />
            </AuthGuardStyle>
          ),
          children: [
            { path: '', element: <NewsContainerPage /> },
            { path: 'create', element: <NewsCreatePage /> },
          ],
        },
        {
          path: '/news/:id',
          element: (
            <AuthGuardStyle>
              <DetailNewsPage />
            </AuthGuardStyle>
          ),
          children: [
            { path: '', element: <NewsDetailContainerPage /> },
            { path: 'edit', element: <NewsDetailEditPage /> },
          ],
        },

        {
          path: '/documents/',
          element: (
            <AuthGuardStyle>
              <DocumentsPage />
            </AuthGuardStyle>
          ),
        },
        {
          path: '/documents/t/:title',
          element: (
            <AuthGuardStyle>
              <DocumentsPage />
            </AuthGuardStyle>
          ),
        },
        {
          path: '/documents/:id',
          element: (
            <AuthGuardStyle>
              <DetailDocumentsPage />
            </AuthGuardStyle>
          ),
        },
        {
          path: '/forums',
          element: (
            <AuthGuardStyle>
              <ForumsPage />
            </AuthGuardStyle>
          ),
        },
        {
          path: '/forums/:id',
          element: (
            <AuthGuardStyle>
              <DetailForumPage />
            </AuthGuardStyle>
          ),
        },
        {
          path: '/group/:id',
          element: (
            <AuthGuardStyle>
              <GroupPage />
            </AuthGuardStyle>
          ),
        },
        {
          path: '/profile',
          element: (
            <AuthGuardStyle>
              <ProfilePage />
            </AuthGuardStyle>
          ),
        },
        {
          path: '/profile/:id',
          element: (
            <AuthGuardStyle>
              <ProfilePage />
            </AuthGuardStyle>
          ),
        },
        {
          path: '/profile/edit',
          element: (
            <AuthGuardStyle>
              <ProfileEditLayout>
                <ProfileEditPage />
              </ProfileEditLayout>
            </AuthGuardStyle>
          ),
        },
        {
          path: '/profile/setting',
          element: (
            <AuthGuardStyle>
              <ProfileEditLayout>
                <ProfileSettingPage />
              </ProfileEditLayout>
            </AuthGuardStyle>
          ),
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ])
}

// Authentication
const Login = Loadable(lazy(() => import('pages/authentication/Login')))
const Register = Loadable(lazy(() => import('pages/authentication/Register')))
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')))
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')))
const VerifyCode = Loadable(lazy(() => import('pages/authentication/VerifyCode')))
const Logout = Loadable(lazy(() => import('pages/authentication/Logout')))

// Main
const LandingPage = Loadable(lazy(() => import('pages/main/Landing')))
const AboutPage = Loadable(lazy(() => import('pages/main/About')))
const ContactPage = Loadable(lazy(() => import('pages/main/Contact')))

// news page
const NewsPage = Loadable(lazy(() => import('pages/main/News')))
const NewsContainerPage = Loadable(lazy(() => import('pages/main/nested/news/NewsContainer')))
const NewsCreatePage = Loadable(lazy(() => import('pages/main/nested/news/NewsCreate')))

const DetailNewsPage = Loadable(lazy(() => import('pages/main/children/News-Detail')))
const NewsDetailEditPage = Loadable(lazy(() => import('pages/main/nested/newsDetail/NewsDetailEdit')))
const NewsDetailContainerPage = Loadable(lazy(() => import('pages/main/nested/newsDetail/NewsDetailContainer')))

// docs page
const DocumentsPage = Loadable(lazy(() => import('pages/main/Documents')))
const DetailDocumentsPage = Loadable(lazy(() => import('pages/main/children/Doc-Detail')))
const ContractsPage = Loadable(lazy(() => import('pages/main/Contract')))
const ExpertisePage = Loadable(lazy(() => import('pages/main/Expertise')))

// profile
const ProfilePage = Loadable(lazy(() => import('pages/main/Profiles')))
const ProfileEditPage = Loadable(lazy(() => import('components/_expand-page/Profile/ProfileFormEdit')))
const ProfileSettingPage = Loadable(lazy(() => import('components/_expand-page/Profile/ProfileFormSetting')))

// forums
const ForumsPage = Loadable(lazy(() => import('pages/main/Forums')))
const DetailForumPage = Loadable(lazy(() => import('pages/main/children/Forum-Detail')))

// group
const GroupPage = Loadable(lazy(() => import('pages/main/Groups')))

// pipeline tracker
const PipelineTracker = Loadable(lazy(() => import('pages/main/PipelineTracker')))

// ERROR
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')))
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')))
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')))
// const Payment = Loadable(lazy(() => import('../pages/Payment')))
// const Page500 = Loadable(lazy(() => import('../pages/Page500')))
const NotFound = Loadable(lazy(() => import('pages/errors/NotFound404')))
