import React, { useRef } from 'react'
import { styled } from '@mui/styles'
import { Box, Stack, IconButton } from '@mui/material'

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import ImageIcon from '@mui/icons-material/Image'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import useUploadFiles from 'hooks/useUploadFiles'

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  padding: 0,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '& svg': {
    fill: theme.palette.primary.main,
    width: 25,
    height: 25,
    marginLeft: 10,
  },
}))

export default function UploadActions(props) {
  const { handleClickImageUpload, open, isSingle = false, isCreate } = props
  const { handleChangeFile, handleReset, sourceImg, imgFiles } = useUploadFiles()

  React.useEffect(() => {
    if (!sourceImg) return
    handleClickImageUpload(sourceImg, imgFiles)
  }, [sourceImg, imgFiles])

  React.useEffect(() => {
    handleReset()
  }, [open])

  const imageUploadRef = useRef()

  const handleClick = () => {
    imageUploadRef.current.click()
    if (isSingle) {
      setTimeout(() => {
        handleReset()
      }, 1000)
    }
  }
  return (
    <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
      <IconButtonStyle onClick={handleClick}>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={imageUploadRef}
          onChange={handleChangeFile}
          name="imageFiles"
          style={{ display: 'none' }}
        />
        <ImageIcon />
      </IconButtonStyle>
      {isCreate ? (
        <IconButtonStyle onClick={handleClick}>
          <input
            type="file"
            accept="pdf"
            multiple
            ref={imageUploadRef}
            onChange={handleChangeFile}
            name="imageFiles"
            style={{ display: 'none' }}
          />
          <UploadFileIcon />
        </IconButtonStyle>
      ) : null}
    </Stack>
  )
}
