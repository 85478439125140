import React from 'react'
import { PATH_AUTH } from 'routes/paths'
import { Country, State, City } from 'country-state-city'

import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import countriesData from 'assets/data/countries'

import { styled } from '@mui/styles'
import { Box, Typography, Grid, FormHelperText } from '@mui/material'
import {
  TitleField,
  FormControl,
  InputField,
  ButtonField,
  OptionLink,
  SelectField,
} from 'components/authentication/styles'
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'
import { useI18n } from 'hooks'

const RegisterSchema = Yup.object().shape({
  company: Yup.string().required('Company is required'),
  role: Yup.string().required('Role is required'),
  phone: Yup.string().required('Phone is required'),
  email: Yup.string()
    .email('Email must be a valid email address')
    .matches(
      /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/,
      'Gmail | google | yahoo | outlook | hotmail | msn email extensions are not accepted'
    )
    .required('Email is required'),
  country: Yup.string().required('Country is required'),
  province: Yup.string().required('Province is required'),
  password: Yup.string().required('Password is required'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const initialize = {
  username: '',
  company: '',
  role: '',
  email: '',
  phone: '',
  country: '',
  province: '',
  password: '',
  passwordConfirmation: '',
}
const Container = styled(Box)(() => ({}))
const RouterLinkStyle = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'underline',
  },
}))

const dataSelect = [
  {
    label: 'Choose options',
    value: '',
  },
  {
    label: 'option 1',
    value: 1,
  },
  {
    label: 'opion2',
    value: 2,
  },
]

const PhoneInputField = props => {
  // eslint-disable-next-line react/destructuring-assignment

  const { error, helperText } = props
  const PhoneInputStyle = styled(PhoneInput)(({ theme, error }) => ({
    '& .PhoneInputCountry': {
      background: 'rgba(238, 238, 238, 0.7)',
      padding: '0px 8px',
      borderRadius: 5,
    },
    '& input': {
      color: '#000',
      padding: '15px 10px',
      fontSize: 14,
      background: 'rgba(238, 238, 238, 0.7)',
      fontWeight: 400,
      borderRadius: 5,
      border: error ? `1px solid ${theme.palette.primary.main}` : 'none',
      outline: 'none',
    },
  }))
  const errorZ = Boolean(error)
  return (
    <FormControl error={errorZ}>
      <PhoneInputStyle {...props} error={errorZ} />
      {errorZ && helperText && (
        <FormHelperText error={errorZ} sx={{ color: '#ca3636!important' }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default function ProfileFormEdit() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [provinces, setProvinces] = React.useState([])
  const { t, getLanguage } = useI18n({ keyPrefix: 'profile' })

  const formik = useFormik({
    initialValues: initialize,
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      // try {
      //   const response = await register(values)
      //   if (response.status !== 201) {
      //     formik.resetForm({ values: { ...values, password: '', passwordConfirmation: '' } })
      //     return enqueueSnackbar(response.data.error, { variant: 'error' })
      //   }
      //   enqueueSnackbar(response.data.message, { variant: 'success' })
      //   navigate(PATH_AUTH.login)
      // } catch (err) {
      //   console.log('err', err)
      // }
    },
  })
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange } = formik

  // get provinces
  React.useEffect(() => {
    if (!formik.values.country) return
    const provinces = State.getStatesOfCountry(formik.values.country)
    const newProvinces = provinces.map(province => ({ label: province.name, value: province.isoCode }))
    setProvinces([{ label: 'Choose province', value: '' }, ...newProvinces])
  }, [formik.values.country])

  let timer
  const handleChangePhone = value => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      formik.setFieldValue('phone', value)
    }, 300)
  }
  return (
    <Box sx={{ maxWidth: 648, margin: '0 auto' }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Container>
            <Grid container columnSpacing={3.5}>
              <Grid item xs={12}>
                <FormControl label={t('input.userName')}>
                  <InputField
                    placeholder={t('input.userName')}
                    {...getFieldProps('username')}
                    helperText={touched.email && errors.email}
                    error={touched.email && errors.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl label={t('input.emailAddress')}>
                  <InputField
                    placeholder={t('input.emailAddress')}
                    {...getFieldProps('email')}
                    helperText={touched.email && errors.email}
                    error={touched.email && errors.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl label={t('input.companyName')}>
                  <InputField
                    placeholder={t('input.companyName')}
                    {...getFieldProps('company')}
                    helperText={touched.company && errors.company}
                    error={touched.company && errors.company}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl label={t('input.role')}>
                  <InputField
                    placeholder={t('input.role')}
                    {...getFieldProps('role')}
                    helperText={touched.role && errors.role}
                    error={touched.role && errors.role}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl label={t('input.phone')}>
                  <PhoneInputField
                    placeholder={t('input.phone')}
                    international
                    defaultCountry="VN"
                    value={formik.values.phone}
                    onChange={phone => handleChangePhone(phone)}
                    onBlur={formik.handleBlur('phone')}
                    helperText={touched.phone && errors.phone}
                    error={touched.phone && errors.phone}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl label={t('input.country')}>
                  <SelectField
                    value=""
                    placeholder={t('input.country')}
                    lists={countriesData}
                    {...getFieldProps('country')}
                    helperText={touched.country && errors.country}
                    error={touched.country && errors.country}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl label={t('input.province')}>
                  <SelectField
                    value=""
                    placeholder={t('input.province')}
                    disabled={!provinces.length && true}
                    lists={provinces.length ? provinces : dataSelect}
                    {...getFieldProps('province')}
                    helperText={touched.province && errors.province}
                    error={touched.province && errors.province}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Container>
          <Box sx={{ mt: 1 }}>
            <ButtonField variant="contained" type="submit">
              {t('input.save')}
            </ButtonField>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  )
}
