import React from 'react'
import { Paper, MenuList, MenuItem, ListItemIcon, Typography, Popper, Fade } from '@mui/material'

import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import EditIcon from '@mui/icons-material/Edit'
import { useI18n } from 'hooks'

const data = [
  { titleVn: 'Lưu bài viết', title: 'Save post', icon: <BookmarkBorderIcon sx={{ fontSize: 14 }} />, tag: 'save' },
  { titleVn: 'Sửa bài viết', title: 'Edit post', icon: <EditIcon sx={{ fontSize: 14 }} />, tag: 'edit' },
  { titleVn: 'Xóa bài viết', title: 'Delele post', icon: <HighlightOffIcon sx={{ fontSize: 14 }} />, tag: 'delete' },
]

export default function ForumActionPost(props) {
  const { anchor, handleAction } = props
  const { getLanguage } = useI18n()

  const open = Boolean(anchor)

  return (
    <Popper open={open} anchorEl={anchor} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={10}>
            <MenuList>
              {data.map(d => (
                <MenuItem key={d.title} sx={{ px: 2, py: 1 }} onClick={() => handleAction(d.tag)}>
                  <ListItemIcon>{d.icon}</ListItemIcon>
                  <Typography variant="caption">{getLanguage === 'en' ? d.title : d.titleVn}</Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}
