import React from 'react'
import { styled } from '@mui/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  IconButton,
  Typography,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Stack,
  Badge,
  Box,
  Avatar,
  Skeleton,
} from '@mui/material'
import { fToNow } from 'utils/formatTime'
import lineClampStyle from 'components/LineClamp'

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

import { PATH_PAGE } from 'routes/paths'
import { useNavigate } from 'react-router-dom'

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  gap: theme.spacing(1),
  '&:hover': {
    background: 'rgba(238, 238, 238, 0.6)',
    '& a': {
      color: '#000',
    },
  },
}))

const CustomSkeleton = () => {
  return (
    <Stack sx={{ py: 1, px: 2 }} spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ py: 1 }}>
        <Skeleton variant="circular" width={24} height={24} />
        <Stack sx={{ flexGrow: 1 }}>
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="40%" height={10} />
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ py: 1 }}>
        <Skeleton variant="circular" width={24} height={24} />
        <Stack sx={{ flexGrow: 1 }}>
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="40%" height={10} />
        </Stack>
      </Stack>
    </Stack>
  )
}
export default function NotificationHeader({ getLanguage, notification, handleSeenNotification, handleGetMoreNoti }) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const navigate = useNavigate()

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const numNotification = notification?.noties.length
    ? notification?.noties.reduce((acc, cur) => (!cur.seen ? acc + 1 : acc), 0)
    : ''

  const handleRedirect = (e, notification, redirect) => {
    handleSeenNotification(notification?._id)
    navigate(redirect, { state: notification?.commentId })
    handleClose(e)
  }

  return (
    <Stack direction="row" spacing={2}>
      <IconButton
        sx={{ p: 0 }}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge
          color="primary"
          badgeContent={typeof numNotification !== 'number' ? 0 : numNotification}
          sx={{ '& .MuiBadge-badge': { height: 15 } }}
        >
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="top-end" transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              sx={{
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                minWidth: 300,

                '& .infinite-scroll-component': {
                  '&::-webkit-scrollbar': { width: 16 },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#fafafa',
                    borderRadius: 100,
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    border: ' 5px solid transparent',
                    backgroundClip: ' content-box',
                    backgroundColor: '#CA3636',
                  },
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  <InfiniteScroll
                    dataLength={notification.noties.length} // This is important field to render the next data
                    next={handleGetMoreNoti}
                    hasMore={notification.noties.length !== notification.totalLength}
                    height={400}
                    loader={<CustomSkeleton />}
                    endMessage={
                      <Box sx={{ pl: 2 }}>
                        <Typography variant="caption" color="primary" sx={{ fontWeight: 700 }}>
                          {getLanguage === 'en'
                            ? `You have seen ${notification.totalLength} notifications`
                            : `Bạn có tất cả ${notification.totalLength} thông báo`}
                        </Typography>
                      </Box>
                    }
                  >
                    {notification.noties.length
                      ? notification.noties.map(noti => {
                          const redirect = noti.type === 'post' ? `${PATH_PAGE.forums}/${noti?.postId}` : ''
                          return (
                            <MenuItemStyle key={noti._id} onClick={e => handleRedirect(e, noti, redirect)}>
                              <Box>
                                <Avatar
                                  src={noti?.createdByImage}
                                  alt={noti?.receiverId}
                                  sx={{ width: 24, height: 24 }}
                                />
                              </Box>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={1}
                                sx={{ flexGrow: 1 }}
                              >
                                <Stack>
                                  <Typography
                                    variant="caption"
                                    dangerouslySetInnerHTML={{
                                      __html: getLanguage === 'en' ? noti?.content : noti?.contentVN,
                                    }}
                                    sx={{ ...lineClampStyle(2), whiteSpace: 'pre-wrap' }}
                                  />
                                  {noti.createdAt && (
                                    <Typography variant="caption" sx={{ color: '#65676b', fontSize: '0.65rem' }}>
                                      {fToNow(noti.createdAt)}
                                    </Typography>
                                  )}
                                </Stack>
                                {!noti.seen && (
                                  <Box
                                    sx={{
                                      width: 8,
                                      minWidth: 8,
                                      height: 8,
                                      borderRadius: '50%',
                                      bgcolor: 'primary.main',
                                    }}
                                  />
                                )}
                              </Stack>
                            </MenuItemStyle>
                          )
                        })
                      : ''}
                  </InfiniteScroll>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  )
}
