import React from 'react'
import { styled } from '@mui/styles'
import { Box, Stack, Typography, SvgIcon } from '@mui/material'
import footerJSON from 'app/data/footer'
import { PATH_PAGE } from 'routes/paths'
import { Link } from 'react-router-dom'

import navigateMenu from 'routes/navigateMain'
import CopyrightIcon from '@mui/icons-material/Copyright'
import { useI18n } from 'hooks'

const Container = styled(Stack)(() => ({
  borderRadius: 2,
  boxShadow:
    '0px 2px 3px -1px rgb(144 144 150 / 20%), 0px 5px 6px 0px rgb(144 144 150 / 14%), 0px 1px 10px 0px rgb(144 144 150 / 12%)',
}))
const TypographyField = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))
const data = [
  {
    title: 'Guideline',
    titleVn: 'Hướng dẫn',
    path: PATH_PAGE.documents,
  },
  {
    title: 'Contract',
    titleVn: 'Hợp đồng',
    path: PATH_PAGE.documents,
  },
  {
    title: 'Form',
    titleVn: 'Mẫu đơn',
    path: PATH_PAGE.documents,
  },
  {
    title: 'Checklist',
    titleVn: 'Danh mục',
    path: PATH_PAGE.documents,
  },
]

export default function Forum_Footer() {
  const { t, getLanguage } = useI18n()

  return (
    <Container sx={{ p: 2.5 }} spacing={2}>
      <Stack direction="row" spacing={2}>
        <Stack sx={{ flex: 1 }} spacing={0.5}>
          {data.map(child => (
            <Box key={child.title}>
              <TypographyField variant="caption" component={Link} to={child.path}>
                {getLanguage === 'en' ? child.title : child.titleVn}
              </TypographyField>
            </Box>
          ))}
        </Stack>
        <Stack sx={{ flex: 1 }} spacing={0.5}>
          {navigateMenu.map(child => (
            <Box key={child.title}>
              <TypographyField variant="caption" component={Link} to={child.path}>
                {getLanguage === 'en' ? child.title : child.titleVn}
              </TypographyField>
            </Box>
          ))}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <SvgIcon color="primary" sx={{ fontSize: 16 }}>
          <CopyrightIcon />
        </SvgIcon>
        <Typography sx={{ fontSize: 13 }} color="primary">
          LNT & Partners {t('footer.allRightsReserved')}
        </Typography>
      </Stack>
    </Container>
  )
}
