import { Stack, Typography } from '@mui/material'
import { useTheme, styled } from '@mui/styles'
import { useNavigate, useLocation } from 'react-router-dom'

import ShareIcon from '@mui/icons-material/Share'
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg'
import { MButton } from 'components/_material-extend'
import LineClamp from 'components/LineClamp'
import { useI18n, useAuth } from 'hooks'

const MButtonStyle = styled(MButton)(() => ({
  padding: 0,
  '& .MuiButton-startIcon': {
    marginRight: 4,
  },
  '& svg': {
    fontSize: '12px!important',
  },
}))

const ForumActions = props => {
  const { handleNavigate, numComments, handleShare } = props
  const { t } = useI18n({ keyPrefix: 'forum' })
  const { isAuthenticated } = useAuth()

  const navigate = useNavigate()
  const location = useLocation().pathname

  const handleNotLogin = () => {
    if (window.confirm('You need to be authenticated to comment/share posts. Login now?')) {
      localStorage.setItem('prevPath', location)
      navigate('/auth/login')
    }
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ flexWrap: 'wrap', ml: 0.5 }} spacing={1}>
      <MButtonStyle
        disableRipple={true}
        startIcon={<CommentIcon style={{ width: 12 }} />}
        onClick={isAuthenticated ? handleNavigate : handleNotLogin}
      >
        <Typography variant="body2" sx={{ color: '#909096', ...LineClamp(1) }}>
          {numComments} {t('comment')}
        </Typography>
      </MButtonStyle>
      <MButtonStyle
        disableRipple={true}
        startIcon={<ShareIcon sx={{ fill: '#909096' }} />}
        onClick={isAuthenticated ? handleShare : handleNotLogin}
      >
        <Typography variant="body2" sx={{ color: '#909096', ...LineClamp(1) }}>
          {t('share')}
        </Typography>
      </MButtonStyle>
    </Stack>
  )
}
export default ForumActions
