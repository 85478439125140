import React from 'react'
import { useI18n, usePost } from 'hooks'

import { ForumPostEdit, ForumPostDelete, ForumPostCreate } from './index'

export default function Forum_PostCRUD({ nameState, handleSubmit }) {
  const {
    isEditPost,
    isDeletePost,
    isCreatePost,
    handleActionPost,
    savePostAction,
    // handleActionPostSubmit,
    // handleActionPostDetailSubmit,
  } = usePost()
  const { t, getLanguage } = useI18n({ keyPrefix: 'forum' })

  return (
    <>
      <ForumPostEdit
        name={nameState}
        open={isEditPost || false}
        handleClose={handleActionPost}
        data={savePostAction}
        handleSubmit={handleSubmit}
        t={t}
      />

      <ForumPostDelete
        name={nameState}
        open={isDeletePost || false}
        handleSubmit={handleSubmit}
        handleClose={handleActionPost}
        t={t}
      />
      <ForumPostCreate
        name={nameState}
        open={isCreatePost || false}
        handleClose={handleActionPost}
        handleSubmit={handleSubmit}
        t={t}
      />
    </>
  )
}
