import React from 'react'
import { PATH_PAGE } from 'routes/paths'
import { Link } from 'react-router-dom'
import { styled } from '@mui/styles'
import { Box, Chip, Avatar, Typography, Stack, TextareaAutosize, CircularProgress } from '@mui/material'
import { MButton } from 'components/_material-extend'
import { ForumCommentInput, ForumReply, ForumCommentActions } from './index'

import LazyLoad from 'react-lazyload'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ReplyIcon from '@mui/icons-material/Reply'
import { fToNow } from 'utils/formatTime'

const Container = styled(Stack)(() => ({}))

const TypographyField = ({ variant = 'caption', children, sx, ...props }) => {
  return (
    <Typography variant={variant} sx={{ fontWeight: 700, ...sx }} {...props}>
      {children}
    </Typography>
  )
}
const ForumCommentItem = React.forwardRef((props, ref) => {
  const {
    children,
    handlerToggleReply,
    inputReply,
    comment,
    user,
    handleNumLikeComment,
    handleSubmitComments,
    handleEditComment,
    handleDeleteComment,

    handleMoreReply,
    handleEditReply,
    handleDeleteReply,
    handleNumLikeReply,
    handleReplySameLevel,

    // other
    isEditIndex,
    isEditReplyIndex,
    handleToggleEdit,
    handleToggleDelete,
    handleChangeFormEdit,
    handleSubmitFormEdit,
    isDisabledForm,
    // i18
    t,
  } = props
  const { numLikes, reply, PostId, _id, content, createdBy, userImg, createdAt, createByName } = comment

  const likeExists = comment?.likedUsers.findIndex(likedUser => likedUser === user?.id)

  const [isActionAnchor, setIsActionAnchor] = React.useState(null)
  const handleToggleActionComment = e => setIsActionAnchor(isActionAnchor ? null : e.currentTarget)

  return (
    <Container direction="row" spacing={1.5} sx={{ flexGrow: 1 }}>
      <div ref={ref} />
      <Avatar sx={{ width: 32, height: 32 }} src={userImg} />
      <Stack spacing={0.5} sx={{ width: '100%' }}>
        <Stack
          sx={{
            position: 'relative',
            bgcolor: !comment?.isReply ? `rgba(135, 135, 135, 0.12)` : 'primary.light',
            borderRadius: 2,
            py: 1,
            px: 2,
            width: 'fit-content',
            '&:hover': {
              '& .BtnActions': {
                opacity: 1,
              },
            },
          }}
        >
          <Box sx={{ zIndex: 1, position: 'relative', width: '100%' }}>
            {children}
            {isEditIndex?.id !== comment?._id ? (
              <>
                <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={0.5}>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    component={Link}
                    to={`${PATH_PAGE.profile}/${createdBy}`}
                  >
                    {createByName}
                  </Typography>
                  {comment?.createdBy === user?.id && (
                    <Box sx={{ potision: 'absolute', top: 0, right: 0 }}>
                      <ForumCommentActions
                        comment={comment}
                        anchor={isActionAnchor}
                        handleToggle={handleToggleActionComment}
                        handleEditComment={handleToggleEdit}
                        handleDeleteComment={handleToggleDelete}
                      />
                    </Box>
                  )}
                </Stack>

                <Typography variant="caption">{content}</Typography>

                {comment?.commentFile ? (
                  <Box sx={{ width: 150 }}>
                    <LazyLoad>
                      <img src={comment?.commentFile} alt="imgComment" />
                    </LazyLoad>
                  </Box>
                ) : (
                  ''
                )}
              </>
            ) : (
              <Box>
                <TextareaAutosize
                  defaultValue={comment?.content}
                  value={isEditIndex?.content}
                  autoFocus={true}
                  onChange={handleChangeFormEdit}
                  style={{
                    background: `transparent`,
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    resize: 'none',
                  }}
                />
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isDisabledForm ? <CircularProgress size={16} /> : null}
                  <Typography
                    color="gray"
                    variant="caption"
                    sx={{ fontWeight: 700, cursor: 'pointer' }}
                    onClick={handleSubmitFormEdit}
                  >
                    {t('input.save')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="caption"
                    sx={{ fontWeight: 700, cursor: 'pointer' }}
                    onClick={() => handleToggleEdit()}
                  >
                    {t('input.close')}
                  </Typography>
                </Stack>
              </Box>
            )}
          </Box>

          {numLikes > 0 ? (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                right: -20,
                bottom: 10,
                justifyContent: 'flex-end',
                alignItems: 'end',
              }}
            >
              <Chip
                sx={{
                  height: 18,
                  alignItems: 'center',
                  '& .MuiChip-label': {
                    fontSize: 10,
                    pl: 1,
                    pt: 0.25,
                  },
                  '& svg': {
                    fill: '#CA3636',
                  },
                }}
                icon={<ThumbUpAltIcon sx={{ fontSize: 12, borderRadius: '50%' }} />}
                label={numLikes}
              />
            </Box>
          ) : null}
        </Stack>
        <Stack sx={{ pl: 1 }}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <TypographyField
              color={likeExists !== -1 ? 'primary' : '#65676b'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleNumLikeComment(comment, likeExists === -1 ? 1 : -1)}
            >
              {t('like')}
            </TypographyField>
            <TypographyField sx={{ pb: 0.5, color: '#65676b' }}>.</TypographyField>
            <TypographyField sx={{ color: '#65676b', cursor: 'pointer' }} onClick={() => handlerToggleReply(comment)}>
              {t('reply')}
            </TypographyField>
            <TypographyField sx={{ pb: 0.5, color: '#65676b' }}>.</TypographyField>

            {createdAt && (
              <TypographyField variant="caption" sx={{ color: '#65676b' }}>
                {fToNow(createdAt)}
              </TypographyField>
            )}
          </Stack>

          {reply.length ? (
            comment?.isShowReply ? (
              <ForumReply
                comment={comment}
                user={user}
                handleSubmitComments={handleSubmitComments}
                handleEditReply={handleEditReply}
                handleDeleteReply={handleDeleteReply}
                handleNumLikeReply={handleNumLikeReply}
                handleReplySameLevel={handleReplySameLevel}
                handleMoreReply={handleMoreReply}
                // other
                isEditReplyIndex={isEditReplyIndex}
                isDisabledForm={isDisabledForm}
                handleChangeFormEdit={handleChangeFormEdit}
                handleSubmitFormEdit={handleSubmitFormEdit}
                handleToggleEdit={handleToggleEdit}
                handleToggleDelete={handleToggleDelete}
                // i18
                t={t}
              />
            ) : (
              <Box>
                <MButton
                  startIcon={<ReplyIcon sx={{ fill: '#CA3636', transform: 'rotate(180deg)' }} />}
                  sx={{ p: 0 }}
                  onClick={() => handleMoreReply(_id)}
                >
                  <Typography variant="body2" color="primary" sx={{ fontSize: '0.7rem' }}>
                    {reply.length && reply.length > 1
                      ? `${t('moreReplies', { numReply: reply.length })}`
                      : `${t('moreReply', { numReply: reply.length })}`}
                  </Typography>
                </MButton>
              </Box>
            )
          ) : (
            ''
          )}
          {comment?.isReply ? (
            <ForumCommentInput
              inputReply={inputReply}
              postID={PostId}
              commentID={_id}
              handleSubmitComments={handleSubmitComments}
            />
          ) : (
            ''
          )}
        </Stack>
      </Stack>
    </Container>
  )
})
export default ForumCommentItem
