import * as React from 'react'
import LazyLoad from 'react-lazyload'
import { Link, useNavigate } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'
import { useMediaQuery, Card, CardActions, CardContent, CardMedia, Button, Typography, Box } from '@mui/material'
import { styled, useTheme } from '@mui/styles'
import lineClamp from 'components/LineClamp'
import { fDateOriginal } from 'utils/formatTime'
import { useI18n } from 'hooks'

const ButtonStyle = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  '& .MuiTypography-root': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    background: theme.palette.primary.main,
    color: '#fff',
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
}))

export default function LandingLegalNewsItem({
  id = '',
  title,
  date,
  poster,
  children,
  isDropdown,
  closeDropdown,
  grid,
}) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down(375))
  const matches600 = useMediaQuery(theme.breakpoints.down(600))
  const { t } = useI18n({ keyPrefix: 'news' })
  const navigate = useNavigate()

  const createMarkup = html => {
    return { __html: html }
  }

  const htmlDecode = html => {
    const e = document.createElement('div')
    e.innerHTML = html
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }

  const handleClick = path => {
    navigate(path)
  }

  return (
    <Card
      sx={{
        height: 420,
        gridArea: isDropdown ? `grid-${grid}` : null,
        maxWidth: !isDropdown ? 355 : '100%',
        margin: isDropdown ? '7.5px' : !matches600 ? '20px 10px' : '20px auto',
        '&:hover': {
          '& .title': {
            transition: 'all 500ms ease-in',
            color: 'primary.main',
          },
        },
        '& .thumbnail': {
          width: '15vh',
          height: '10vh',
        },
      }}
      elevation={5}
      onContextMenu={e => {
        e.preventDefault()
        return false
      }}
    >
      {/* <CardMedia component="img" sx={{ height: '28vh' }}  alt="green iguana"> */}
      <Box sx={{ flexShrink: 0, height: 150 }}>
        <LazyLoad>
          <img src={poster} alt="legal news" resizeMode="cover" />
        </LazyLoad>
      </Box>
      <CardContent sx={{ height: '50%' }}>
        <Typography
          onClick={isDropdown && closeDropdown}
          variant={isDropdown ? 'subtitle2' : 'subtitle1'}
          component={Link}
          to={`${PATH_PAGE.news}/${id}`}
          className="title"
          sx={lineClamp({ line: 2, isDropdown: true })}
        >
          {title}
        </Typography>
        {date && (
          <Typography gutterBottom variant="caption" color="text.secondary">
            {fDateOriginal(date)}
          </Typography>
        )}
        <Typography
          className="force-bland"
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, ...lineClamp({ line: 3 }) }}
          dangerouslySetInnerHTML={createMarkup(htmlDecode(children))}
        />
      </CardContent>
      {!isDropdown && (
        <CardActions sx={{ p: 2, position: 'relative', backgroundColor: '#fff' }}>
          <ButtonStyle onClick={() => handleClick(`${PATH_PAGE.news}/${id}`)} size="small" fullWidth>
            <Typography variant="subtitle1Res">{t('input.readMore')}</Typography>
          </ButtonStyle>
        </CardActions>
      )}
    </Card>
  )
}
