import React from 'react'
import { Dialog, DialogContent, DialogContentText, DialogTitle, CircularProgress, DialogActions } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import MButton from 'components/_material-extend/MButton'

export default function ForumCommentDialogDelete(props) {
  const { isDeleteID, open, isDeleteReply = false, isDisabled, handleToggleDelete, handleDelete } = props

  return (
    <Dialog onClose={handleToggleDelete} open={open}>
      <DialogTitle>Delete Comment?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to delete this comment?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MButton onClick={handleToggleDelete} disabled={isDisabled} variant="gray" sx={{ fontSize: 12 }}>
          Cancel
        </MButton>

        <MButton
          onClick={() => handleDelete(isDeleteReply)}
          disabled={isDisabled}
          variant="contained"
          sx={{ fontSize: 12 }}
        >
          {isDisabled && <CircularProgress size={16} sx={{ mr: 1 }} />}
          Accept
        </MButton>
      </DialogActions>
    </Dialog>
  )
}
