const GET_API = (id, query = '') => {
  return {
    getUserID: `/users/${id}`,
    notifications: '/user/notifications',
    notificationUpdateSeen: `/notification/${id}/updateSeen`,

    groups: `/groups`,
    groupById: `/group/${id}/posts?${query}`,
    groupByRelated: `/groups/${id}/postsRelated`,

    posts: `/posts`,
    postsTopic: `/postTop`,
    postById: `/posts/${id}`,
    postByUser: `/user/${id}/posts`,
    postRelatedByGroup: `/group/${id}/postsRelated`,
    postTrendingToday: `/postTop`,

    commentsByPost: `post/${id}/comments`,
    replies: `/comment/${id}/replies`,

    docs: `/fileExternal?language=${query}`,
    docByID: `/fileExternal/${id}`,
    docMostView: `/TopFileExternal`,
    docRelated: `/external_file/${id}/related`,
    docKeywordSearch: `/external_file/search?q=${query}`,

    news: `/news`,
    newsByID: `/news/${id}`,
    newsByRelated: `/news/${id}/related`,
  }
}

const POST_API = (id, query = '') => {
  return {
    login: '/login',
    register: '/register',
    resetPassword: '/users/resetpassword',
    editUser: `/users/${id}/edit`,
    uploadImgUser: `/users/${id}/upload_img`,

    uploadAvatar: `/user/${id}/upload_img`,

    posts: `/posts/create`,
    postsByGroup: `/group/${id}/posts`,
    commentByPost: `/post/${id}/comments/create`,
    replies: `/comment/${id}/reply/create`,

    docSearch: `/searchFilesExternal?language=${query}`,
    // docSearchTitle: `/external_file/searchByTitle `,
    docSearchTitle: `/external_file/search?language=${query}`,

    docFile: `/files/upload_external`,

    news: `/news/create`,
  }
}
const UPDATE_API = (id, id2) => {
  return {
    postEdit: `/posts/${id}/edit`,
    upVotePost: `/posts/${id}/upvote`,
    downVotePost: `/posts/${id}/downvote`,

    updateDoc: `/fileExternal/${id}/update`,

    joinGroup: `/group/${id}/join`,
    removeJoinGroup: `/group/${id}/out`,

    newsEdit: `/news/${id}/edit`,
    getLinkBanner: `/news/${id}/upload_banner`,

    comments: `/post/${id}/comments/${id2}/edit`,
    commentsNumLike: `/post/${id}/comments/${id2}/updateNumLike`,
    replies: `/reply/${id}/edit`,
    repliesNumLike: `/reply/${id}/updateNumLike`,
  }
}

const DELETE_API = (id, id2) => {
  return {
    postDelete: `/posts/${id}`,
    comments: `/post/${id}/comments/${id2}/delete`,
    replies: `/reply/${id}/delete`,
  }
}

export { GET_API, POST_API, UPDATE_API, DELETE_API }
