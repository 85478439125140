import axios from 'utils/axios'
import { isValidToken, setSession, getIdByToken } from 'utils/jwt'
import { GET_API, POST_API, UPDATE_API } from 'routes/api'

const { createSlice } = require('@reduxjs/toolkit')

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    notification: {
      totalPage: null,
      noties: [],
      totalLength: null,
    },
    isAuthenticated: null,
    isLoading: false,
    isLoadingUpload: false,
  },
  reducers: {
    LOGIN_LOADING: (state, action) => {
      state.isLoading = action.payload
    },
    LOGIN_SUCCESS: (state, action) => {
      state.user = action.payload
      state.isAuthenticated = true
      state.isLoading = false
    },
    LOGIN_FAIL: state => {
      localStorage.removeItem('accessToken')
      state.isAuthenticated = false
      state.isLoading = false
    },
    CLEAR_AUTHENTICATION: state => {
      state.user = {}
      state.isAuthenticated = null
    },
    UPDATE_AVATAR_LOADING: state => {
      state.isLoadingUpload = true
    },
    UPDATE_AVATAR: (state, action) => {
      state.user = { ...state.user, image_url: action.payload }
      state.isLoadingUpload = false
    },
    UPDATE_AVATAR_FAIL: state => {
      state.isLoadingUpload = false
    },
    SET_NOTIFICATION: (state, action) => {
      state.notification = action.payload
    },
    SET_NOTIFICATION_SOCKET: (state, action) => {
      const newNotification = state.notification
      state.notification = { newNotification, noties: [action.payload, ...newNotification.noties] }
    },
  },
})

const { reducer, actions } = authSlice

export const {
  SET_NOTIFICATION_SOCKET,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CLEAR_AUTHENTICATION,
  UPDATE_AVATAR_LOADING,
  LOGIN_LOADING,
} = actions

export default reducer

export function GET_NOTIFICATIONS(page = 1) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`${GET_API().notifications}?page=${page}`)
      console.log('get noties', response, page)

      if (response.data.status === 'success') {
        const currentState = getState().auth.notification

        // scroll
        if (page > 1) {
          const newNotification = [...currentState.noties, ...response.data.noties]
          console.log('new noti page >2', newNotification, currentState)
          return dispatch(authSlice.actions.SET_NOTIFICATION({ ...currentState, noties: newNotification }))
        }

        const { totalPage, noties, length } = response.data

        const newNotifications = {
          totalPage,
          noties: [...currentState.noties, ...noties],
          totalLength: length,
        }
        dispatch(authSlice.actions.SET_NOTIFICATION(newNotifications))
      }
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function GET_USER_AUTH() {
  return async dispatch => {
    try {
      const accessToken = localStorage.getItem('accessToken')

      if (accessToken && isValidToken(accessToken) && getIdByToken(accessToken)) {
        const id = getIdByToken(accessToken)

        setSession(accessToken)

        const response = await axios.get(GET_API(id).getUserID)
        console.log('GET_USER_AUTH', response)
        const { user } = response.data

        dispatch(authSlice.actions.LOGIN_SUCCESS({ ...user, id }))
      } else {
        dispatch(authSlice.actions.LOGIN_FAIL())
      }
    } catch (error) {
      console.log('err', error)
      dispatch(authSlice.actions.CLEAR_AUTHENTICATION())
    }
  }
}

export function UPLOAD_AVATAR(userID, files) {
  return async dispatch => {
    dispatch(authSlice.actions.UPDATE_AVATAR_LOADING())

    const form = new FormData()
    form.append('file', files)

    try {
      const response = await axios.post(POST_API(userID).uploadAvatar, form)
      dispatch(authSlice.actions.UPDATE_AVATAR(response.data.image.path))
    } catch (error) {
      console.log('error', error.response)
      dispatch(authSlice.actions.UPDATE_AVATAR_FAIL())
    }
  }
}

export function UPDATE_SEEN_NOTIFICATION(notiID) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(GET_API(notiID).notificationUpdateSeen)
      if (response.data.status === 'success') {
        const currentState = getState().auth
        const newNoties = currentState.notification?.noties?.map(noti => {
          if (noti?._id !== notiID) return noti
          return { ...noti, seen: true }
        })

        dispatch(authSlice.actions.SET_NOTIFICATION({ ...currentState.notification, noties: newNoties }))
      }
    } catch (error) {
      console.log('error', error)
    }
  }
}
