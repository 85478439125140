import * as React from 'react'
import { styled, useTheme } from '@mui/styles'
import { useAuth, useI18n } from 'hooks'

import {
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Avatar,
  TextField,
  InputAdornment,
  Divider,
  CircularProgress,
  useMediaQuery,
} from '@mui/material'

import { MButton } from 'components/_material-extend'
import GridImage from 'components/GridImage'
import UploadActions from 'components/UploadActions'
import LoadingForm from 'components/LoadingForm'

const MButtonStyle = styled(MButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 3),
}))

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& input': {
    padding: theme.spacing(1.6875, 2.375),
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 5,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23)!important',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

// const data = [Banner, Banner1, Rectangle1, Rectangle2]
const initialize = { title: '', content: '', files: [] }

export default function ForumPostEdit(props) {
  const { handleSubmit, handleClose, open, data, name } = props
  const theme = useTheme()
  const { t } = useI18n({ keyPrefix: 'forum' })

  const matchesMobile = useMediaQuery(theme.breakpoints.down(425))

  const [form, setForm] = React.useState(initialize)
  const [disableBtn, setDisableBtn] = React.useState(false)

  const { user } = useAuth()

  const isFalse = !form.title || !form.content
  // update state
  React.useEffect(() => {
    if (!data || !data.title || !data.content) return
    const { path, title, content } = data

    setForm({ title, content, files: path })

    return () => setDisableBtn(false)
  }, [data])

  const handleChange = e => {
    if (e.target.name === 'title') {
      if (e.target.value.length > 300) return
    }
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmitPost = () => {
    handleSubmit(name, 'edit', { ...form, _id: data._id })
    setDisableBtn(true)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose('')}
      fullWidth={true}
      sx={{ '& .MuiDialog-paper': { maxWidth: 900 } }}
    >
      <DialogContent sx={{ pl: 3.75, pr: 5.75, pt: 3.75, pb: 2 }}>
        <Stack direction={!matchesMobile ? 'row' : 'column'} alignItems={matchesMobile && 'center'} spacing={3.125}>
          <Avatar
            sx={{ width: !matchesMobile ? 50 : 32, height: !matchesMobile ? 52 : 32 }}
            src={user?.image_url || true}
          />
          <Stack spacing={2.25} sx={{ flexGrow: 1 }}>
            <TextFieldStyle
              name="title"
              placeholder="title"
              value={form.title}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{form.title.length}/300</InputAdornment>,
              }}
            />

            <TextFieldStyle
              placeholder="Text (Option)"
              value={form.content}
              multiline
              rows={4}
              name="content"
              onChange={handleChange}
            />
            <Box sx={{ mt: 1 }}>
              <Divider />
            </Box>
            {data?.path?.length ? (
              <Box>
                <GridImage images={data?.path} isRemove={false} />
              </Box>
            ) : (
              ''
            )}
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ pl: 12.5, pr: 5.75, pt: 1.825, pb: 2.75 }}>
        <Stack direction="row" alignItems="center" spacing={1.8}>
          <MButtonStyle onClick={() => handleClose('')} variant="outlined">
            {t('input.cancel')}
          </MButtonStyle>
          <MButtonStyle onClick={handleSubmitPost} variant="" disabled={disableBtn || isFalse}>
            {!disableBtn ? t('input.edit') : <CircularProgress sx={{ color: '#fff' }} size={20} />}
          </MButtonStyle>
        </Stack>
      </DialogActions>

      <LoadingForm open={disableBtn} handleClose={e => console.log(e)} />
    </Dialog>
  )
}
