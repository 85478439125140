import {
  SET_NAME,
  SET_SELECTED_STATES,
  SET_INPUT_STATES,
  SET_OPTION_STATES,
  SET_RADIO_STATES,
  SET_NEW_CONTRACT,
  SET_NEW_PARTIES,
  SET_AGREED_CONTENT,
} from 'store/reducers/ContractSlice'
import { useDispatch, useSelector } from 'react-redux'

export default function useNews() {
  const dispatch = useDispatch()

  const name = useSelector(state => state.contract.name)
  const selectedStates = useSelector(state => state.contract.selectedStates)
  const inputStates = useSelector(state => state.contract.inputStates)
  const optionStates = useSelector(state => state.contract.optionStates)
  const radioStates = useSelector(state => state.contract.radioStates)
  const agreedTerms = useSelector(state => state.contract.agreedTerms)
  const parties = useSelector(state => state.contract.parties)
  const agreedContents = useSelector(state => state.contract.agreedContents)

  const handleChangeName = value => dispatch(SET_NAME(value))
  const handleChangeSelectedStates = lists => dispatch(SET_SELECTED_STATES(lists))
  const handleChangeInputStates = form => dispatch(SET_INPUT_STATES(form))
  const handleChangeOptionStates = form => dispatch(SET_OPTION_STATES(form))
  const handleChangeRadioStates = form => dispatch(SET_RADIO_STATES(form))
  const handleChangeAgreedTerms = lists => dispatch(SET_NEW_CONTRACT(lists))
  const handleChangeParties = form => dispatch(SET_NEW_PARTIES(form))
  const handleAddContent = values => dispatch(SET_AGREED_CONTENT(values))

  return {
    name,
    selectedStates,
    inputStates,
    optionStates,
    radioStates,
    agreedTerms,
    agreedContents,
    parties,

    handleChangeName,
    handleChangeSelectedStates,
    handleChangeInputStates,
    handleChangeOptionStates,
    handleChangeRadioStates,
    handleChangeAgreedTerms,
    handleAddContent,
    handleChangeParties,
  }
}
