import { styled, useTheme } from '@mui/styles'
import { Box, useMediaQuery } from '@mui/material'

const Root = styled(Box)(() => ({}))

const TitleMain = styled(Box)(({ theme }) => ({
  borderRadius: 2,
  background: theme.palette.primary.main,
  padding: theme.spacing(1.5, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 2),
  },
}))

const ContainerRight = {
  flexShrink: 1,
  maxWidth: 320,
  minWidth: 320,
  '@media (max-width:1024px)': {
    maxWidth: '100%',
  },
  '@media (max-width:600px)': {
    minWidth: 0,
    paddingLeft: 3,
    paddingRight: 3,
  },
}

export { Root, TitleMain, ContainerRight }
