import React from 'react'
import { Link as ReactRouter, useLocation } from 'react-router-dom'
import { styled } from '@mui/styles'
import { Stack, Typography } from '@mui/material'
import { PATH_PAGE } from 'routes/paths'

const links = [
  { title: 'Edit Profile', titleVn: 'Sửa thông tin', path: `${PATH_PAGE.profile}/edit` },
  { title: 'Account Settings', titleVn: 'Cài đặt tài khoản', path: `${PATH_PAGE.profile}/setting` },
]

export default function ProfileNavLink({ getLanguage }) {
  const { pathname } = useLocation()

  const ReactRouterStyle = styled(ReactRouter)(({ theme, active }) => ({
    color: !active ? '#909096' : theme.palette.primary.main,
    fontWeight: 'bold',
    position: 'relative',
    transition: 'all 300ms ease',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: !active ? '0%' : '70%',
      height: 2,
      bottom: 0,
      left: 0,
      background: theme.palette.primary.main,
    },

    '&:hover': {
      color: theme.palette.primary.main,
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '70%',
        height: 2,
        bottom: 0,
        left: 0,
        transition: 'width 500ms',
        transitionTimingFunction: 'ease-in-out',
        background: theme.palette.primary.main,
      },
    },
  }))

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={6.5} sx={{ mt: 1 }}>
      {links.map(link => (
        <Typography
          variant="h6"
          component={ReactRouterStyle}
          to={link.path}
          key={link.title}
          active={pathname.includes(link.path) || false}
        >
          {getLanguage === 'en' ? link.title : link.titleVn}
        </Typography>
      ))}
    </Stack>
  )
}
