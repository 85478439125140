import React from 'react'
import { Outlet } from 'react-router-dom'
import { styled, useTheme } from '@mui/styles'
import { Box, useMediaQuery, Grid } from '@mui/material'

// component
import BannerAuth from 'components/authentication/Banner'

export default function index() {
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={!matchesMobile ? 5 : 12}>
        <BannerAuth />
      </Grid>
      <Grid item xs={!matchesMobile ? 7 : 12}>
        <Outlet />
      </Grid>
    </Grid>
  )
}
