import React from 'react'
import { styled } from '@mui/styles'
import { Box, TextField, InputAdornment, IconButton } from '@mui/material'
import { Root } from './styles'

import SearchIcon from '@mui/icons-material/Search'

const Container = styled(Box)(() => ({}))

export default function ForumInputField(props) {
  const { handleChange, handleSubmit } = props

  return (
    <Container data-aos="fade-up">
      <TextField
        // onChange={handleChange}
        placeholder="Search topic"
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                // onClick={handleSubmit}
                edge="end"
                sx={{ '& svg': { width: 22, height: 22 } }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            pt: 2,
            pb: 1.75,
            pl: 4.5,
            pr: 3,
            background: ' rgba(238, 238, 238, 0.69)',
            borderRadius: '5px',
          },
          '& input': {
            padding: 0,
            color: '#C4C4C4',
            fontSize: 16,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      />
    </Container>
  )
}
