import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  HANDLE_SUBMIT_COMMENT,
  SET_COMMENTS,
  GET_COMMENTS_BY_ID,
  GET_REPLIES_BY_ID,
  ADD_COMMENT,
  DELETE_COMMENT,
  EDIT_COMMENT,
  NUMLIKE_COMMENT,
  UPDATE_COMMENT_SOCKET,
  DELETE_COMMENT_SOCKET,
  // REPLY
  UPDATE_REPLY_SOCKET,
  DELETE_REPLY_SOCKET,
  ADD_REPLY,
  EDIT_REPLY,
  DELETE_REPLY,
  NUMLIKE_REPLY,
} from 'store/reducers/CommentSlice'
import axios from 'utils/axios'

export default function useComment() {
  const dispatch = useDispatch()

  // redux
  const comments = useSelector(state => state.comment.comments)
  const totalPageComment = useSelector(state => state.comment.totalPage)

  const handleGetComments = (postID, page = 1) => {
    dispatch(GET_COMMENTS_BY_ID(postID, page))
  }

  const handleSubmitComments = (form, isReply = false) => {
    dispatch(HANDLE_SUBMIT_COMMENT(form, isReply))
  }
  const handleUpdateCommentSocket = comment => dispatch(UPDATE_COMMENT_SOCKET(comment))
  const handleDeleteCommentSocket = comment => dispatch(DELETE_COMMENT_SOCKET(comment))

  const handleAddComment = reply => dispatch(ADD_COMMENT(reply))
  const handleNumLikeComment = (comment, numLikes) => dispatch(NUMLIKE_COMMENT(comment, numLikes))

  const handleEditComment = comment => dispatch(EDIT_COMMENT(comment))
  const handleDeleteComment = comment => dispatch(DELETE_COMMENT(comment))

  const handleUpdateReplySocket = reply => dispatch(UPDATE_REPLY_SOCKET(reply))
  const handleAddReply = reply => dispatch(ADD_REPLY(reply))
  const handleMoreReply = commentID => dispatch(GET_REPLIES_BY_ID(commentID))
  const handleEditReply = commentForm => dispatch(EDIT_REPLY(commentForm))
  const handleDeleteReply = comment => dispatch(DELETE_REPLY(comment))
  const handleNumLikeReply = (commentID, form) => dispatch(NUMLIKE_REPLY(commentID, form))
  const handleDeleteReplySocket = reply => dispatch(DELETE_REPLY_SOCKET(reply))
  // const handleAddReply = reply => {
  //   dispatch(SET_REPLY_SOCKET(reply))
  // }

  const handlerToggleReply = comment => {
    if (!comment?.isShowReply) handleMoreReply(comment?._id)
    const newComments = comments.map(c => {
      if (c?._id !== comment?._id) return c
      return { ...c, isReply: !c?.isReply ? true : false }
    })
    dispatch(SET_COMMENTS(newComments))
  }

  const handleFocusCommentByState = commentID => {
    const newComments = comments.map(comment => {
      if (comment?._id !== commentID) return comment
      return { ...comment, isReply: true }
    })

    dispatch(SET_COMMENTS(newComments))
  }
  return {
    comments,
    totalPageComment,
    handleAddComment,
    handleGetComments,
    handleSubmitComments,
    handleNumLikeComment,
    handleUpdateCommentSocket,
    handleDeleteCommentSocket,
    handleEditComment,
    handleDeleteComment,
    handleFocusCommentByState,
    // reply
    handleUpdateReplySocket,
    handleDeleteReplySocket,
    handleMoreReply,
    handleAddReply,
    handleEditReply,
    handleDeleteReply,
    handleNumLikeReply,
    handlerToggleReply,
  }
}
