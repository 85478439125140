import React from 'react'
import LazyLoad from 'react-lazyload'
import { styled, useTheme } from '@mui/styles'
import { Box, Typography, useMediaQuery, Stack, Button } from '@mui/material'
import { TitleField, Root } from './styles'
import LineClamp from 'components/LineClamp'
import { PATH_PAGE } from 'routes/paths'

import Rectangle1 from 'assets/images/landing/pipeline2.png'
import Rectangle2 from 'assets/images/landing/Rectangle14.png'
import Rectangle3 from 'assets/images/landing/Rectangle15.png'
import Rectangle4 from 'assets/images/landing/doc.png'
import { useDocument, useI18n } from 'hooks'
import { useNavigate } from 'react-router-dom'

import docTitleJSON from 'assets/data/docsTitle'

const bg = [Rectangle1, Rectangle2, Rectangle3, Rectangle4]

const GridContainer = styled(Box)(({ theme }) => ({
  height: 510,
  display: 'grid!important',
  gridTemplateColumns: '9fr 2fr 9fr',
  gridTemplateRows: '15px 15px 15px 15px',

  gridTemplateAreas: `
    'grid-0 grid-9 grid-1'
    'grid-0 grid-9 grid-2'
    'grid-0 grid-9 grid-3'
    'grid-0 grid-9 grid-4'
  `,
  gap: 15,
}))

const LegalItem = ({ title, text, handleRedirect, children, bg, path, mobile, resMobile, float, xs = 6, index }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const textColor = 'white'

  return (
    <Box
      onClick={handleRedirect}
      sx={{
        gridArea: `grid-${index}`,
        background: `url(${bg})`,
        backgroundRepeat: 'no-peat',
        backgroundSize: 'cover',
        // width: '50%',
        // maxWidth: !mobile ? 600 : 'none',
        // height: !resMobile ? '100px' : '27%',
        position: 'relative',
        borderRadius: 0.5,
        float: `${float}`,
        '&:hover': {
          '& a': {
            transition: 'all 300ms ease-in',
            color: 'primary.main',
          },
        },
      }}
    >
      <Stack sx={{ padding: 4 }}>
        <Typography sx={{ fontWeight: 600, color: textColor, fontSize: 18, marginBottom: 2 }}>{title}</Typography>
        <Typography sx={{ color: textColor, fontSize: 12 }}>{text}</Typography>
      </Stack>
    </Box>
  )
}

export default function LandingLegal({ closeDropdown }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down(1300))
  const matchesMobile = useMediaQuery(theme.breakpoints.down('md'))
  const resMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const navigate = useNavigate()
  const { t, getLanguage } = useI18n({ keyPrefix: 'home.legal' })
  const { handleUpdateType } = useDocument()

  const handleRedirect = type => {
    navigate(PATH_PAGE.documents)
    handleUpdateType(type)
    closeDropdown()
  }

  return (
    // <Root sx={{ padding: '0px !important', justifyContent: 'center' }}>
    //   {/* <TitleField title={t('title')}>{t('title_des')}</TitleField> */}
    //   <Grid container columnSpacing={!matches ? 15 : 5}>
    //     {docTitleJSON.map((d, ind) => (
    //       <LegalItem
    //         key={d.title}
    //         title={getLanguage === 'en' ? d.title : d.titleVn}
    //         title1={d.title}
    //         mobile={matchesMobile}
    //         resMobile={resMobile}
    //         bg={bg[ind]}
    //         xs={(matchesMobile && 12) || 6}
    //         float={!matchesMobile && d.float}
    //         handleRedirect={handleRedirect}
    //       >
    //         {getLanguage === 'en' ? d.text : d.textVn}
    //       </LegalItem>
    //     ))}
    //   </Grid>
    // </Root>
    <Root isDropdown={true} sx={{ mb: 0, backgroundColor: 'white' }}>
      <GridContainer>
        <TitleField isDropdown grid={0} title={t('title')}>
          {t('title_des')}
        </TitleField>
        <div
          style={{ gridArea: 'grid-9', height: 510, marginRight: 30, alignItems: 'center', justifyContent: 'center' }}
        >
          <div style={{ marginRight: 1, borderRight: '1px solid #eeeded', height: 510 }} />
        </div>
        {docTitleJSON.map((item, index) => {
          return (
            // <LegalItem
            //   index={index + 1}
            //   key={d.title}
            //   title={getLanguage === 'en' ? d.title : d.titleVn}
            //   text={d.text}
            //   mobile={matchesMobile}
            //   resMobile={resMobile}
            //   bg={bg[index]}
            //   xs={(matchesMobile && 12) || 6}
            //   float={!matchesMobile && d.float}
            //   handleRedirect={() => handleRedirect(d.title)}
            // >
            //   {getLanguage === 'en' ? d.text : d.textVn}
            // </LegalItem>
            <Button
              className="option"
              onClick={() => handleRedirect(item.path)}
              sx={{
                marginTop: 5,
                gridArea: `grid-${index + 1}`,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                fontWeight: 'bold',
                backgroundColor: 'white',
                padding: '0 !important',
              }}
            >
              <Typography
                sx={{
                  borderRadius: 1,
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: 'black',
                }}
              >
                {getLanguage === 'en' ? item.title : item.titleVn}
              </Typography>
            </Button>
          )
        })}
      </GridContainer>
    </Root>
  )
}
