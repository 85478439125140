import * as React from 'react'
import { styled, useTheme } from '@mui/styles'
import { useAuth } from 'hooks'
import { formatImg, handleLayout } from 'utils/formatImgs'

import {
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Avatar,
  TextField,
  InputAdornment,
  Divider,
  CircularProgress,
  useMediaQuery,
  Typography,
} from '@mui/material'

import { ReactPhotoCollage } from 'react-photo-collage'

import { MButton } from 'components/_material-extend'
import GridImage from 'components/GridImage'
import UploadActions from 'components/UploadActions'
import LoadingForm from 'components/LoadingForm'
import TextEditorV2 from 'components/TextEditorV2'

const MButtonStyle = styled(MButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 3),
}))

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  width: '100%',
  height: '39px !important',
  marginTop: '15px !important',
  '& .MuiOutlinedInput-root': {
    fontSize: '14px !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    color: '#1c1c1c',
    border: '1px solid #EDEFF1',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

// const data = [Banner, Banner1, Rectangle1, Rectangle2]
const initialize = { title: '', content: '', files: [] }

let isSubmit = false

export default function ForumPostCreate(props) {
  const { handleSubmit, handleClose, open, name, t } = props
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down(425))
  const { user } = useAuth()

  const [images, setImages] = React.useState([])
  const [form, setForm] = React.useState(initialize)
  const [disableBtn, setDisableBtn] = React.useState(false)

  const isFalse = !form.content

  React.useEffect(() => {
    if (!open) return
    setForm(initialize)
    return () => {
      setDisableBtn(false)
      if (isSubmit) {
        setImages([])
      }
    }
  }, [open])

  const handleRemoveImage = id => {
    const getIndex = images.findIndex(source => source.id === id)
    const newSource = images.filter(source => source.id !== id)
    const newFiles = form.files
    newFiles.splice(getIndex, 1)

    setImages(newSource)
    setForm({ ...form, files: newFiles })
  }

  const handleChange = e => {
    if (e.target.name === 'title') {
      if (e.target.value.length > 300) return
    }
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleChangeQuill = value => {
    setForm({ ...form, content: value })
  }

  const handleClickImageUpload = (sources, files) => {
    setImages(sources) // base 64
    setForm({ ...form, files })
  }

  const handleSubmitPost = () => {
    handleSubmit(name, 'create', form)
    setDisableBtn(true)
    isSubmit = true
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose('')}
      fullWidth={true}
      sx={{ '& .MuiDialog-paper': { maxWidth: 552 } }}
    >
      <DialogContent sx={{ pl: 2, pr: 2, pt: 2, pb: 2 }}>
        <Stack direction={'column'} alignItems={matchesMobile ? 'center' : 'flex-start'} spacing={3.125}>
          <Stack direction={'row'} alignItems={'center'}>
            <Avatar
              sx={{ width: !matchesMobile ? 35 : 30, height: !matchesMobile ? 35 : 30 }}
              src={user?.image_url || true}
            />
            <Typography style={{ fontSize: 14, marginLeft: 12, color: '#ca3636', fontWeight: 600 }}>
              {user?.fullname}
            </Typography>
          </Stack>

          <TextFieldStyle placeholder={t('input.title')} name="title" onChange={handleChange} />

          <Stack spacing={2} sx={{ width: '100%', fontSize: !matchesMobile ? 16 : 14 }}>
            <TextEditorV2 value={form.content} onChange={value => handleChangeQuill(value)} placeholder="Text" />
            <Box>
              <Divider />
            </Box>
            {images.length ? (
              <Box>
                <GridImage files={form?.files} images={images} handleRemove={handleRemoveImage} />
              </Box>
            ) : (
              ''
            )}
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ pl: 2, pr: 2, pt: 0, pb: 2.75 }}>
        <UploadActions handleClickImageUpload={handleClickImageUpload} open={open} isCreate />

        <Stack direction="row" alignItems="center" spacing={1.8}>
          <MButtonStyle onClick={() => handleClose('')} variant="outlined">
            {t('input.cancel')}
          </MButtonStyle>
          <MButtonStyle onClick={handleSubmitPost} variant="contained" disabled={disableBtn || isFalse}>
            {!disableBtn ? t('input.posts') : <CircularProgress sx={{ color: '#fff' }} size={20} />}
          </MButtonStyle>
        </Stack>
      </DialogActions>

      <LoadingForm open={disableBtn} handleClose={e => console.log(e)} />
    </Dialog>
  )
}
