import React from 'react'
import { styled } from '@mui/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, TextField, Avatar, Divider, Stack, IconButton, InputAdornment } from '@mui/material'

import { useAuth, useI18n } from 'hooks'

import UploadActions from 'components/UploadActions'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  borderRadius: 5,
  '& input': {
    background: 'rgba(196, 196, 196, 0.16)',
    borderRadius: 5,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))

export default function ForumInputField(props) {
  const { nameState, isSingleFile = false } = props // action
  const { children, handleCreatePost, handleClickImageUpload, handleChange, fieldValue, handleSubmit } = props
  const { user, isAuthenticated } = useAuth()
  const { t } = useI18n({ keyPrefix: 'forum' })
  const navigate = useNavigate()
  const location = useLocation().pathname

  const handleNotLogin = () => {
    if (window.confirm('You need to be authenticated to write a post. Login now?')) {
      localStorage.setItem('prevPath', location)
      navigate('/auth/login')
    }
  }

  const createPost = () => {
    handleCreatePost(nameState, 'create')
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
      <Avatar sx={{ width: 32, height: 32, mt: !handleCreatePost && 0.5 }} src={user?.image_url || true} />
      <Stack spacing={2.625} sx={{ width: '100%' }}>
        {handleCreatePost ? (
          <TextFieldStyle
            placeholder={t('input.post')}
            disabled
            onClick={isAuthenticated ? createPost : handleNotLogin}
            sx={{
              '& input': {
                cursor: 'pointer',
                fontSize: 16,
                py: 1.5,
                px: 2.5,
              },
            }}
          />
        ) : (
          <Stack sx={{ width: '100%' }} spacing={1}>
            <TextFieldStyle
              placeholder={t('input.comment')}
              name="content"
              onKeyDown={e => (e.key === 'Enter' ? handleSubmit(e) : '')}
              value={fieldValue}
              onChange={handleChange}
              autoFocus={false}
              sx={{
                background: 'rgba(196, 196, 196, 0.3)',
                p: 0,
                '& input': {
                  borderRadius: 3.1875,
                  fontSize: 14,
                  py: 1.5,
                  px: 0,
                  background: 'rgba(196, 196, 196, 0)!important',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <UploadActions handleClickImageUpload={handleClickImageUpload} isSingle={isSingleFile} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ '& svg': { width: 18 } }} onClick={handleSubmit}>
                      <SendOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
