import axios from 'utils/axios'
import { GET_API, POST_API, UPDATE_API, DELETE_API } from 'routes/api'
import { SET_ALERT_JOINED_GROUP } from 'store/reducers/GroupSlice'

const { createSlice } = require('@reduxjs/toolkit')

const postSlice = createSlice({
  name: 'post',
  initialState: {
    posts: [],
    postsGroup: [],
    postsProfile: [],
    postDetail: null,
    postsRelatedByGroup: [],
    postsTrending: [],
    postsTopic: [],
    postRelated: [],

    page: 1,
    isLoading: true,
    isNotFound: null,
    messages: null,

    isCreatePost: false,
    isEditPost: false,
    isDelete: false,
    savePostTemporary: null,
  },
  reducers: {
    SET_POSTS: (state, action) => {
      state.posts = action.payload
      state.isLoading = false
    },
    SET_POSTS_GROUP: (state, action) => {
      state.postsGroup = action.payload
      state.isLoading = false
    },
    SET_POSTS_TRENDING: (state, action) => {
      state.postsTrending = action.payload
    },
    SET_POST_DETAIL: (state, action) => {
      state.postDetail = action.payload
      state.isLoading = false
    },
    SET_POSTS_PROFILE: (state, action) => {
      state.postsProfile = action.payload
      state.isLoading = false
    },

    // ----
    SET_POSTS_RELATED_BY_GROUP: (state, action) => {
      state.postsRelatedByGroup = action.payload
    },
    SET_POSTS_TOPIC: (state, action) => {
      state.postsTopic = action.payload
    },
    SET_POST_RELATED: (state, action) => {
      state.postRelated = action.payload
    },

    HANDLE_CREATE_POST: state => {
      state.isCreatePost = !state.isCreatePost
    },
    HANDLE_EDIT_POST: state => {
      state.isEditPost = !state.isEditPost
    },
    HANDLE_DELETE_POST: state => {
      state.isDeletePost = !state.isDeletePost
    },
    HANDLE_SAVE_POST_TEMPORARY: (state, action) => {
      state.savePostTemporary = action.payload
    },
    HANDLE_MESSAGE: (state, action) => {
      state.isDeletePost = false
      state.isEditPost = false
      state.isCreatePost = false
      state.savePostTemporary = null
      state.messages = action.payload
    },

    HANDLE_STATUS_NOT_FOUND: (state, action) => {
      state.isNotFound = action.payload
    },
  },
})

const { reducer, actions } = postSlice

export const {
  SET_POSTS,
  SET_POSTS_GROUP,
  HANDLE_CREATE_POST,
  HANDLE_EDIT_POST,
  HANDLE_DELETE_POST,
  HANDLE_SAVE_POST_TEMPORARY,
  HANDLE_MESSAGE,
} = actions

export default reducer

export function GET_POSTS(page = 1) {
  return async (dispatch, getState) => {
    // dispatch(postSlice.actions.POST_DETAIL_STATUS_LOADING())
    try {
      const response = await axios.get(`${GET_API().posts}?page=${page}`)
      const currentState = getState().post
      const newPost = [...response.data.posts, ...currentState.posts]

      dispatch(postSlice.actions.SET_POSTS(newPost))
    } catch (error) {
      // dispatch(postSlice.actions.POST_DETAIL_STATUS_FAIL())

      console.log('err', error)
    }
  }
}
export function GET_POSTS_RELATED_BY_GROUP(groupID) {
  return async dispatch => {
    try {
      const response = await axios.get(`${GET_API(groupID).postRelatedByGroup}`)
      if (response.data.status === 'error') {
        return
      }

      dispatch(postSlice.actions.SET_POSTS_RELATED_BY_GROUP(response.data.posts))
    } catch (error) {
      // dispatch(postSlice.actions.POST_DETAIL_STATUS_FAIL())
      console.log('err', error)
    }
  }
}
export function GET_POSTS_TRENDING() {
  return async dispatch => {
    try {
      const response = await axios.get(`${GET_API().postTrendingToday}`)
      if (response.data.status === 'error') {
        return
      }

      dispatch(postSlice.actions.SET_POSTS_TRENDING(response.data.posts))
    } catch (error) {
      // dispatch(postSlice.actions.POST_DETAIL_STATUS_FAIL())
      console.log('err', error)
    }
  }
}

export function GET_POST_BY_ID(id) {
  return async dispatch => {
    dispatch(postSlice.actions.HANDLE_STATUS_NOT_FOUND(false))
    try {
      const response = await axios.get(`${GET_API(id).postById}`)

      if (response.data.status === 'error') {
        dispatch(postSlice.actions.HANDLE_STATUS_NOT_FOUND(true))
        return dispatch(
          postSlice.actions.HANDLE_MESSAGE({ status: response.data.status, message: response.data.message })
        )
      }
      if (response.data?.group?._id) {
        dispatch(GET_POSTS_RELATED_BY_GROUP(response.data?.group?._id))
      }
      dispatch(postSlice.actions.SET_POST_DETAIL(response.data))
      postSlice.actions.HANDLE_MESSAGE({ status: 'success', message: 'Create Post Success' })
    } catch (error) {
      // dispatch(postSlice.actions.POST_DETAIL_STATUS_FAIL())
      console.log('err', error)
    }
  }
}

export function GET_POSTS_BY_USER(id) {
  return async (dispatch, getState) => {
    let currentState = id
    try {
      if (!id) {
        currentState = getState().auth.user.id
      }

      const response = await axios.get(`${GET_API(currentState).postByUser}?page=1`)

      if (response.data.status === 'error') {
        return
      }
      dispatch(postSlice.actions.SET_POSTS_PROFILE(response.data.posts))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function CREATE_POSTS(nameState, forms) {
  return async (dispatch, getState) => {
    try {
      const { title, content, files, groupID = null } = forms
      const formData = new FormData()

      // ---- set up formData
      if (files.length) {
        await files.map(file => formData.append('files', file))
      }
      await formData.append('title', title)
      await formData.append('content', content)

      if (!groupID) {
        // posts in forum
        const response = await axios.post(POST_API().posts, formData)

        const currentState = getState().post

        if (nameState === 'posts') {
          const newPosts = [response.data.data, ...currentState.posts]
          dispatch(postSlice.actions.SET_POSTS(newPosts))
        }
        if (nameState === 'postsProfile') {
          const newPosts = [response.data.data, ...currentState.postsProfile]
          dispatch(postSlice.actions.SET_POSTS_PROFILE(newPosts))
        }
      } else {
        // create posts of Group
        const response = await axios.post(POST_API(groupID).postsByGroup, formData)

        const currentState = getState().post
        const newPostsGroup = [response.data.data, ...currentState.postsGroup]

        // console.log('CREATE_POST_BY_GROUP', response, currentState.groupDetail)
        dispatch(postSlice.actions.SET_POSTS_GROUP(newPostsGroup))
      }

      // -----------------

      dispatch(postSlice.actions.HANDLE_MESSAGE({ status: 'success', message: 'Create Post Success' }))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function EDIT_POSTS(nameState, id, form) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.patch(`${UPDATE_API(id).postEdit}`, form)
      if (response.data.status === 'error') {
        return dispatch(
          postSlice.actions.HANDLE_MESSAGE({ status: response.data.status, message: response.data.message })
        )
      }
      const currentState = getState().post
      // posts
      if (nameState === 'posts') {
        const newPosts = currentState.posts.map(post => {
          if (post._id === id) {
            const newPost = { ...post, ...form }
            return newPost
          }
          return post
        })
        dispatch(postSlice.actions.SET_POSTS(newPosts))
      }
      // posts group
      if (nameState === 'postsGroup') {
        const newPosts = currentState.postsGroup.map(post => {
          if (post._id === id) {
            const newPost = { ...post, ...form }
            return newPost
          }
          return post
        })
        dispatch(postSlice.actions.SET_POSTS_GROUP(newPosts))
      }
      // post detail
      if (nameState === 'postDetail') {
        const postDetailCurrent = currentState.postDetail
        const newPost = { ...postDetailCurrent, post: { ...postDetailCurrent.post, ...form } }
        dispatch(postSlice.actions.SET_POST_DETAIL(newPost))
      }

      // post profile
      if (nameState === 'postsProfile') {
        const newPosts = currentState.postsProfile.map(post => {
          if (post._id === id) {
            const newPost = { ...post, ...form }
            return newPost
          }
          return post
        })
        dispatch(postSlice.actions.SET_POSTS_PROFILE(newPosts))
      }
      // ------
      dispatch(postSlice.actions.HANDLE_MESSAGE({ status: 'success', message: 'Edit Post Success' }))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function DELETE_POSTS(nameState, id) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.delete(`${DELETE_API(id).postDelete}`)
      if (response.data.status === 'error') {
        return dispatch(
          postSlice.actions.HANDLE_MESSAGE({ status: response.data.status, message: response.data.message })
        )
      }
      const currentState = getState().post

      if (nameState === 'posts') {
        const newPosts = currentState.posts.filter(post => post._id !== id)
        dispatch(postSlice.actions.SET_POSTS(newPosts))
      }
      if (nameState === 'postsGroup') {
        const newPosts = currentState.postsGroup.filter(post => post._id !== id)
        dispatch(postSlice.actions.SET_POSTS_GROUP(newPosts))
      }

      if (nameState === 'postDetail') {
        dispatch(postSlice.actions.SET_POST_DETAIL(null))
        dispatch(postSlice.actions.HANDLE_STATUS_NOT_FOUND(true))
      }

      if (nameState === 'postsProfile') {
        const newPosts = currentState.postsProfile.filter(post => post._id !== id)
        dispatch(postSlice.actions.SET_POSTS_PROFILE(newPosts))
      }
      dispatch(postSlice.actions.HANDLE_MESSAGE({ status: 'success', message: 'Delete Post Success' }))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function GET_POST_TOPICS() {
  return async dispatch => {
    try {
      const response = await axios.get(GET_API().postsTopic)
      dispatch(postSlice.actions.SET_POSTS_TOPIC(response.data.posts))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function UP_DOWN_VOTE(nameState, postID, type) {
  return async (dispatch, getState) => {
    try {
      const currentState = getState().post
      const getUser = getState().auth.user

      if (type === 'up') {
        const response = await axios.patch(UPDATE_API(postID).upVotePost)

        if (nameState === 'posts') {
          const newPost = currentState.posts.map(post => {
            if (post?._id !== postID) return post
            return {
              ...post,
              upvote: [getUser?.id, ...post.upvote],
              downvote: post.downvote.filter(p => p !== getUser?.id),
              numLikes: post.numLikes + (post.downvote.filter(p => p === getUser?.id).length ? 2 : 1),
              // if user have downvote then numLikes + 2
            }
          })
          return dispatch(postSlice.actions.SET_POSTS(newPost))
        }
        // posts group
        if (nameState === 'postsGroup') {
          const newPosts = currentState.postsGroup.map(post => {
            if (post?._id !== postID) return post
            return {
              ...post,
              upvote: [getUser?.id, ...post.upvote],
              downvote: post.downvote.filter(p => p !== getUser?.id),
              numLikes: post.numLikes + (post.downvote.filter(p => p === getUser?.id).length ? 2 : 1),
              // if user have downvote then numLikes + 2
            }
          })
          dispatch(postSlice.actions.SET_POSTS_GROUP(newPosts))
        }
        // post detail
        if (nameState === 'postDetail') {
          const postDetailCurrent = currentState.postDetail
          const newPost = {
            ...postDetailCurrent,
            post: {
              ...postDetailCurrent.post,
              upvote: [getUser?.id, ...postDetailCurrent.post.upvote],
              downvote: postDetailCurrent.post.downvote.filter(p => p !== getUser?.id),
              numLikes: postDetailCurrent.post.numLikes + 2,
              // (postDetailCurrent.post.downvote.filter(p => p === getUser?.id).length ? 2 : 1),
            },
          }
          return dispatch(postSlice.actions.SET_POST_DETAIL(newPost))
        }

        // post profile
        if (nameState === 'postsProfile') {
          const newPosts = currentState.postsProfile.map(post => {
            if (post?._id !== postID) return post
            return {
              ...post,
              upvote: [getUser?.id, ...post.upvote],
              downvote: post.downvote.filter(p => p !== getUser?.id),
              numLikes: post.numLikes + (post.downvote.filter(p => p === getUser?.id).length ? 2 : 1),
              // if user have downvote then numLikes + 2
            }
          })
          dispatch(postSlice.actions.SET_POSTS_PROFILE(newPosts))
        }
      }
      if (type === 'down') {
        const response = await axios.patch(UPDATE_API(postID).downVotePost)

        if (nameState === 'posts') {
          const newPost = currentState.posts.map(post => {
            if (post?._id !== postID) return post
            return {
              ...post,
              upvote: post.upvote.filter(p => p !== getUser?.id),
              downvote: [getUser?.id, ...post.downvote],
              numLikes: post.numLikes - (post.upvote.filter(p => p === getUser?.id).length ? 2 : 1),
            }
          })
          return dispatch(postSlice.actions.SET_POSTS(newPost))
        }
        if (nameState === 'postsGroup') {
          const newPosts = currentState.postsGroup.map(post => {
            if (post?._id !== postID) return post
            return {
              ...post,
              upvote: post.upvote.filter(p => p !== getUser?.id),
              downvote: [getUser?.id, ...post.downvote],
              numLikes: post.numLikes - (post.upvote.filter(p => p === getUser?.id).length ? 2 : 1),
            }
          })
          dispatch(postSlice.actions.SET_POSTS_GROUP(newPosts))
        }
        if (nameState === 'postDetail') {
          const postDetailCurrent = currentState.postDetail
          const newPost = {
            ...postDetailCurrent,
            post: {
              ...postDetailCurrent.post,
              upvote: postDetailCurrent.post.upvote.filter(p => p !== getUser?.id),
              downvote: [getUser?.id, ...postDetailCurrent.post.downvote],
              numLikes: postDetailCurrent.post.upvote.filter(p => p === getUser?.id).length ? 2 : 1,
            },
          }

          return dispatch(postSlice.actions.SET_POST_DETAIL(newPost))
        }
        // post profile
        if (nameState === 'postsProfile') {
          const newPosts = currentState.postsProfile.map(post => {
            if (post?._id !== postID) return post
            return {
              ...post,
              upvote: post.upvote.filter(p => p !== getUser?.id),
              downvote: [getUser?.id, ...post.downvote],
              numLikes: post.numLikes - (post.upvote.filter(p => p === getUser?.id).length ? 2 : 1),
            }
          })
          dispatch(postSlice.actions.SET_POSTS_PROFILE(newPosts))
        }
      }
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function HANDLE_JOIN_GROUP(id, value = false) {
  return async (dispatch, getState) => {
    try {
      // remove join group

      const currentState = getState().post
      const getUser = getState().auth.user

      // out group
      if (value) {
        const response = await axios.patch(UPDATE_API(id).removeJoinGroup)
        if (response.status === 'error') {
          return postSlice.actions.HANDLE_MESSAGE({ status: response.data.status, message: response.data.message })
        }

        const newPostDetail = currentState.postDetail
        const newGroup = { ...newPostDetail.group }

        newGroup.members = newGroup.members.filter(member => member !== getUser?.id)
        newGroup.numMembers -= 1

        dispatch(postSlice.actions.SET_POST_DETAIL({ ...newPostDetail, group: newGroup }))
      } else {
        // add group
        const response = await axios.patch(UPDATE_API(id).joinGroup)

        if (response.status === 'error') {
          return dispatch(
            postSlice.actions.HANDLE_MESSAGE({ status: response.data.status, message: response.data.message })
          )
        }

        const newPostDetail = currentState.postDetail
        const newGroup = { ...newPostDetail.group }

        newGroup.members = [...newGroup.members, getUser?.id]
        newGroup.numMembers += 1

        dispatch(postSlice.actions.SET_POST_DETAIL({ ...newPostDetail, group: newGroup }))
      }

      dispatch(postSlice.actions.HANDLE_MESSAGE({ status: 'success', message: 'You have joined this group' }))
      if (value) {
        dispatch(SET_ALERT_JOINED_GROUP({ open: false, id: null }))
      }
    } catch (error) {
      console.log('err', error)
    }
  }
}
