import { format, formatDistanceToNow } from 'date-fns'
import { useI18n } from 'hooks'
import { enUS, vi } from 'date-fns/locale'
// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy')
}

export function fDateOriginal(date) {
  return format(new Date(date), 'dd/MM/yyyy')
}

export function fDateTime(date) {
  return format(new Date(date), 'dd/MM HH:mm')
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p')
}

export function fToNow(date) {
  // const { getLanguage } = useI18n()
  const getLanguage = localStorage.getItem('i18nextLng')

  const oneDay = 86400000
  if (new Date().getTime() - Math.floor(new Date(date).getTime()) > oneDay) {
    return fDateTime(date)
  }
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: getLanguage === 'en' ? enUS : vi,
  })
}
