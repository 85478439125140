import React from 'react'
import { styled, useTheme } from '@mui/styles'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { ForumActions } from './index'
import LineClamp from 'components/LineClamp'
import { Link } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'

export default function ForumTopicItem(props) {
  const theme = useTheme()
  const { post, ind } = props
  const { numComments, title, _id } = post

  return (
    <Paper sx={{ px: 0, borderRadius: 0 }} elevation={0}>
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Box
          sx={{
            width: 36,
            minWidth: 36,
            height: 36,
            bgcolor: 'primary.light',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
              width: 32,
              minWidth: 32,
              height: 32,
            },
          }}
        >
          <Typography color="primary" variant="caption" sx={{ fontWeight: 700 }}>
            {`0${ind}`}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{ color: '#000', ...LineClamp(1) }}
            component={Link}
            to={`${PATH_PAGE.forums}/${_id}`}
            variant="subtitle2"
          >
            {title}
          </Typography>

          <ForumActions handleNavigate={() => console.log('.')} numComments={numComments} />
        </Box>
      </Stack>
    </Paper>
  )
}
