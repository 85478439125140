import React from 'react'
import { styled, useTheme } from '@mui/styles'
import { Box, Stack, Typography, IconButton, Skeleton, useMediaQuery } from '@mui/material'
import { MButton } from 'components/_material-extend'

import Banner from 'assets/images/forums/Banner.png'
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'

import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg'
import { fDateOriginal } from 'utils/formatTime'
import { useI18n } from 'hooks'

const Root = styled(Box)(() => ({
  position: 'relative',
  // border: '1px solid #E5E5E5',
  borderRadius: 5,
  overflow: 'hidden',
  background: '#fff',
  boxShadow:
    '0px 2px 3px -1px rgb(144 144 150 / 20%), 0px 5px 6px 0px rgb(144 144 150 / 14%), 0px 1px 10px 0px rgb(144 144 150 / 12%)',
}))

const BannerStyle = styled(Box)(({ bg }) => ({
  background: `url(${bg}) center no-repeat`,
  backgroundSize: 'cover',
  height: '20vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

const Container = styled(Stack)(() => ({}))

const ItemField = ({ icon, children }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1.25}>
      <IconButton
        sx={{
          padding: 0,
          '& svg': {
            width: 20,
            fill: '#fff',
            '& path': {
              fill: '#fff',
            },
          },
        }}
      >
        {icon}
      </IconButton>
      <Typography variant="body2" sx={{ color: '#fff' }}>
        {children}
      </Typography>
    </Stack>
  )
}
const SkeletonCustom = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" width="100%" height={250} />
    </Box>
  )
}
export default function GroupInfo(props) {
  const { group, numPosts, user, handleClick } = props
  const { t, getLanguage } = useI18n({ keyPrefix: 'forum' })

  const join = group?._id
    ? group?.members?.length
      ? group?.members?.filter(member => member === user?.id).length
      : false
    : false

  const MButtonStyle = styled(MButton)(({ theme, active }) => ({
    padding: theme.spacing(1, 5),
    color: !active ? theme.palette.primary.main : '#fff',
    border: !active && '1px solid #909096',
    background: active ? theme.palette.primary.main : '#fff',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#fff',
      background: theme.palette.primary.main,
      borderColor: !active && '#fff',
    },
  }))

  return group?._id ? (
    <Root>
      <BannerStyle bg={Banner} sx={{ px: 6 }}>
        <Stack spacing={1}>
          <Typography variant="h4" sx={{ color: '#fff' }}>
            {group?.title}
          </Typography>

          <Stack direction="row" alignItems="center" spacing={6.5} sx={{ flexGrow: 1 }}>
            <ItemField icon={<PersonSharpIcon />}>
              {/* {group?.numMembers} {t('members')} */}
              {group?.numMembers} {t('members')}
            </ItemField>
            <ItemField icon={<ChatIcon />}>
              {/* {numPosts} {t('posts')} */}
              {numPosts} {t('posts')}
            </ItemField>
            {group?.createdAt && (
              <ItemField icon={<CalendarTodaySharpIcon />}>{fDateOriginal(group?.createdAt)}</ItemField>
            )}
          </Stack>
        </Stack>
      </BannerStyle>
      <Container direction="row" alignItems="center" justifyContent="center" spacing={3} sx={{ py: 2, px: 6 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {/* <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin amet sagittis sem quis dui. Vulputate orci,
            tortor consequat venenatis amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin amet
            sagittis sem quis dui.
          </Typography> */}
          <MButtonStyle
            variant="outlined"
            sx={{ px: 2.5, py: 1 }}
            active={!join && true}
            onClick={() => handleClick({ id: group?._id, value: join, isGroupDetail: true })}
          >
            {!join ? t('join') : t('joined')}
          </MButtonStyle>
        </Stack>
      </Container>
    </Root>
  ) : (
    <SkeletonCustom />
  )
}
