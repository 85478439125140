import React from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import 'assets/css/ImageLightBox.css'

export default function ImageLightBox(props) {
  const { ind, open, listImages } = props

  const [photoIndex, setPhotoIndex] = React.useState(0)
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    if (!open) return
    setIsOpen(open)
    setPhotoIndex(ind)
  }, [open])

  return (
    isOpen && (
      <Lightbox
        mainSrc={listImages[photoIndex]}
        nextSrc={listImages[(photoIndex + 1) % listImages.length]}
        prevSrc={listImages[(photoIndex + listImages.length - 1) % listImages.length]}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() => setPhotoIndex((photoIndex + listImages.length - 1) % listImages.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % listImages.length)}
        imagePadding={100}
      />
    )
  )
}
