export function formatImg(images) {
  const imgsArr = []
  for (let i = 0; i < images.length; i++) {
    imgsArr.push({
      resizeMode: 'cover',
      source: images[i],
    })
  }

  return imgsArr
}

export function handleLayout(images) {
  return images.length < 4 && images.length > 0 ? [3] : images.length >= 4 ? [2, 2] : []
}

export function formatSize(images, isMobile) {
  return images.length === 1 ? ['300px', '300px'] : isMobile && images.length > 1 ? ['270px', '270px'] : []
}
