import React from 'react'
import { useGroup, useI18n, usePost } from 'hooks'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { TitleField, Root } from './styles'

import { ForumPosts, ForumTopics } from 'components/_expand-page/Forum'
import { ContainerRight } from 'components/_expand-page/Forum/styles'
import { ContainerGroups } from 'components/_expand-page/Group'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
}))

const StackStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3.75),
}))

const postsFake = [
  {
    _id: '',
    content:
      'Due to the fragmentation of firms’ capital structures, many corporate debtors now have at least two groups of lenders, and sometimes more. Neiman Marcus, for example, had five different substantial groups of secured lenders...',
    title: 'Pandemic Hope for Chapter 11 Financing',
    createdAt: new Date(),
    createdBy: '',
    createByName: 'u/emma2021',
    numComments: 12,
    numLikes: 53,
  },
  {
    _id: '',
    content:
      'Business bankruptcy is a public-private partnership. This characterization comes from how the system is funded, who oversees it, and who sets the substantive rules.15 Meant to facilitate the reorganization and preservation of for-profit and nonprofit enterprises, Chapter 11 bankruptcy is funded, for better or worse, from a mixture of public and private sources.16 Liabilities come from diverse legal doctrines, including contract, tort, statutory, regulatory, and sometimes even constitutional law...',
    title: 'Shocking Business Bankruptcy Law',
    createdAt: new Date(),
    createdBy: '',
    createByName: 'u/jacky2021',
    numComments: 32,
    numLikes: 42,
  },
  {
    _id: '',
    content:
      'The number of large companies entering Chapter 11 in the first half of 2021 was quite low. By one metric, only five large publicly held companies filed for bankruptcy during that period.5 This quiescent period will not last...',
    title: 'COVID-19 Debt and Bankruptcy Infrastructure',
    createdAt: new Date(),
    createdBy: '',
    createByName: 'u/ap2021',
    numComments: 12,
    numLikes: 30,
  },
]

export default function LandingForums() {
  const { handleGetPosts, postsTopic, handleGetTopics } = usePost()
  const { groups, handleGetGroups } = useGroup()
  const { t } = useI18n({ keyPrefix: 'home.forum' })

  React.useEffect(() => {
    handleGetPosts()
    handleGetTopics()
    handleGetGroups()
  }, [])

  return (
    <Root>
      <TitleField title={t('title')}>{t('title_des')}</TitleField>
      <Container>
        <StackStyle sx={{ flexGrow: 1 }}>
          <ForumPosts posts={postsFake} />
        </StackStyle>
        {postsTopic.length ? (
          <StackStyle sx={ContainerRight}>
            <ContainerGroups groups={groups} isDisabled />
            <ForumTopics posts={postsTopic} />
          </StackStyle>
        ) : (
          ''
        )}
      </Container>
    </Root>
  )
}
