const { createSlice } = require('@reduxjs/toolkit')

const contractSlice = createSlice({
  name: 'contract',
  initialState: {
    name: 'Boiler Contract',
    selectedStates: [0, 1, 2],
    inputStates: {},
    optionStates: {},
    radioStates: {},
    agreedTerms: {},
    parties: {
      Date: new Date(),
      Party1: { 'Company Name': '', Number: '', Address: '' },
      Party2: { 'Company Name': '', Number: '', Address: '' },
      IndividualName: '',
      IndividualAddress: '',
      Assumptions: '',
      Text: '',
    },
    agreedContents: {},
  },
  reducers: {
    SET_NAME: (state, action) => {
      state.name = action.payload
    },
    SET_SELECTED_STATES: (state, action) => {
      state.selectedStates = action.payload
    },
    SET_INPUT_STATES: (state, action) => {
      state.inputStates = action.payload
    },
    SET_OPTION_STATES: (state, action) => {
      state.optionStates = action.payload
    },
    SET_RADIO_STATES: (state, action) => {
      state.radioStates = action.payload
    },
    SET_NEW_CONTRACT: (state, action) => {
      state.agreedTerms = action.payload
    },
    SET_NEW_PARTIES: (state, action) => {
      state.parties = action.payload
    },
    SET_AGREED_CONTENT: (state, action) => {
      state.agreedContents = action.payload
    },
  },
})

const { reducer, actions } = contractSlice

export const {
  SET_NAME,
  SET_SELECTED_STATES,
  SET_INPUT_STATES,
  SET_OPTION_STATES,
  SET_RADIO_STATES,
  SET_NEW_CONTRACT,
  SET_NEW_PARTIES,
  SET_AGREED_CONTENT,
} = actions

export default reducer
