import React from 'react'
import { PATH_AUTH } from 'routes/paths'

import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'

import { styled } from '@mui/styles'
import { Box, Typography, Grid, FormHelperText } from '@mui/material'
import { FormControl, InputField, ButtonField } from 'components/authentication/styles'
import { useI18n } from 'hooks'

const RegisterSchema = Yup.object().shape({
  passwordOld: Yup.string().required('Old password  is required'),
  password: Yup.string().required('Password is required'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const initialize = {
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
}
const Container = styled(Box)(() => ({}))

export default function ProfileFormSetting() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t, getLanguage } = useI18n({ keyPrefix: 'profile' })

  const formik = useFormik({
    initialValues: initialize,
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      // try {
      //   const response = await register(values)
      //   if (response.status !== 201) {
      //     formik.resetForm({ values: { ...values, password: '', passwordConfirmation: '' } })
      //     return enqueueSnackbar(response.data.error, { variant: 'error' })
      //   }
      //   enqueueSnackbar(response.data.message, { variant: 'success' })
      //   navigate(PATH_AUTH.login)
      // } catch (err) {
      //   console.log('err', err)
      // }
    },
  })
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange } = formik

  return (
    <Box sx={{ maxWidth: 648, margin: '0 auto' }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Container>
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <FormControl
                  label={t('input.oldPWD')}
                  children2={<Typography sx={{ color: '#5A5A5A' }}>{t('changeYourPassword')}</Typography>}
                >
                  <InputField
                    placeholder={t('input.oldPWD')}
                    {...getFieldProps('oldPassword')}
                    type="password"
                    helperText={touched.oldPassword && errors.oldPassword}
                    error={touched.oldPassword && errors.oldPassword}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl label={t('input.newPWD')}>
                  <InputField
                    placeholder={t('input.newPWD')}
                    {...getFieldProps('password')}
                    type="password"
                    helperText={touched.password && errors.password}
                    error={touched.password && errors.password}
                  />
                </FormControl>
              </Grid>{' '}
              <Grid item xs={12}>
                <FormControl label={t('input.confirmPWD')}>
                  <InputField
                    placeholder={t('input.confirmPWD')}
                    {...getFieldProps('passwordConfirmation')}
                    type="password"
                    helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                    error={touched.passwordConfirmation && errors.passwordConfirmation}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Container>
          <Box sx={{ mt: 3.3125 }}>
            <ButtonField variant="contained" type="submit">
              {t('input.change')}
            </ButtonField>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  )
}
