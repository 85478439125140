import React from 'react'
import { styled } from '@mui/styles'
import {
  Box,
  Typography,
  TextField,
  Stack,
  Select,
  MenuItem,
  FormControl as FormControlMui,
  FormHelperText,
} from '@mui/material'
import { MButton } from 'components/_material-extend'

const UnderLine = styled(Box)(({ theme }) => ({
  width: 85,
  height: 6,
  background: theme.palette.primary.main,
}))

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(0, 20),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',

  [theme.breakpoints.down(1300)]: {
    padding: theme.spacing(7, 10),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(7, 3),
  },
}))

const TitleField = ({ children }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h3">{children}</Typography>
      <UnderLine />
    </Box>
  )
}

const InputField = styled(TextField)(({ theme, weight }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.875, 1.25),
    fontSize: 14,
    background: 'rgba(238, 238, 238, 0.7);',
    color: '#5F5F5F',
    borderRadius: theme.spacing(1),
    fontWeight: weight ? `${weight}` : 400,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}))

const FormControlSelectStyle = styled(FormControlMui)(({ theme, weight }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.875, 1.25),
    fontSize: 14,
    background: 'rgba(238, 238, 238, 0.7);',
    color: '#5F5F5F',
    borderRadius: theme.spacing(1),
    fontWeight: weight ? `${weight}` : 400,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}))

const SelectField = props => {
  const { lists, error = false, helperText = '', disabled = false } = props
  return (
    <FormControlSelectStyle error={error} disabled={disabled}>
      <Select {...props}>
        {lists.length ? lists.map(list => <MenuItem value={list.value}>{list.label}</MenuItem>) : 'none'}
      </Select>
      {error && helperText && <FormHelperText sx={{ color: 'primary.main' }}>{helperText}</FormHelperText>}
    </FormControlSelectStyle>
  )
}

const FormControl = ({ label, children2, children, nomb, mb }) => {
  return (
    <Box sx={{ mb: mb ? mb : !nomb && 1 }}>
      {!children2 ? (
        <Typography sx={{ mb: 1 }} variant="subtitle2Res">
          {label}
        </Typography>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography sx={{ mb: 1 }} variant="subtitle2Res">
            {label}
          </Typography>
          {children2}
        </Stack>
      )}

      {children}
    </Box>
  )
}

const ButtonField = styled(MButton)(({ theme }) => ({
  padding: theme.spacing(1, 4),
}))

const OptionLink = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 25,
  right: 50,
}))

export { TitleField, Root, FormControl, InputField, ButtonField, OptionLink, SelectField }
