import { withStyles } from '@mui/styles'

// ----------------------------------------------------------------------

const GlobalStyles = withStyles(theme => ({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    html: {
      width: '100%',
      height: '100%',
      scrollBehaviour: 'smooth',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch',
    },
    body: {
      width: '100%',
      height: '100%',
      outline: 'none',
      color: '#000',
    },
    '#root': {
      width: '100%',
      height: '100%',
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' },
      },
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled },
    },
    a: {
      color: '#000',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    img: { maxWidth: '100%', width: '100%', height: '100%' },

    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(5px)',
      filter: 'blur(5px)',
      transition: 'filter 400ms, -webkit-filter 400ms',
    },
    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)',
    },
    '.container': {
      position: 'relative',
      maxWidth: 1920,
      padding: theme.spacing(0, 5),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 5),
        minHeight: '60vh',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
      },
    },
  },
}))(() => null)

export default GlobalStyles
