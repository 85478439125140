import React from 'react'
import { styled, useTheme } from '@mui/styles'
import { Box, useMediaQuery } from '@mui/material'

import { ProfileNavLink } from 'components/_expand-page/Profile'
import { useI18n } from 'hooks'

const Container = styled(Box)(() => ({}))

export default function ProfileEdit({ children }) {
  const { getLanguage } = useI18n({ keyPrefix: 'profile' })

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container sx={{ mt: 13.9375, mb: 7 }}>
      <Box sx={{ mb: !matches ? 6.5 : 3 }}>
        <ProfileNavLink getLanguage={getLanguage} />
      </Box>

      <Box>{children}</Box>
    </Container>
  )
}
