import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GET_GROUP_BY_ID, GET_GROUPS, HANDLE_JOIN_GROUP, SET_ALERT_JOINED_GROUP } from 'store/reducers/GroupSlice'

export default function useGroup() {
  const dispatch = useDispatch()

  // redux
  const groups = useSelector(state => state.group.groups)
  const group = useSelector(state => state.group.groupDetail)
  const alertJoinedGroup = useSelector(state => state.group.alertJoinedGroup)

  const handleGetGroupById = id => dispatch(GET_GROUP_BY_ID(id, `page=1`))
  const handleGetGroups = () => dispatch(GET_GROUPS())
  const handleOpenAlertJoinedGroup = (open, id) => dispatch(SET_ALERT_JOINED_GROUP(open, id))

  const handleJoin = async ({ id, value, acceptJoined, isGroupDetail = false }) => {
    // value = true: joined
    if (!value) return dispatch(HANDLE_JOIN_GROUP(id, false))

    // alert if out group
    if (!acceptJoined) return dispatch(SET_ALERT_JOINED_GROUP({ open: true, id }))

    dispatch(HANDLE_JOIN_GROUP(id, value, isGroupDetail))
  }

  return {
    groups,
    group,
    handleGetGroups,
    handleJoin,
    handleGetGroupById,
    handleOpenAlertJoinedGroup,
    alertJoinedGroup,
  }
}
