import { configureStore } from '@reduxjs/toolkit'
import AuthReducer from './reducers/AuthSlice'
import PostReducer from './reducers/PostSlice'
import GroupReducer from './reducers/GroupSlice'
import CommentReducer from './reducers/CommentSlice'
import DocReducer from './reducers/DocSlice'
import NewsReducer from './reducers/NewsSlice'
import ContractReducer from './reducers/ContractSlice'

const rootReducer = {
  auth: AuthReducer,
  post: PostReducer,
  group: GroupReducer,
  comment: CommentReducer,
  doc: DocReducer,
  news: NewsReducer,
  contract: ContractReducer,
}

export default configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
})
