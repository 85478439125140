import React from 'react'
import { styled, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Divider, Typography, IconButton, Stack, Grid } from '@mui/material'

import footerJSON from 'app/data/footer'
import navigateMenu from 'routes/navigateMain'

import footer from 'assets/images/landing/footer.png'
import { ReactComponent as FbIcon } from 'assets/icons/fb.svg'
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg'
import { ReactComponent as TweeterIcon } from 'assets/icons/tweeter.svg'
import { useI18n } from 'hooks'
import { PATH_PAGE } from 'routes/paths'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'

import logo from 'assets/images/logo.png'
import mapsJSON from 'assets/data/maps'

const socials = [
  {
    icon: <FbIcon />,
    label: 'FbIcon',
    path: '/',
  },
  {
    icon: <TweeterIcon />,
    label: 'TweeterIcon',
    path: '/',
  },
  {
    icon: <LinkedinIcon />,
    label: 'LinkedinIcon',
    path: '/',
  },
]

const RootStyle = styled(Box)(() => ({
  background: `url(${footer}) center no-repeat`,
  backgroundSize: 'cover',
  width: '100%',
}))

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2, 0),
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(6, 5, 5),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(5, 1),
  },
}))

const RouterLinkStyle = styled(RouterLink)(() => ({
  color: '#fff',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

const NavLinkField = ({ children, to }) => {
  return (
    <Typography component={RouterLinkStyle} variant="body2Res" color="primary" to={to}>
      {children}
    </Typography>
  )
}

const InfoItem = ({ icon, children }) => {
  return (
    <Stack direction="row-reverse" alignItems="flex-start" spacing={1}>
      <IconButton sx={{ '& svg': { fill: '#fff', width: 14, height: 14 } }}>{icon}</IconButton>
      <Typography variant="body2Res" color="white" sx={{ textAlign: 'right' }}>
        {children}
      </Typography>
    </Stack>
  )
}
const InfoAddress = ({ getLanguage, map }) => {
  return (
    <Stack className="Box-InfoAddress" sx={{ width: '50%' }}>
      <InfoItem icon={<LocationOnIcon />}>{getLanguage === 'en' ? map.address : map.addressVn}</InfoItem>
      <InfoItem icon={<PhoneIcon />}>{map.tell}</InfoItem>
      <InfoItem icon={<ImportContactsIcon />}>{map.fax}</InfoItem>
    </Stack>
  )
}
export default function Header() {
  const { t, getLanguage } = useI18n()
  const [option, setOption] = React.useState('HN')

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const matchesMobile = useMediaQuery(theme.breakpoints.down(800))
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesXs = useMediaQuery(theme.breakpoints.down(425))
  const matchesXxs = useMediaQuery(theme.breakpoints.down(320))

  const handleChangeLocate = locate => setOption(locate)

  return (
    <RootStyle>
      {!matches ? (
        <Container>
          <Stack>
            <Stack>
              <Typography
                component={RouterLink}
                to={PATH_PAGE.root}
                variant="h6"
                color="white"
                sx={{ fontWeight: 500 }}
              >
                Vietnamlawinsight
              </Typography>
              {/* <Stack spacing={1} sx={{ mt: 2 }}>
                {navigateMenu.map((link, ind) => {
                  if (link.hidden) return
                  return (
                    <NavLinkField key={link.title} to={link.path}>
                      {getLanguage === 'en' ? link.title : link.titleVn}
                    </NavLinkField>
                  )
                })} */}
              {/* </Stack> */}
            </Stack>

            {/* <Stack sx={{ flex: 1 }} spacing={2}>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                {mapsJSON.map(map => (
                  <Typography
                    active={map.locate === option}
                    key={map.name}
                    color="white"
                    onClick={() => handleChangeLocate(map.locate)}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: map.locate === option ? 'underline' : '',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    {getLanguage === 'en' ? map.name : map.nameVn}
                  </Typography>
                ))}
              </Stack>
              <Stack alignItems="flex-end">
                {mapsJSON.map(map => {
                  if (map.locate !== option) return
                  return <InfoAddress key={map.name} map={map} getLanguage={getLanguage} />
                })}
              </Stack>
            </Stack> */}
            {/* <Grid container sx={{ justifyContent: 'end' }}>
              {footerJSON.map((footer, ind) => (
                <Grid
                  key={footer.title}
                  item
                  xs={!matchesXxs ? (!matchesMobile ? (ind !== 0 ? 6 : matches && 4) : 6) : 12}
                >
                  <Typography variant="body2Res" component={RouterLinkStyle} to="/">
                    {getLanguage === 'en' ? footer.title : footer.titleVn}
                  </Typography>
                  <Grid container sx={{ mt: !matchesMobile ? 5 : 2 }} columnSpacing={1}>
                    {footer.children.map(child => (
                      <Grid item xs={ind === 0 ? 12 : !matches ? 6 : 12} key={child.title}>
                        <Typography variant="body2Res" component={RouterLinkStyle} to={child.path}>
                          {getLanguage === 'en' ? child.title : child.titleVn}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid> */}
          </Stack>
        </Container>
      ) : null}

      {/* <Divider sx={{ my: 1 }} color="white" /> */}

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, pb: 1 }}>
        <Typography variant="subtitle2" sx={{ color: '#fff', fontWeight: 400 }}>
          © LNT & Partners - {t('footer.allRightsReserved')}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {socials.map(social => (
            <IconButton key={social.label} sx={{ '& svg': { width: 16, height: 16 } }}>
              {social.icon}
            </IconButton>
          ))}
        </Stack>
      </Stack>
    </RootStyle>
  )
}
