import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'
import { styled } from '@mui/styles'
import { Box, Stack, Typography } from '@mui/material'
import { TitleMain } from './styles'

import { MButton } from 'components/_material-extend'
import { ForumRelatedTopicItem } from './index'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useI18n } from 'hooks'

const Root = styled(Box)(({ theme }) => ({
  border: '1px solid #E5E5E5',
  borderRadius: 5,
}))
const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2.625, 3.875, 4.125),
}))

export default function ForumRelatedTopics({ handleSharePost, posts }) {
  const navigate = useNavigate()
  const { t } = useI18n({ keyPrefix: 'forum' })

  const handleNavigate = id => {
    navigate(`${PATH_PAGE.forums}/${id}`)
  }

  return (
    <Root data-aos="fade-up">
      <TitleMain>
        <Typography variant="subtitle2Res" sx={{ color: '#fff' }}>
          {t('title.relatedTopic')}
        </Typography>
      </TitleMain>
      <Container spacing={3}>
        <Stack spacing={3}>
          {posts?.map(post => (
            <ForumRelatedTopicItem
              key={post._id}
              post={post}
              handleNavigate={handleNavigate}
              handleSharePost={handleSharePost}
            />
          ))}
        </Stack>
      </Container>
    </Root>
  )
}
