import React from 'react'
import { useAuth, usePost } from 'hooks'
import { styled } from '@mui/styles'
import { Box, Typography, Stack, Skeleton } from '@mui/material'
import { ForumPostItem } from './index'
import { useNavigate, useLocation } from 'react-router-dom'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.25),
}))

const SkeletonCustom = () => {
  return (
    <Stack spacing={2} direction="row" sx={{ height: 300, width: '700px' }}>
      <Stack sx={{ width: 25 }}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Stack>
      <Stack sx={{ flexGrow: 1 }} spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Skeleton variant="circular" width={36} height={36} />
          <Stack sx={{ flexGrow: 1 }} direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Stack>
              <Skeleton variant="text" width={300} height={15} />
              <Skeleton variant="text" width={100} height={15} />
            </Stack>

            <Skeleton variant="rectangular" width={30} height={15} />
          </Stack>
        </Stack>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Stack>
    </Stack>
  )
}

const Loading = ({ isNoPosts }) => {
  return !isNoPosts ? (
    [1, 2, 3, 4].map(item => <SkeletonCustom key={item} />)
  ) : (
    <Typography sx={{ textAlign: 'center' }} variant="subTitle2Res">
      No Posts
    </Typography>
  )
}

export default function ForumPosts(props) {
  const { posts, nameState } = props
  const { handleActionPost, handleUpDownVote, handleSharePost } = usePost()
  const { user, isAuthenticated } = useAuth()
  const [isNoPosts, setIsNoPosts] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation().pathname

  const handleNotLogin = () => {
    if (window.confirm('You need to log in do this action. Login now?')) {
      localStorage.setItem('prevPath', location)
      navigate('/auth/login')
    }
  }

  const handleNotLogin2 = () => {
    if (window.confirm('You need to log in to view post. Login now?')) {
      navigate('/auth/login')
    }
  }

  React.useEffect(() => {
    if (posts.length > 0) return setIsNoPosts(false)
    setTimeout(() => setIsNoPosts(true), 5000)
  }, [posts])

  return (
    <Container>
      {posts?.length ? (
        posts.map(post => (
          <ForumPostItem
            outside
            key={post._id || post.content}
            post={post}
            user={user}
            handleSharePost={handleSharePost}
            handleAction={isAuthenticated ? handleActionPost : handleNotLogin2}
            nameState={nameState}
            handleUpDownVote={isAuthenticated ? handleUpDownVote : handleNotLogin}
          />
        ))
      ) : (
        <Loading isNoPosts={isNoPosts} />
      )}
    </Container>
  )
}
