import React from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { Stack } from '@mui/material'
import Routes from 'routes'
import ThemeConfig from 'theme'
import NotistackProvider from 'components/Notistack'

// animation
// import 'aos/dist/aos.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function App() {
  return (
    <div style={{ flexDirection: 'column', alignItems: 'center' }}>
      <ThemeConfig>
        <NotistackProvider>
          {/* <Stack sx={{ maxWidth: 1500 }} direction="row" justifyContent="end" alignItems="center"> */}
          <Router>
            <Routes />
          </Router>
          {/* </Stack> */}
        </NotistackProvider>
      </ThemeConfig>
    </div>
  )
}

export default App
