import axios from 'utils/axios'
import { GET_API, POST_API, UPDATE_API, DELETE_API } from 'routes/api'

const { createSlice } = require('@reduxjs/toolkit')

const commentSlice = createSlice({
  name: 'comment',
  initialState: {
    comments: [],
    totalPage: 0,
  },
  reducers: {
    SET_COMMENTS: (state, action) => {
      state.comments = action.payload
    },
    SET_TOTAL_PAGE: (state, action) => {
      state.totalPage = action.payload
    },
  },
})

const { reducer, actions } = commentSlice

export const { SET_COMMENTS, SET_TOTAL_PAGE } = actions

export default reducer

export function GET_COMMENTS_BY_ID(postID, page = 1) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`${GET_API(postID).commentsByPost}?page=${page}`)
      if (page !== 1) {
        const currentState = getState().comment
        const newComments = [...currentState.comments, ...response.data.comments]
        return dispatch(SET_COMMENTS(newComments))
      }
      dispatch(SET_COMMENTS(response.data.comments))
      dispatch(SET_TOTAL_PAGE(response.data.totalPage))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function GET_REPLIES_BY_ID(commentID) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(GET_API(commentID).replies)
      const currentState = getState().comment

      const newComments = currentState.comments.map(comment => {
        if (comment?._id !== commentID) return comment
        return { ...comment, isReply: true, isShowReply: true, reply: response.data.replies }
      })

      dispatch(SET_COMMENTS(newComments))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function HANDLE_SUBMIT_COMMENT(form, commentID = null) {
  return async () => {
    try {
      const { files, content, postID } = form
      const formData = new FormData()

      if (files && files.length) {
        await files.map(file => formData.append('file', file))
      }
      await formData.append('content', content)

      if (commentID) {
        await axios.post(POST_API(commentID).replies, formData)
      } else {
        await axios.post(POST_API(postID).commentByPost, formData)
      }
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function ADD_COMMENT(comment) {
  return async (dispatch, getState) => {
    try {
      const currentState = getState().comment
      const newComments = [comment, ...currentState.comments]
      dispatch(SET_COMMENTS(newComments))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function EDIT_COMMENT(commentForm) {
  return async () => {
    try {
      await axios.patch(UPDATE_API(commentForm?.PostId, commentForm?.id).comments, {
        content: commentForm.content,
      })
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function DELETE_COMMENT(commentForm) {
  return async () => {
    try {
      await axios.delete(DELETE_API(commentForm?.PostId, commentForm?.id).comments)
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function NUMLIKE_COMMENT(comment, numLikes) {
  return async () => {
    try {
      await axios.patch(UPDATE_API(comment?.PostId, comment?._id).commentsNumLike, { numLikes })
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function UPDATE_COMMENT_SOCKET(comment) {
  return async (dispatch, getState) => {
    try {
      const currentState = getState().comment
      const newComments = currentState.comments.map(c => {
        if (c?._id !== comment?._id) return c
        return { ...c, ...comment }
      })
      dispatch(SET_COMMENTS(newComments))
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function DELETE_COMMENT_SOCKET(comment) {
  return async (dispatch, getState) => {
    try {
      const currentState = getState().comment
      const newComments = currentState.comments.filter(c => c?._id !== comment?._id)
      dispatch(SET_COMMENTS(newComments))
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function UPDATE_REPLY_SOCKET(reply) {
  return async (dispatch, getState) => {
    try {
      const currentState = getState().comment
      const newComments = currentState.comments.map(c => {
        if (c?._id !== reply?.parentCmtId) return c
        const newReply = c.reply.map(r => {
          if (r?._id !== reply?._id) return r
          return { ...r, ...reply }
        })
        return { ...c, reply: newReply }
      })
      console.log('UPDATE_REPLY_SOCKET', reply, newComments)
      dispatch(SET_COMMENTS(newComments))
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function DELETE_REPLY_SOCKET(reply) {
  return async (dispatch, getState) => {
    try {
      const currentState = getState().comment
      const newComments = currentState.comments.map(c => {
        if (c?._id !== reply?.parentCmtId) return c
        const newReply = c.reply.filter(r => r?._id !== reply?._id)
        return { ...c, reply: newReply }
      })
      console.log('UPDATE_REPLY_SOCKET', reply, newComments)
      dispatch(SET_COMMENTS(newComments))
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function ADD_REPLY(reply) {
  return async (dispatch, getState) => {
    try {
      const currentState = getState().comment
      const newComments = currentState?.comments.map(comment => {
        if (comment?._id !== reply?.parentCmtId) return comment
        return { ...comment, isShowReply: true, reply: [...comment.reply, reply] }
      })
      console.log('ADD_REPLY', newComments)
      dispatch(SET_COMMENTS(newComments))
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function EDIT_REPLY(commentForm) {
  return async dispatch => {
    try {
      const response = await axios.patch(UPDATE_API(commentForm.id).replies, { content: commentForm.content })
      console.log('EDIT_REPLY', response)
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function NUMLIKE_REPLY(reply, numLikes) {
  return async dispatch => {
    try {
      const response = await axios.patch(UPDATE_API(reply?._id).repliesNumLike, { numLikes })
      console.log('NUMLIKE_REPLY', response)
      // console.log('GET_COMMENTS_BY_ID', response)
      // dispatch(commentSlice.actions.SET_COMMENTS(response.data.comments))
    } catch (error) {
      console.log('err', error)
    }
  }
}
export function DELETE_REPLY(comment) {
  return async dispatch => {
    try {
      const response = await axios.delete(DELETE_API(comment.id).replies)
      console.log('DELETE_REPLY', response)
    } catch (error) {
      console.log('err', error)
    }
  }
}
