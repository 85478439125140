import React from 'react'
import io from 'socket.io-client'

const ENDPOINT = 'https://d392hd1u5yd463.cloudfront.net'
let socket

export default function useSocket() {
  const initiateSocket = (postID = null) => {
    socket = io(ENDPOINT)
    console.log(`Connecting socket...`)
    if (socket && postID) socket.emit('join', postID)
  }

  const disconnectSocket = () => {
    console.log('Disconnecting socket...')
    if (socket) socket.disconnect()
  }

  const joinNotification = (userID = null) => {
    socket = io(ENDPOINT)
    console.log(`Connecting noti socket...`, userID)

    if (socket && userID) socket.emit('join', userID)
  }

  const subscribeToNotifications = cb => {
    if (!socket) return true
    socket.on('sendNotification', msg => {
      return cb(null, msg)
    })
  }
  // comments
  const subscribeToComments = cb => {
    if (!socket) return true
    socket.on('Addcomment', msg => {
      return cb(null, msg)
    })
  }
  const subscribeToCommentUpdated = cb => {
    if (!socket) return true
    socket.on('commentUpdated', msg => {
      return cb(null, msg)
    })
  }
  const subscribeToCommentUpdatedNumLike = cb => {
    if (!socket) return true
    socket.on('commentUpdatedNumLike', msg => {
      return cb(null, msg)
    })
  }
  const subscribeToDeleteComment = cb => {
    if (!socket) return true
    socket.on('Deletecomment', msg => {
      return cb(null, msg)
    })
  }
  // reply
  const subscribeToReply = cb => {
    if (!socket) return true
    socket.on('Addreply', msg => {
      return cb(null, msg)
    })
  }
  const subscribeToReplyUpdated = cb => {
    if (!socket) return true
    socket.on('replyUpdated', msg => {
      return cb(null, msg)
    })
  }
  const subscribeToReplyUpdatedNumLike = cb => {
    if (!socket) return true
    socket.on('replyUpdatedNumLike', msg => {
      return cb(null, msg)
    })
  }
  const subscribeToDeleteReply = cb => {
    if (!socket) return true
    socket.on('Deletereply', msg => {
      return cb(null, msg)
    })
  }
  return {
    socket,
    initiateSocket,
    disconnectSocket,
    // noti
    joinNotification,
    subscribeToNotifications,
    // comment
    subscribeToComments,
    subscribeToCommentUpdated,
    subscribeToCommentUpdatedNumLike,
    subscribeToDeleteComment,
    // reply
    subscribeToReply,
    subscribeToReplyUpdated,
    subscribeToReplyUpdatedNumLike,
    subscribeToDeleteReply,
  }
}
