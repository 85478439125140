import React from 'react'
import { styled } from '@mui/styles'
import {
  IconButton,
  Typography,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ListItemIcon,
  Divider,
  Stack,
  TextField,
  InputAdornment,
  Button,
  Box,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'
import { fDateOriginal } from 'utils/formatTime'
import lineClampStyle from 'components/LineClamp'
import { useDocument, useI18n } from 'hooks'

import SearchIcon from '@mui/icons-material/Search'
import { ReactComponent as DocIcon } from 'assets/icons/docs.svg'
import DownloadIcon from '@mui/icons-material/Download'
import VisibilityIcon from '@mui/icons-material/Visibility'

const Container = styled(Link)(({ theme, active }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'flex-start',
  padding: theme.spacing(2, 1.5),
  color: '#000',
  gap: theme.spacing(1.5),

  '& .docButton': {
    width: 36,
    height: 36,
    backgroundColor: '#EEE',
    '& svg': {
      width: 14,
      height: 14,
      fill: '#5A5A5A',
      '& path': {
        fill: '#5A5A5A',
      },
    },
  },

  '& .count': {
    '& .MuiButton-root': {
      color: '#5A5A5A',
      padding: theme.spacing(0, 0.5),
      minWidth: 0,
      '& .MuiButton-iconSizeMedium': {
        marginRight: 0,
      },
      '& svg': {
        fill: '#5A5A5A',
        '& path': {
          fill: '#5A5A5A',
        },
      },
    },
  },
  '& .body2': {
    color: '#5A5A5A',
  },
  '&:hover': {
    '& .title': {
      color: theme.palette.primary.main,
    },
    '& .docButton': {
      backgroundColor: theme.palette.primary.main,
      '& svg': {
        fill: '#EEE',

        '& path': {
          fill: '#EEE',
        },
      },
    },
  },
}))

const TypographyField = ({ children, sx, line = 1, ...props }) => {
  return (
    <Typography {...props} sx={{ overflowWrap: 'anywhere', textAlign: 'left', ...lineClampStyle(line), ...sx }}>
      {children}
    </Typography>
  )
}
const ItemField = props => {
  const { doc, handleClose } = props
  const { title, content, createdAt, downloads, views, _id } = doc
  const titleStyle = title.split('.')

  const createMarkup = html => {
    return { __html: html }
  }

  return (
    <Container to={`${PATH_PAGE.documents}/${_id}`} onClick={handleClose}>
      <IconButton className="docButton">
        <DocIcon />
      </IconButton>
      <Stack alignItems="start" sx={{ flexGrow: 1, width: '100%' }}>
        <Box>
          <TypographyField
            dangerouslySetInnerHTML={createMarkup(titleStyle[0])}
            variant="subtitle1"
            sx={{ fontWeight: 700, fontSize: '0.8rem', mb: 0.5 }}
            className="title"
          />
          <TypographyField
            dangerouslySetInnerHTML={createMarkup(content)}
            variant="caption"
            className="body2"
            line="2"
            sx={{ lineHeight: 1.2 }}
          />
          <Stack direction="row" alignItems="center" spacing={4} sx={{ mt: 0.5 }}>
            {createdAt && (
              <TypographyField variant="caption" className="body2" sx={{ fontSize: 12 }}>
                {fDateOriginal(createdAt)}
              </TypographyField>
            )}
            <Stack direction="row" alignItems="center" spacing={1} className="count">
              <Button startIcon={<DownloadIcon />} sx={{ fontSize: 12 }}>
                {downloads}
              </Button>
              <Button startIcon={<VisibilityIcon />} sx={{ fontSize: 12, gap: 0.5 }}>
                {views}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default function NotificationHeader() {
  const { getLanguage } = useI18n()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const { handleSearchDocTitle } = useDocument()

  const [search, setSearch] = React.useState('')
  const [docSearch, setDocSearch] = React.useState([])

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  React.useEffect(() => {
    setDocSearch([])
    setSearch('')
  }, [open])

  React.useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        const response = await handleSearchDocTitle(search, true)
        if (!response) {
          return setDocSearch([])
        }
        setDocSearch(response)
      } catch (error) {
        console.log('err', error)
      }
    }, 200)

    return () => clearTimeout(timer)
  }, [search])

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  // return focus to the button when we transitioned from !open -> open

  return (
    <Stack direction="row" spacing={2}>
      <IconButton
        sx={{ p: 0 }}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <SearchIcon />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="top-end" transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              sx={{
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                minWidth: 300,
                maxWidth: 300,
                maxHeight: '60vh',
                overflowY: 'auto',
                '& ul': { overflow: 'hidden' },
                '& li': { whiteSpace: 'normal' },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  <MenuItem>
                    <TextField
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      label="Search"
                      id="outlined-start-adornment"
                      sx={{
                        width: '100%',
                        '& .MuiInputLabel-root': {
                          top: -5,
                          fontSize: 14,
                        },
                        '& input': {
                          py: 1.5,
                          fontSize: 12,
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter something..."
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton sx={{ '& svg': { width: 18, height: 18 } }}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MenuItem>
                  <Divider sx={{ mb: 1, '& .MuiDivider-wrapper': { fontSize: 12 } }}>
                    {docSearch?.length ? docSearch?.length : 'No'} results
                  </Divider>
                  {docSearch?.length
                    ? docSearch.map(doc => (
                        <MenuItem key={doc._id}>
                          <ItemField doc={doc} handleClose={handleClose} />
                        </MenuItem>
                      ))
                    : ''}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  )
}
