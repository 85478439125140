import React from 'react'
import { makeStyles } from '@mui/styles'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import './quilljs.css'

const styles = makeStyles(() => ({
  root: {
    '& .ql-editor': {
      minHeight: '20vh',
      marginLeft: 0,
      fontSize: 14,
      border: '1px solid #EDEFF1',
      color: '#1c1c1c',
    },
  },
}))

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'align',
  'link',
]

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
  ],
}

const TextEditorV2 = props => {
  const classes = styles()

  return (
    <div className={classes.root}>
      <ReactQuill {...props} theme="bubble" />
    </div>
  )
}

export default TextEditorV2
