// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:960px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1280px)': {
      fontSize: pxToRem(lg),
    },
  }
}

const FONT_PRIMARY = ['Raleway'] // Google Font

// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY.join(','),

  // fontWeightRegular: 400,
  // fontWeightMedium: 600,
  // fontWeightBold: 700,
  title: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 42, md: 48, lg: 52 }),
  },
  40: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(28),
    ...responsiveFontSizes({ sm: 28, md: 36, lg: 40 }),
  },
  32: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(25.6),
    ...responsiveFontSizes({ sm: 25.6, md: 28.8, lg: 32 }),
  },
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 56, md: 64, lg: 72 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 32, md: 40, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 32, md: 32, lg: 36 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '28px',
    ...responsiveFontSizes({ sm: 22, md: 24, lg: 28 }),
  },
  h5: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: '14px',
    ...responsiveFontSizes({ sm: 14, md: 14, lg: 14 }),
  },
  h6: {
    fontWeight: 600,
    lineHeight: 30 / 18,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.2,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  subtitle2Res: {
    fontWeight: 600,
    lineHeight: 28 / 18,
    fontSize: pxToRem(12.8),
    ...responsiveFontSizes({ sm: 14, md: 14, lg: 16 }),
  },

  body1: {
    lineHeight: 1.5,
    fontWeight: 400,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body2Res: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(12.8),
    ...responsiveFontSizes({ sm: 14, md: 14, lg: 16 }),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
}

export default typography
