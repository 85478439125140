import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH_PAGE, PATH_AUTH } from 'routes/paths'
import { useSnackbar } from 'notistack'

import { useSelector, useDispatch } from 'react-redux'
import {
  LOGIN_SUCCESS,
  GET_NOTIFICATIONS,
  LOGIN_FAIL,
  LOGIN_LOADING,
  CLEAR_AUTHENTICATION,
  GET_USER_AUTH,
  UPLOAD_AVATAR,
  UPDATE_AVATAR_LOADING,
  SET_NOTIFICATION_SOCKET,
  UPDATE_SEEN_NOTIFICATION,
} from 'store/reducers/AuthSlice'

// api
import axiosInstance from 'utils/axios'
import { setSession } from 'utils/jwt'
import { GET_API, POST_API } from 'routes/api'

export default function useAuth() {
  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const notification = useSelector(state => state.auth.notification)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const isLoadingUpload = useSelector(state => state.auth.isLoadingUpload)
  const isLoading = useSelector(state => state.auth.isLoading)

  const [userProfile, setUserProfile] = useState({})

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleCloseLoading = () => {
    dispatch(LOGIN_LOADING(true))
  }

  const handleGetAuthentication = async () => {
    // get user
    await dispatch(LOGIN_LOADING(true))
    await dispatch(GET_USER_AUTH())
    await dispatch(GET_NOTIFICATIONS())
    await dispatch(LOGIN_LOADING(false))
  }

  const handleLogin = async form => {
    dispatch(LOGIN_LOADING(true))

    try {
      const { email, password } = form
      const response = await axiosInstance.post(POST_API().login, { email, password })
      dispatch(LOGIN_LOADING(false))

      if (response.data.status === 'error') {
        enqueueSnackbar(response.data.message, { variant: 'error' })
        return false
      }

      const { data, user } = response.data
      const accessToken = data

      setSession(accessToken)
      dispatch(LOGIN_SUCCESS(user))

      enqueueSnackbar('Login success', { variant: 'success' })
      if (localStorage.getItem('prevPath')) {
        if (localStorage.getItem('docsOpened')) {
          localStorage.removeItem('docsOpened')
        }
        navigate(localStorage.getItem('prevPath'))
        return localStorage.removeItem('prevPath')
      }
      if (localStorage.getItem('path')) {
        navigate(localStorage.getItem('path'))
        return localStorage.removeItem('path')
      }
      navigate(PATH_PAGE.root)
    } catch (error) {
      console.log('error', error)
      dispatch(CLEAR_AUTHENTICATION())
      return error
    }
  }

  const handleRegister = async values => {
    dispatch(LOGIN_LOADING(true))

    try {
      const { passwordConfirmation, ...form } = values
      const response = await axiosInstance.post(POST_API().register, form)
      dispatch(LOGIN_LOADING(false))
      console.log('response', response)

      if (response.data.message) {
        enqueueSnackbar(response.data.message, { variant: 'success' })
        navigate(PATH_AUTH.login)

        return response
      }
    } catch (err) {
      dispatch(LOGIN_LOADING(false))
      enqueueSnackbar(err.response.data.msg, { variant: 'error' })

      console.log('err', err.response)
      return false
    }
  }
  const handleLogout = () => {
    setSession(null)
    dispatch(CLEAR_AUTHENTICATION())
    navigate(PATH_PAGE.root)
  }
  const handleResetPassword = async values => {
    dispatch(LOGIN_LOADING(true))
    const { newpassword, email } = values
    try {
      const response = await axiosInstance.post(POST_API.resetPassword, {
        email,
        newpassword,
      })
      dispatch(LOGIN_LOADING(false))
      if (response.data.status === 'error') {
        return response
      }

      return response
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleUploadUser = (userID, imgFiles) => {
    dispatch(UPDATE_AVATAR_LOADING())
    dispatch(UPLOAD_AVATAR(userID, imgFiles))
  }

  const handleGetUserById = async userID => {
    try {
      const response = await axiosInstance.get(GET_API(userID).getUserID)

      if (response.data.status === 'success') {
        setUserProfile(response.data.user)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleAddNotification = notification => dispatch(SET_NOTIFICATION_SOCKET(notification))
  const handleSeenNotification = notiID => dispatch(UPDATE_SEEN_NOTIFICATION(notiID))
  const handleMoreNoti = page => dispatch(GET_NOTIFICATIONS(page))

  return {
    user,
    notification,
    userProfile,
    isAuthenticated,
    isLoading,
    isLoadingUpload,
    handleCloseLoading,
    handleGetAuthentication,
    handleLogin,
    handleRegister,
    handleLogout,
    handleResetPassword,
    handleUploadUser,
    handleGetUserById,
    handleAddNotification,
    handleSeenNotification,
    handleMoreNoti,
  }
}
