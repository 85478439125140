import React from 'react'
import { useAuth, useI18n, useSocket } from 'hooks'
import { useNavigate, Link } from 'react-router-dom'
import { PATH_AUTH } from 'routes/paths'
import { styled, useTheme } from '@mui/styles'
import { MButton } from 'components/_material-extend'
import { Stack, useMediaQuery, Typography, CircularProgress } from '@mui/material'

// components
import MenuProfile from 'components/header/MenuProfile'
import Notification from 'components/header/Notification'
import Search from 'components/header/Search'
import Translate from 'components/header/Translate'

const MButtonStyle = styled(MButton)(({ theme }) => ({
  padding: theme.spacing(0.625, 3.125),
  fontSize: 18,
  borderRadius: theme.spacing(0.625),
}))

export default function AuthHeader({ children }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const matchesMobile = useMediaQuery(theme.breakpoints.down(705))
  const {
    isAuthenticated,
    user,
    isLoading,
    notification,
    handleAddNotification,
    handleSeenNotification,
    handleMoreNoti,
  } = useAuth()
  const { t, getLanguage } = useI18n({ keyPrefix: 'header' })
  const { subscribeToNotifications, joinNotification } = useSocket()

  const [pageNotiDefault, setPageNotiDefault] = React.useState(1)

  React.useEffect(() => {
    if (!user?.id) return
    joinNotification(user?.id)
    subscribeToNotifications((err, data) => handleAddNotification(data))
  }, [user?.id])

  const handleGetMoreNoti = () => {
    console.log('handleGetMoreNoti')
    handleMoreNoti(pageNotiDefault + 1)
    setPageNotiDefault(pageNotiDefault + 1)
  }

  return (
    <Stack
      direction={!matchesMobile ? 'row' : 'row-reverse'}
      alignItems="center"
      spacing={!isAuthenticated ? 1 : !matches ? 3 : 2}
    >
      {children}
      <Translate />

      {!isLoading ? (
        !isAuthenticated ? (
          !matchesMobile ? (
            <>
              <MButtonStyle variant="contained" onClick={() => navigate(PATH_AUTH.login)}>
                <Typography variant="subtitle2">{t('login')}</Typography>
              </MButtonStyle>
              <Typography component={Link} to={PATH_AUTH.signup} variant="subtitle2" sx={{ py: 1.5, pl: 2 }}>
                {t('signUp')}
              </Typography>
            </>
          ) : (
            ''
          )
        ) : !matchesMobile ? (
          <>
            {/* <Search /> */}
            <Notification
              getLanguage={getLanguage}
              notification={notification}
              handleSeenNotification={handleSeenNotification}
              handleGetMoreNoti={handleGetMoreNoti}
            />
            <MenuProfile user={user} />
          </>
        ) : (
          ''
        )
      ) : (
        <CircularProgress color="primary" size={20} />
      )}
    </Stack>
  )
}
