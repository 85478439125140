import React, { useState } from 'react'
import { styled } from '@mui/styles'
import { Box, Typography, Grid, Button, Paper, IconButton, Stack } from '@mui/material'
import { TitleField, Root } from './styles'
import { useNavigate } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'
import { useDocument, useI18n } from 'hooks'

import lineClampStyle from 'components/LineClamp'
import { ReactComponent as DocIcon } from 'assets/icons/docs.svg'
import expertiseJSON from 'assets/data/expertise'

const PaperStyle = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3, 1.5),
  color: '#000',
  gap: theme.spacing(1.5),
  width: '100%',
  display: 'flex',
  transition: 'all 100ms ease-in',
  '& .docButton': {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      width: 16,
      height: 16,
      fill: '#fff',
      '& path': {
        fill: '#fff',
      },
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '& .docButton': {
      backgroundColor: '#fff',
      '& svg': {
        fill: theme.palette.primary.main,
        '& path': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
}))

const TypographyField = ({ children, sx, line = 2, ...props }) => {
  return (
    <Typography {...props} sx={{ textAlign: 'left', ...lineClampStyle(line), ...sx }}>
      {children}
    </Typography>
  )
}

const ItemExpertise = ({ title, path, handleRedirect, children }) => {
  return (
    <PaperStyle elevation={5} onClick={() => handleRedirect(path)}>
      <IconButton className="docButton">
        <DocIcon />
      </IconButton>
      <Stack alignItems="start" sx={{ flexGrow: 1, width: '100%' }}>
        <Box>
          <TypographyField variant="subtitle1" sx={{ fontWeight: 700, fontSize: '0.8rem' }} className="title">
            {title}
          </TypographyField>
          <TypographyField variant="body2" className="body2" line="1">
            {children}
          </TypographyField>
        </Box>
      </Stack>
    </PaperStyle>
  )
}

const GridContainer = styled(Box)(({ theme }) => ({
  height: 510,
  display: 'grid!important',
  gridTemplateColumns: '9fr 1fr 9fr 1fr 9fr',
  gridTemplateRows: '15px 15px 15px 15px 15px 15px',

  gridTemplateAreas: `
    'grid-0 grid-0 grid-1 grid-13 grid-7'
    'grid-0 grid-0 grid-2 grid-13 grid-8'
    'grid-0 grid-0 grid-3 grid-13 grid-9'
    'grid-0 grid-0 grid-4 grid-13 grid-10'
    'grid-0 grid-0 grid-5 grid-13 grid-11'
    'grid-0 grid-0 grid-6 grid-13 grid-12'
  `,
  gap: 15,
}))

export default function LandingExpertise({ closeDropdown }) {
  const navigate = useNavigate()
  const { handleUpdateArea } = useDocument()
  const { t, getLanguage } = useI18n({ keyPrefix: 'home.Areas' })

  const handleRedirect = (area, name) => {
    navigate(PATH_PAGE.documents)
    localStorage.setItem('area', area)
    localStorage.setItem('areaName', name)
    handleUpdateArea(area)
    closeDropdown()
  }

  return (
    // <Root>
    //   <TitleField title={t('title')}>{t('title_des')}</TitleField>
    //   <Grid container spacing={3} data-aos="fade-up">
    //     {expertiseJSON.map(item => (
    //       // eslint-disable-next-line react/no-array-index-key
    //       <Grid item key={item.title} xs={12} sm={6} md={4} lg={3} sx={{ textAlign: 'center' }}>
    //         <ItemExpertise
    //           title={getLanguage === 'en' ? item.title : item.titleVn}
    //           path={item.path}
    //           handleRedirect={handleRedirect}
    //         >
    //           {getLanguage === 'en' ? '390+ documents' : 'Hơn 390 bộ tài liệu'}
    //         </ItemExpertise>
    //       </Grid>
    //     ))}
    //   </Grid>
    // </Root>
    <Root isDropdown={true} sx={{ mb: 0, backgroundColor: 'white' }}>
      <GridContainer>
        <TitleField isDropdown grid={0} title={t('title')}>
          {t('title_des')}
        </TitleField>
        <div style={{ gridArea: 'grid-13', height: 510, alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ marginRight: 1, borderRight: '1px solid #eeeded', height: 510 }} />
        </div>
        {expertiseJSON.map((item, index) => {
          return (
            <Button
              className="option"
              onClick={() => handleRedirect(item.title, getLanguage === 'en' ? item.title : item.titleVn)}
              sx={{
                marginTop: 5,
                marginLeft: index < 6 ? 20 : 5,
                gridArea: `grid-${index + 1}`,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                fontWeight: 'bold',
                backgroundColor: 'blue',
                padding: '0 !important',
              }}
            >
              <Typography
                sx={{
                  borderRadius: 1,
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: 'black',
                }}
              >
                {getLanguage === 'en' ? item.title : item.titleVn}
              </Typography>
            </Button>
          )
        })}
      </GridContainer>
    </Root>
  )
}
