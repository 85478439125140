import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function LoadingForm({ open, handleClose }) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1, mt: '0!important' }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  )
}
