import axios from 'utils/axios'
import { GET_API, POST_API, UPDATE_API, DELETE_API } from 'routes/api'

const { createSlice } = require('@reduxjs/toolkit')

const newsSlice = createSlice({
  name: 'news',
  initialState: {
    news: [],
    newsDetail: null,
    newsRelated: null,
    isReset: false,
    isLoading: false,
  },
  reducers: {
    STATUS_LOADING: state => {
      state.isLoading = true
      state.isReset = false
    },
    STATUS_LOADING_SUCCESS: state => {
      state.isLoading = false
      state.isReset = true
    },
    SET_NEWS: (state, action) => {
      state.news = action.payload
    },
    SET_NEWS_DETAIL: (state, action) => {
      state.newsDetail = action.payload
    },
    SET_NEWS_RELATED: (state, action) => {
      state.newsRelated = action.payload
    },
    RESET_NEWS: state => {
      state.isReset = false
    },
  },
})

const { reducer, actions } = newsSlice

export const { SET_NEWS, RESET_NEWS } = actions

export default reducer

export function GET_NEWS() {
  return async dispatch => {
    try {
      const response = await axios.get(GET_API().news)
      dispatch(newsSlice.actions.SET_NEWS(response.data.news))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function GET_NEWS_BY_ID(newsID) {
  return async dispatch => {
    try {
      const response = await axios.get(GET_API(newsID).newsByID)
      if (response.data.status === 'success') {
        dispatch(newsSlice.actions.SET_NEWS_DETAIL(response.data.new))
      }
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function GET_NEWS_RELATED(newsID) {
  return async dispatch => {
    try {
      const response = await axios.get(GET_API(newsID).newsByRelated)
      if (response.data.status === 'success') {
        dispatch(newsSlice.actions.SET_NEWS_RELATED(response.data.new))
      }
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function CREATE_NEWS(form) {
  return async (dispatch, getState) => {
    dispatch(newsSlice.actions.STATUS_LOADING())

    try {
      const response = await axios.post(POST_API().news, form)
      dispatch(newsSlice.actions.STATUS_LOADING_SUCCESS())
      if (response.data.status === 'success') {
        const currentState = getState().news
        dispatch(newsSlice.actions.SET_NEWS([response.data.new, ...currentState.news]))
      }
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function EDIT_NEWS(form) {
  return async (dispatch, getState) => {
    dispatch(newsSlice.actions.STATUS_LOADING())
    try {
      const { newsID, ...props } = form
      const response = await axios.patch(UPDATE_API(newsID).newsEdit, props)
      dispatch(newsSlice.actions.STATUS_LOADING_SUCCESS())

      if (response.data.status === 'success') {
        const currentState = getState().news
        const newNews = currentState.news.map(n => {
          if (n?.id !== newsID) return n
          return response.data.newsEdit
        })
        // const newNewsDetail = { ...currentState.newsDetail, title: props.title }

        dispatch(newsSlice.actions.SET_NEWS(newNews))
        dispatch(newsSlice.actions.SET_NEWS_DETAIL(response.data.newEdit))
      }
    } catch (error) {
      console.log('err', error)
    }
  }
}
