import React from 'react'
import {
  IconButton,
  Typography,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material'

import LanguageIcon from '@mui/icons-material/Language'
import { ReactComponent as EnglishIcon } from 'assets/icons/en.svg'
import { ReactComponent as VietNamIcon } from 'assets/icons/vietnam.svg'
import { useI18n } from 'hooks'

const languages = [
  {
    label: 'English',
    labelVn: 'Tiếng Anh',
    code: 'en',
    icon: <EnglishIcon />,
  },
  {
    label: 'Vietnamese',
    labelVn: 'Tiếng Việt',
    code: 'vn',
    icon: <VietNamIcon />,
  },
]
export default function TranslateHeader() {
  const { i18next, getLanguage } = useI18n()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleChangeLang = (e, lang) => {
    handleClose(e)
    i18next.changeLanguage(lang)
  }

  return (
    <Stack direction="row" spacing={2}>
      <IconButton
        sx={{ p: 0 }}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <LanguageIcon />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="top-end" transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              sx={{
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,

                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  {languages.map(lang => (
                    <MenuItem
                      key={lang.code}
                      onClick={e => handleChangeLang(e, lang.code)}
                      value={lang.code}
                      sx={{ gap: 1, background: lang.code === getLanguage && 'rgba(145, 158, 171, 0.08)' }}
                    >
                      <IconButton sx={{ p: 0 }}>{lang.icon}</IconButton>
                      <Typography variant="caption">{getLanguage === 'en' ? lang.label : lang.labelVn}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  )
}
