// ----------------------------------------------------------------------

const shape = {
  borderRadius: 5,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
  borderRadiusCircle: '50%',
}

export default shape
