import React from 'react'

import { styled } from '@mui/styles'
import { Box, Stack, Typography, IconButton, Avatar, TextField, CircularProgress } from '@mui/material'
import { MButton } from 'components/_material-extend'

import Banner from 'assets/images/forums/Banner.png'
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { fDateOriginal } from 'utils/formatTime'
import { useI18n } from 'hooks'

const Root = styled(Box)(() => ({
  position: 'relative',
  boxShadow:
    '0px 2px 3px -1px rgb(144 144 150 / 20%), 0px 5px 6px 0px rgb(144 144 150 / 14%), 0px 1px 10px 0px rgb(144 144 150 / 12%)',
  borderRadius: 2,
  overflow: 'hidden',
}))

const BannerStyle = styled(Box)(({ bg }) => ({
  background: `url(${bg}) center no-repeat`,
  backgroundSize: 'cover',
  minHeight: 134,
}))

const Container = styled(Stack)(() => ({
  position: 'relative',
}))
const MButtonStyle = styled(MButton)(({ theme }) => ({
  padding: theme.spacing(0.9375, 2),
  fontSize: 12,
}))

const ItemField = ({ icon, children }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <IconButton
        sx={{
          padding: 0,
          '& svg': {
            fill: '#909096',
          },
        }}
      >
        {icon}
      </IconButton>
      <Typography variant="caption" sx={{ color: '#909096' }}>
        {children}
      </Typography>
    </Stack>
  )
}

const CameraBtn = ({ sx, handleClick }) => {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        ...sx,
        bgcolor: '#fff',
        '& svg': { fill: '#CA3636', width: 15, height: 13 },
        '&:hover': {
          bgcolor: '#fff',
        },
      }}
      onClick={handleClick}
    >
      <CameraAltIcon />
    </IconButton>
  )
}

const EditForm = ({ handleEditUser, t }) => {
  return (
    <Stack>
      <TextField
        multiline
        rows={4}
        placeholder="Write your bio here..."
        sx={{
          background: 'rgba(238, 238, 238, 0.69)',
          borderRadius: '5px',
          '& textarea': {
            fontSize: 12,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      />
      <Typography sx={{ fontSize: 12, color: '#909096' }}>3 characters remaining</Typography>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mt: 1 }}>
        <Stack direction="row" alignItem="center" spacing={1.2}>
          <MButtonStyle variant="gray" onClick={handleEditUser}>
            {t('input.cancel')}
          </MButtonStyle>
          <MButtonStyle variant="contained">{t('input.edit')}</MButtonStyle>
        </Stack>
      </Stack>
    </Stack>
  )
}

const NoEdit = ({ handleEditUser, bio, t }) => {
  const textDefault = 'Please write something in bio'
  const text = bio ? bio : textDefault
  return (
    <>
      <Typography variant="caption" sx={{ textAlign: 'center' }}>
        {text}
      </Typography>
      <MButton onClick={handleEditUser}>{t('input.editBio')}</MButton>
    </>
  )
}

const styleAvatar = { width: 100, height: 100, border: '3px solid #fff' }

export default function ForumUserInfo(props) {
  const { handleEditUser, handleChangeFile, isEdit, user, isLoadingUpload, numPosts, myUser } = props
  const { t, getLanguage } = useI18n({ keyPrefix: 'forum' })

  const imageUploadRef = React.useRef()

  return (
    <Root>
      <BannerStyle bg={Banner} />

      <Container spacing={1} sx={{ mb: 4.5, mt: -9.5 }}>
        <Stack alignItems="center">
          <Box sx={{ position: 'relative' }}>
            {!isLoadingUpload ? (
              <Avatar sx={{ backgroundColor: '#fff', ...styleAvatar }} src={user?.image_url} />
            ) : (
              <Stack
                sx={{ borderRadius: '50%', backgroundColor: '#fff', ...styleAvatar }}
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress color="primary" />
              </Stack>
            )}
            {myUser ? (
              <CameraBtn sx={{ right: 0, bottom: 0 }} handleClick={() => imageUploadRef.current.click()} />
            ) : (
              ''
            )}

            <input
              type="file"
              accept="image/*"
              ref={imageUploadRef}
              onChange={handleChangeFile}
              name="imageFiles"
              style={{ display: 'none' }}
            />
          </Box>
          <Box sx={{ maxWidth: 300, textAlign: 'center' }}>
            <Typography variant="subtitle1" color="primary" sx={{ textTransform: 'capitalize' }}>
              {user?.fullname}
            </Typography>
          </Box>
        </Stack>

        <Stack spacing={1.75} sx={{ px: 3.875 }}>
          <Stack direction="row" alignItems="center" spacing={4.125} sx={{ flexGrow: 1 }} justifyContent="center">
            {user?.createdAt && (
              <ItemField icon={<CalendarTodaySharpIcon sx={{ fontSize: 14 }} />}>
                {fDateOriginal(user?.createdAt)}
              </ItemField>
            )}
            <Typography variant="caption" sx={{ fontWeight: 700 }}>
              {numPosts} <span style={{ color: '#909096', fontWeight: 400 }}>{t('posts')}</span>
            </Typography>
          </Stack>

          {myUser ? (
            !isEdit ? (
              <NoEdit handleEditUser={handleEditUser} t={t} />
            ) : (
              <EditForm handleEditUser={handleEditUser} t={t} />
            )
          ) : (
            ''
          )}
        </Stack>
      </Container>
    </Root>
  )
}
