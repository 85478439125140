import React from 'react'

import { styled } from '@mui/styles'
import {
  Box,
  Avatar,
  Typography,
  Stack,
  Divider,
  IconButton,
  CircularProgress,
  TextareaAutosize,
  Chip,
} from '@mui/material'
import { PATH_PAGE } from 'routes/paths'
import { Link } from 'react-router-dom'
import { fToNow } from 'utils/formatTime'
import { MButton } from 'components/_material-extend'
import { ForumCommentActions } from './index'

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ReplyIcon from '@mui/icons-material/Reply'
import LazyLoad from 'react-lazyload'

const Container = styled(Stack)(() => ({
  width: '100%',
}))
const TypographyField = ({ variant = 'caption', children, sx, ...props }) => {
  return (
    <Typography variant={variant} sx={{ fontWeight: 700, ...sx }} {...props}>
      {children}
    </Typography>
  )
}
const ReplyItem = props => {
  const {
    reply,
    user,
    handleReplySameLevel,
    handleNumLikeReply,

    // other
    isEditReplyIndex,
    isDisabledForm,
    handleChangeFormEdit,
    handleSubmitFormEdit,
    handleToggleEdit,
    handleToggleDelete,
    // i18
    t,
  } = props
  const likeExists = reply?.likedUsers.findIndex(likedUser => likedUser === user?.id)

  const [isActionAnchor, setIsActionAnchor] = React.useState(null)
  const handleToggleActionComment = e => setIsActionAnchor(isActionAnchor ? null : e.currentTarget)

  return (
    <Container direction="row" spacing={1.5}>
      <Avatar sx={{ width: 32, height: 32 }} src={reply?.userImg} />
      <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
        <Stack
          sx={{
            position: 'relative',
            background: `rgba(0, 0, 0, 0.01)`,
            borderRadius: 2,
            py: 1,
            px: 2,
            width: 'fit-content',
            '&:hover': {
              '& .BtnActions': {
                opacity: 1,
              },
            },
          }}
        >
          <Box sx={{ zIndex: 1, position: 'relative', width: '100%' }}>
            {isEditReplyIndex?.id !== reply?._id ? (
              <>
                <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={0.5}>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    component={Link}
                    to={`${PATH_PAGE.profile}/${reply?.createdBy}`}
                  >
                    {reply?.createByName}
                  </Typography>
                  {reply?.createdBy === user?.id && (
                    <Box sx={{ potision: 'absolute', top: 0, right: 0 }}>
                      <ForumCommentActions
                        comment={reply}
                        isReply={true}
                        anchor={isActionAnchor}
                        handleToggle={handleToggleActionComment}
                        handleEditComment={handleToggleEdit}
                        handleDeleteComment={handleToggleDelete}
                      />
                    </Box>
                  )}
                </Stack>

                <Typography variant="caption">{reply?.content}</Typography>
                {reply?.replyFile ? (
                  <Box sx={{ width: 150 }}>
                    <LazyLoad>
                      <img src={reply?.replyFile} alt="imgComment" />
                    </LazyLoad>
                  </Box>
                ) : (
                  ''
                )}
              </>
            ) : (
              <Box>
                <TextareaAutosize
                  defaultValue={reply?.content}
                  // value={isEditReplyIndex?.content}
                  autoFocus={true}
                  onChange={e => handleChangeFormEdit(e, true)}
                  style={{
                    background: `transparent`,
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    resize: 'none',
                  }}
                />
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isDisabledForm ? <CircularProgress size={16} /> : null}
                  <Typography
                    color="gray"
                    variant="caption"
                    sx={{ fontWeight: 700, cursor: 'pointer' }}
                    onClick={() => handleSubmitFormEdit(true)}
                  >
                    {t('input.save')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="caption"
                    sx={{ fontWeight: 700, cursor: 'pointer' }}
                    onClick={() => handleToggleEdit(null, true)}
                  >
                    {t('input.close')}
                  </Typography>
                </Stack>
              </Box>
            )}
          </Box>

          {reply?.numLikes > 0 ? (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                right: -20,
                bottom: 10,
                justifyContent: 'flex-end',
                alignItems: 'end',
              }}
            >
              <Chip
                sx={{
                  height: 18,
                  alignItems: 'center',
                  '& .MuiChip-label': {
                    fontSize: 10,
                    pl: 1,
                    pt: 0.25,
                  },

                  '& svg': {
                    fill: '#CA3636',
                  },
                }}
                icon={<ThumbUpAltIcon sx={{ fontSize: 12, borderRadius: '50%' }} />}
                label={reply?.numLikes}
              />
            </Box>
          ) : null}
        </Stack>

        <Stack>
          <Stack direction="row" alignItems="center" spacing={0.5} sx={{ ml: 1 }}>
            <TypographyField
              color={likeExists !== -1 ? 'primary' : '#65676b'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleNumLikeReply(reply, likeExists === -1 ? 1 : -1)}
            >
              {t('like')}
            </TypographyField>
            <TypographyField sx={{ pb: 0.5, color: '#65676b' }}>.</TypographyField>

            <TypographyField sx={{ color: '#65676b', cursor: 'pointer' }} onClick={() => handleReplySameLevel(reply)}>
              {t('reply')}
            </TypographyField>
            <TypographyField sx={{ pb: 0.5, color: '#65676b' }}>.</TypographyField>

            {reply?.createdAt && (
              <TypographyField variant="caption" sx={{ color: '#65676b' }}>
                {fToNow(reply?.createdAt)}
              </TypographyField>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  )
}
export default function ForumReply(props) {
  const {
    comment,
    user,
    handleReplySameLevel,
    handleSubmitComments,
    handleEditReply,
    handleDeleteReply,
    handleNumLikeReply,
    handleMoreReply,

    // other
    isEditReplyIndex,
    isDisabledForm,
    handleChangeFormEdit,
    handleSubmitFormEdit,
    handleToggleEdit,
    handleToggleDelete,

    // i18
    t,
  } = props

  const numReplyHidden = comment?.reply.filter(reply => typeof reply !== 'object')

  return (
    <Container spacing={1.5}>
      <Divider />
      {numReplyHidden.length ? (
        <Box>
          <MButton
            startIcon={<ReplyIcon sx={{ fill: '#CA3636', transform: 'rotate(180deg)' }} />}
            sx={{ p: 0 }}
            onClick={() => handleMoreReply(comment?._id)}
          >
            <Typography variant="body2" color="primary" sx={{ fontSize: '0.7rem' }}>
              {numReplyHidden.length && numReplyHidden.length > 1
                ? `${t('moreReplies', { numReply: numReplyHidden.length })}`
                : `${t('moreReply', { numReply: numReplyHidden.length })}`}
            </Typography>
          </MButton>
        </Box>
      ) : (
        ''
      )}

      {comment?.reply?.length
        ? comment?.reply.map(reply => {
            if (typeof reply !== 'object') return
            return (
              <ReplyItem
                key={reply?._id}
                user={user}
                handleReplySameLevel={handleReplySameLevel}
                reply={reply}
                handleNumLikeReply={handleNumLikeReply}
                // other
                isEditReplyIndex={isEditReplyIndex}
                isDisabledForm={isDisabledForm}
                handleChangeFormEdit={handleChangeFormEdit}
                handleSubmitFormEdit={handleSubmitFormEdit}
                handleToggleEdit={handleToggleEdit}
                handleToggleDelete={handleToggleDelete}
                // i18
                t={t}
              />
            )
          })
        : ''}
    </Container>
  )
}
