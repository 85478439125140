import React from 'react'
import LazyLoad from 'react-lazyload'

import { styled, useTheme } from '@mui/styles'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'

import Banner from 'assets/images/docs/banner.png'
import Banner1 from 'assets/images/docs/banner1.png'
import { ReactPhotoCollage } from 'react-photo-collage'

import { formatImg, handleLayout } from 'utils/formatImgs'

const Item = styled(Box)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
}))

// const images = [Banner, Banner1, Banner1, Banner1, Banner1, Banner1, Banner1]

const GroupImageField = ({ children }) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.5)',
        cursor: 'pointer',
      }}
    >
      <Typography variant="h2" sx={{ color: '#fff' }}>
        +{children}
      </Typography>
    </Stack>
  )
}

export default function ForumPostItemImage(props) {
  const { handleImageLightBox, images } = props
  const [countImg, setCountImg] = React.useState(0)
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down(705))

  const [photos, setPhotos] = React.useState([])
  const [layout, setLayout] = React.useState([])

  React.useEffect(() => {
    const photos = formatImg(images)
    const layout = handleLayout(images)

    setPhotos(photos)
    setLayout(layout)
  }, images)

  const Container = styled(Box)(({ theme, length }) => ({}))

  const settings = {
    width: '100%',
    height: ['300px', '300px'],
    layout: layout ? layout : [],
    photos: photos ? photos : [],
    showNumOfRemainingPhotos: true,
  }

  return images.length ? (
    <Container length={images.length}>
      <ReactPhotoCollage {...settings} />
    </Container>
  ) : (
    ''
  )
}
