import React from 'react'
import { styled } from '@mui/styles'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { MButton } from 'components/_material-extend'
import { useI18n } from 'hooks'

const MButtonStyle = styled(MButton)(({ theme }) => ({
  padding: theme.spacing(1, 4),
  fontSize: 12,
}))

export default function DialogAlert(props) {
  const { textYes, handleClose, open = false, title, children, handleAccept, id, isActions = true } = props
  const { t } = useI18n({ keyPrefix: 'forum' })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 9999999 }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
      </DialogContent>
      {isActions ? (
        <DialogActions>
          <MButtonStyle variant="gray" onClick={handleClose}>
            {t('input.cancel')}
          </MButtonStyle>
          <MButtonStyle variant="contained" onClick={() => handleAccept(id, true)}>
            {textYes}
          </MButtonStyle>
        </DialogActions>
      ) : (
        ''
      )}
    </Dialog>
  )
}
