import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'

import { styled } from '@mui/styles'
import { Box, Typography, Stack } from '@mui/material'

import AuthBanner from 'assets/images/landing/auth.png'
import { useI18n } from 'hooks'

const Root = styled(Box)(({ theme }) => ({
  background: `url(${AuthBanner}) center no-repeat`,
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: theme.spacing(0, 10),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 10),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 5),
  },
}))

export default function LandingBanner() {
  const navigate = useNavigate()
  const { t } = useI18n({ keyPrefix: 'auth' })

  return (
    <Root>
      <Typography
        variant="h2"
        sx={{
          color: '#fff',
          mb: 7,
          transition: 'all 300ms ease',
          cursor: 'pointer',
          '&:hover': { textShadow: ' 5px 2px 4px grey' },
        }}
        onClick={() => navigate(PATH_PAGE.root)}
      >
        LNT & Partners
      </Typography>

      <Typography variant="h6" sx={{ color: '#fff', fontWeight: 400 }}>
        {t('banner_des')}
      </Typography>
    </Root>
  )
}
