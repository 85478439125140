import React from 'react'
import { styled, useTheme } from '@mui/styles'
import { Box, Typography, Stack, SvgIcon, Avatar, useMediaQuery } from '@mui/material'
import { TitleMain } from 'components/_expand-page/Forum/styles'
import { Link as RouterLink } from 'react-router-dom'
import { MButton } from 'components/_material-extend'
import { PATH_PAGE } from 'routes/paths'
import lineClampStyle from 'components/LineClamp'

import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp'
import { fDateTime } from 'utils/formatTime'
import { useI18n } from 'hooks'

const Root = styled(Box)(({ theme }) => ({
  border: '1px solid #E5E5E5',
  borderRadius: 5,
  maxWidth: '1000px',
}))
const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3.25, 3.875, 4.375),
}))

const TypographyField = ({ variant = 'caption', sx, children }) => {
  return (
    <Typography sx={{ color: '#909096', ml: 0.5, ...sx }} variant={variant}>
      {children}
    </Typography>
  )
}
export default function GroupDetail(props) {
  const { group, handleJoin, user } = props
  const { ImgUrl, createdAt, numMembers, members, posts, title, _id } = group
  const { t } = useI18n({ keyPrefix: 'forum' })
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const join = members.length ? members.filter(member => member === user?.id).length : false

  return (
    <Root data-aos="fade-up">
      <TitleMain>
        <Typography variant="subtitle2Res" sx={{ color: '#fff' }}>
          {t('title.groupInfo')}
        </Typography>
      </TitleMain>
      <Container spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%' }}>
          <Stack sx={{ flexGrow: 1 }}>
            <RouterLink
              to={`${PATH_PAGE.group}/${_id}`}
              style={{ flexGrow: 1, fontSize: 14, fontWeight: 700, color: '#CA3636', ...lineClampStyle(1) }}
            >
              {title}
            </RouterLink>
            <Stack direction="row" alignItems="center" spacing={0.75}>
              <SvgIcon sx={{ fontSize: 14 }}>
                <CalendarTodaySharpIcon />
              </SvgIcon>
              {createdAt && (
                <Typography variant="caption" sx={{ fontWeight: 700 }}>
                  {fDateTime(createdAt)}
                </Typography>
              )}
            </Stack>
          </Stack>
          <MButton
            variant={!join ? 'contained' : 'outlined'}
            sx={{ px: 2, py: 0.5, fontSize: 12 }}
            onClick={() => handleJoin({ id: _id, value: join })}
          >
            {!join ? t('join') : t('joined')}
          </MButton>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={3.125} sx={{ mt: 0 }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption" sx={{ fontWeight: 700 }}>
              {posts.length}
            </Typography>
            <TypographyField> {t('posts')}</TypographyField>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption" sx={{ fontWeight: 700 }}>
              {numMembers}
            </Typography>
            <TypographyField>{t('members')}</TypographyField>
          </Stack>
        </Stack>

        <Box sx={{ mt: 2 }}>
          <Typography variant="caption">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate aenean lorem a cras est. Gravida tempus
            ac dignissim turpis gravida nulla ornare.
          </Typography>
        </Box>
      </Container>
    </Root>
  )
}
