import React, { useState, useEffect } from 'react'
import axios from 'utils/axios'
import { useI18n } from 'hooks'
import { GET_API, POST_API, UPDATE_API } from 'routes/api'
import { useDispatch, useSelector } from 'react-redux'
import {
  SET_SEARCH_RESULT,
  UPDATE_AREA,
  UPDATE_AREA_NAME,
  UPDATE_AREA_INDEX,
  UPDATE_TYPE,
  SET_DOCS,
  SET_DOCS_RELATED,
  SET_DOCS_MOST_VIEW,
} from 'store/reducers/DocSlice'
import { HANDLE_MESSAGE } from 'store/reducers/PostSlice'

export default function useDocument() {
  const { getLanguage } = useI18n({ keyPrefix: 'doc' })
  const dispatch = useDispatch()

  // redux
  const docs = useSelector(state => state.doc.docs)
  const docRelated = useSelector(state => state.doc.docRelated)
  const docMostView = useSelector(state => state.doc.docMostView)
  const docSearch = useSelector(state => state.doc.docSearch)
  const area = useSelector(state => state.doc.area)
  const areaName = useSelector(state => state.doc.areaName)
  const areaIndex = useSelector(state => state.doc.areaIndex)
  const type = useSelector(state => state.doc.type)

  const [docDetail, setDocDetail] = useState({})

  const handleGetDocs = async () => {
    try {
      const response = await axios.get(GET_API('', getLanguage).docs)
      console.log(response)
      dispatch(SET_DOCS(response.data.file))
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleGetDocByID = async id => {
    try {
      const response = await axios.get(GET_API(id).docByID)
      console.log(response.data.file.path)
      setDocDetail(response.data.file)
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleGetDocMostView = async () => {
    try {
      const response = await axios.get(GET_API().docMostView)
      dispatch(SET_DOCS_MOST_VIEW(response.data.file))
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetDocRelated = async id => {
    try {
      const response = await axios.get(GET_API(id).docRelated)
      dispatch(SET_DOCS_RELATED(response.data.files))
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleUpdateDoc = async (id, form) => {
    try {
      const response = await axios.patch(UPDATE_API(id).updateDoc, form)
      console.log('response', response)
      // setDocDetail(response.data.file)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleSearchDoc = async search => {
    let formData
    try {
      if (search === '') return handleGetDocs()

      formData = { title: search }

      // const response = await axios.post(POST_API('', getLanguage).docSearch, formData)
      const response = await axios.get(GET_API('', search).docKeywordSearch)
      console.log(response)
      if (response.data.status === 'success' && response.data.files.length === 0) {
        dispatch(HANDLE_MESSAGE({ status: 'warning', message: 'No results ' }))
      }
      dispatch(SET_SEARCH_RESULT(response.data.files))
    } catch (error) {
      console.log('eror', error)
    }
  }

  const handleSearchDocTitle = async (search, isNavbar = false) => {
    let formData
    try {
      console.log('search', search)
      // if (search === '') return handleGetDocs()
      if (search === '') return dispatch(SET_SEARCH_RESULT([]))
      formData = { query: search }

      const response = await axios.post(POST_API('', getLanguage).docSearchTitle, formData)

      if (response.data.status === 'success' && response.data.files.length === 0) {
        dispatch(HANDLE_MESSAGE({ status: 'warning', message: 'No results ' }))
      }

      const newSearch = response.data.files
        .filter(
          file =>
            file?.content.toLowerCase().includes(search.toLowerCase()) ||
            file?.title.toLowerCase().includes(search.toLowerCase())
        )
        .map(file => {
          const newContent = file.content.replace(
            new RegExp(search, 'gi'),
            match => `<mark style={{background: '#999', color: '#fff'}}>${match}</mark>`
          )
          const newTitle = file.title.replace(
            new RegExp(search, 'gi'),
            match => `<mark style={{background: '#999', color: '#fff'}}>${match}</mark>`
          )
          return { ...file, content: newContent, title: newTitle }
        })

      if (isNavbar) {
        return newSearch
      }
      dispatch(SET_SEARCH_RESULT(newSearch))
    } catch (error) {
      console.log('eror', error)
    }
  }
  const handleUpdateArea = value => {
    dispatch(UPDATE_AREA(value))
  }

  const handleUpdateAreaName = value => {
    dispatch(UPDATE_AREA_NAME(value))
  }

  const handleUpdateAreaIndex = index => {
    dispatch(UPDATE_AREA_INDEX(index))
  }

  const handleUpdateType = value => {
    dispatch(UPDATE_TYPE(value))
  }

  const handleReset = () => {
    dispatch(UPDATE_AREA(''))
    dispatch(UPDATE_AREA_NAME(''))
    dispatch(UPDATE_TYPE(''))
    dispatch(UPDATE_AREA_INDEX(-1))
  }

  const handleDocPostFile = async form => {
    try {
      const { area, type, files, language, badges } = form
      const formData = new FormData()

      await formData.append('area', area)
      await formData.append('type', type)
      await formData.append('file', files)
      await formData.append('language', language === 'English' ? 'en' : 'vn')
      for (let i = 0; i < badges.length; i++) {
        formData.append('badges', badges[i])
      }

      const response = await axios.post(POST_API().docFile, formData)
      console.log(response)
      if (response.data.status === 'error') {
        return { status: 'error', data: response.data }
      }
      dispatch(SET_DOCS([response.data.newfileEx, ...docs]))
      return { status: 'success' }
    } catch (error) {
      console.log('err', error)
    }
  }
  return {
    handleGetDocs,
    handleGetDocByID,
    handleUpdateDoc,
    handleSearchDoc,
    handleUpdateArea,
    handleUpdateAreaName,
    handleUpdateAreaIndex,
    handleUpdateType,
    handleGetDocRelated,
    handleGetDocMostView,
    handleDocPostFile,
    handleSearchDocTitle,
    handleReset,
    docRelated,
    docMostView,
    docs,
    docSearch,
    docDetail,
    area,
    areaName,
    areaIndex,
    type,
  }
}
