import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Stack, Typography, Avatar, Skeleton, useMediaQuery } from '@mui/material'
import lineClampStyle from 'components/LineClamp'
import { Link } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'
import LazyLoad from 'react-lazyload'
import { fToNow } from 'utils/formatTime'

const CustomSkeleton = () => {
  return (
    <Box sx={{ flex: 1, height: 180, width: '240px', borderRadius: 1, backgroundColor: '#f6f6f6' }}>
      <Stack sx={{ height: '100%', py: 1, px: 2 }} justifyContent="flex-end">
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="text" width="80%" />
        <Stack direction="row" alignItems="center" spacing={1}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="text" width="50%" height={10} />
        </Stack>
      </Stack>
    </Box>
  )
}
const Item = ({ item }) => {
  return (
    <Box sx={{ position: 'relative', height: 180, borderRadius: 1, overflow: 'hidden', flex: 1 }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <LazyLoad height={180}>
          <img src={item?.postBanner || item?.userImg} alt={item.title} style={{ objectFit: 'cover' }} />
        </LazyLoad>
      </Box>
      <Stack
        sx={{ position: 'relative', height: '100%', py: 1, px: 2, backgroundColor: 'rgba(0,0,0,0.5)' }}
        justifyContent="flex-end"
      >
        <Typography
          component={Link}
          to={`${PATH_PAGE.forums}/${item?._id}`}
          sx={{ ...lineClampStyle(1), color: '#fff' }}
          variant="h6"
        >
          {item?.title}
        </Typography>

        <div
          dangerouslySetInnerHTML={{ __html: item?.content }}
          style={{ fontSize: 12, color: '#FFF', ...lineClampStyle({ line: 2 }) }}
        />

        {/* <Typography
          component={Link}
          to={`${PATH_PAGE.forums}/${item?._id}`}
          sx={{ ...lineClampStyle(2), color: '#fff' }}
          variant="body2"
        >
          {item?.content}
        </Typography> */}
        <Stack direction="row" alignItems="flex-start" spacing={1} sx={{ mt: 1 }}>
          <Avatar src={item?.userImg} alt="" sx={{ width: 20, height: 20, border: '1px solid #fff' }} />
          <Stack direction="column" alignItems="flex-start">
            <Typography
              sx={{ ...lineClampStyle({ line: 2 }), color: '#fff' }}
              variant="caption"
              component={Link}
              to={`${PATH_PAGE.forums}/${item?._id}`}
            >
              {item?.createByName}
            </Typography>
            {/* <Typography sx={{ ...lineClampStyle({ line: 2 }), color: '#fff' }} variant="caption">
              {fToNow(item?.createdAt)}
            </Typography> */}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

function ForumTrendingToday({ posts }) {
  const [isLoading, setLoading] = React.useState(true)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 3000)

    return () => {
      setLoading(true)
      clearTimeout(timer)
    }
  }, [])

  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
      {!isLoading
        ? posts.length
          ? posts.slice(0, matches ? 2 : posts.length).map(item => <Item key={item._id} item={item} />)
          : null
        : [1, 2, 3, 4].map(num => <CustomSkeleton key={`CustomSkeleton-${num}`} />)}
    </Stack>
  )
}

export default ForumTrendingToday
