import React from 'react'

import { styled } from '@mui/styles'
import { Box, Typography, Skeleton, Stack } from '@mui/material'
import { TitleMain } from 'components/_expand-page/Forum/styles'
import { useI18n } from 'hooks'

const Root = styled(Box)(() => ({
  // border: '1px solid #E5E5E5',
  borderRadius: 5,
  overflow: 'hidden',
  boxShadow:
    '0px 2px 3px -1px rgb(144 144 150 / 20%), 0px 5px 6px 0px rgb(144 144 150 / 14%), 0px 1px 10px 0px rgb(144 144 150 / 12%)',
}))
const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
}))

const SkeletonCustom = () => {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Skeleton variant="circular" width={32} height={32} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1 }} spacing={2}>
        <Stack sx={{ flexGrow: 1 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" width="60%" />
        </Stack>
        <Skeleton variant="rectangular" width={50} />
      </Stack>
    </Stack>
  )
}

const RuleItem = ({ children }) => {
  return (
    <Box>
      <Typography sx={{ cursor: 'context-menu' }} variant="subtitle2">
        {children}
      </Typography>
    </Box>
  )
}
export default function GroupRules(props) {
  const { group } = props
  const { t, getLanguage } = useI18n({ keyPrefix: 'forum' })

  return (
    <Root>
      <TitleMain>
        <Typography variant="subtitle2Res" sx={{ color: '#fff' }}>
          {getLanguage === 'en' ? `${t('rules')}` : `${t('rules')}`}
        </Typography>
      </TitleMain>
      <Container spacing={2}>
        {group?.rules?.length
          ? group.rules.map((rule, ind) => (
              <RuleItem key={rule}>
                {ind + 1}. {rule}
              </RuleItem>
            ))
          : [1, 2, 3, 4].map(item => <SkeletonCustom key={item} />)}
      </Container>
    </Root>
  )
}
