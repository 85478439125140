import React from 'react'
import { styled, useTheme } from '@mui/styles'
import { Skeleton, Stack, Typography, useMediaQuery } from '@mui/material'
import { TitleMain } from './styles'

import { ForumTopicItem } from './index'
import { useI18n } from 'hooks'

const Container = styled(Stack)(() => ({
  borderRadius: 5,
  boxShadow:
    '0px 2px 3px -1px rgb(144 144 150 / 20%), 0px 5px 6px 0px rgb(144 144 150 / 14%), 0px 1px 10px 0px rgb(144 144 150 / 12%)',
}))

const SkeletonCustom = () => {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Skeleton variant="circular" width={36} height={36} />
      <Stack sx={{ flexGrow: 1 }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
      </Stack>
    </Stack>
  )
}
export default function ForumTopics({ posts }) {
  const theme = useTheme()
  const { t, getLanguage } = useI18n({ keyPrefix: 'forum' })

  const [isNoPosts, setIsNoPosts] = React.useState(false)

  React.useEffect(() => {
    if (posts.length > 0) return setIsNoPosts(false)
    const timer = setTimeout(() => setIsNoPosts(true), 5000)
    return () => clearTimeout(timer)
  }, [posts])

  return !isNoPosts ? (
    <Container spacing={2}>
      <TitleMain>
        <Typography variant="subtitle2Res" sx={{ color: '#fff', [theme.breakpoints.down('sm')]: { fontWeight: 700 } }}>
          {t('title.topTopic')}
        </Typography>
      </TitleMain>

      <Stack spacing={2} sx={{ px: 2, pb: 2 }}>
        {posts.length
          ? posts.map((post, ind) => (
              <ForumTopicItem key={`${post._id}-${Math.random(33312312)}`} ind={ind + 1} post={post} />
            ))
          : [1, 2, 3, 4].map(item => <SkeletonCustom key={item} />)}
      </Stack>
    </Container>
  ) : (
    ''
  )
}
