import React, { useState, useRef, useEffect } from 'react'

import { useAuth } from 'hooks'
import { PATH_PAGE } from 'routes/paths'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { fToNow } from 'utils/formatTime'

import ReactQuill from 'react-quill'

import { styled, useTheme } from '@mui/styles'
import { Box, Stack, IconButton, Typography, Avatar, Paper, useMediaQuery } from '@mui/material'

import { ForumPostItemImage, ForumActionPost, ForumActions } from './index'
import ImageLightbox from 'components/ImageLightBox'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
// import Banner from 'assets/images/docs/banner.png'
// import Banner1 from 'assets/images/docs/banner1.png'
// import Rectangle1 from 'assets/images/docs/Rectangle1.png'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import '../../../assets/css/fadeOutText.css'
import TextEditorV2 from 'components/TextEditorV2'
import lineClamp from 'components/LineClamp'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  '&.postItem': {
    '&:not(:last-child)': {
      borderBottom: '1px solid #E5E5E5',
    },
  },
}))

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.125, 2.75),
}))

const Sidebar = ({ handleUpDownVote, nameState, downvote, upvote, like = 0, postID, userID }) => {
  const disableUp = upvote?.length ? upvote.filter(member => member === userID).length : false
  const disableDown = downvote?.length ? downvote.filter(member => member === userID).length : false

  return (
    <Stack justifyContent="flex-start" sx={{ background: '#F3F3F3', pt: 0, borderRight: '1px solid #E5E5E5', px: 0.2 }}>
      <Stack alignItems="center">
        <IconButton
          sx={{
            p: 0,
            '& svg': {
              fill: theme => (!disableUp ? '#babfc4' : theme.palette.primary.main),
              fontSize: 60,
            },
          }}
          onClick={() => handleUpDownVote(nameState, postID, 'up')}
        >
          <ArrowDropUpIcon />
        </IconButton>
        <Typography sx={{ color: '#6a737c', my: -0.75 }} variant="subtitle1">
          {like}
        </Typography>
        <IconButton
          sx={{
            p: 0,
            '& svg': {
              fill: theme => (!disableDown ? '#babfc4' : theme.palette.primary.main),
              fontSize: 60,
            },
          }}
          onClick={() => handleUpDownVote(nameState, postID, 'down')}
        >
          <ArrowDropDownIcon />
        </IconButton>
      </Stack>
    </Stack>
  )
}

const Header = props => {
  const { nameState } = props
  const { postID, createdBy, createdAt, createByName, avatar, user, handleAction } = props
  const [anchorAction, setAnchorAction] = React.useState(null)

  const handleToggle = e => {
    setAnchorAction(anchorAction ? null : e.currentTarget)
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <Box>
        <Avatar sx={{ width: 32, height: 32, borderRadius: 16 }} variant="rounded" src={avatar} />
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2" color="primary" component={Link} to={`${PATH_PAGE.profile}/${createdBy}`}>
            {createByName}
          </Typography>
          {createdAt && (
            <Typography variant="subtitle2" sx={{ fontSize: '0.7rem', mt: -0.5 }}>
              {fToNow(createdAt)}
            </Typography>
          )}
        </Box>
        {user?.id === createdBy || user.role === 'admin' ? (
          <IconButton onClick={handleToggle} sx={{ p: 0 }}>
            <MoreHorizIcon />
            <ForumActionPost
              anchor={anchorAction}
              handleClose={handleToggle}
              handleAction={actionName => handleAction(nameState, actionName, postID)}
            />
          </IconButton>
        ) : (
          ''
        )}
      </Stack>
    </Stack>
  )
}

export default function ForumPostItem(props) {
  // nameState is action
  const {
    outside,
    handleSharePost,
    cursor = true,
    children,
    post,
    handleUpDownVote,
    user,
    handleAction,
    nameState,
    isInDetail,
  } = props

  const {
    _id,
    content,
    title,
    path = [],
    createdAt,
    createdBy,
    userImg,
    createByName,
    numComments = 0,
    Comments = [],
    numLikes,
    upvote,
    downvote,
  } = post

  const navigate = useNavigate()
  const location = useLocation().pathname
  const [isLightBox, setIsLightBox] = React.useState({ open: false, ind: 0 })
  const { isAuthenticated } = useAuth()
  const [isOverflown, setIsOverflown] = useState(outside ? true : !isAuthenticated)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      const { scrollHeight } = ref.current
      const { clientHeight } = ref.current
      setIsOverflown(scrollHeight > clientHeight)
    }
  }, [ref])

  const handleImageLightBox = index => {
    setIsLightBox({ ind: index ? index : 0, open: !isLightBox.open })
  }

  const handleNavigate = () => {
    navigate(`${PATH_PAGE.forums}/${_id}`)
  }

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const handleNotLogin = () => {
    if (window.confirm('You need to be authenticated to read the post. Login now?')) {
      localStorage.setItem('prevPath', location)
      navigate('/auth/login')
    }
  }

  const handleNotLogin2 = () => {
    if (window.confirm('You need to be authenticated to upvote/downvote. Login now?')) {
      localStorage.setItem('prevPath', location)
      navigate('/auth/login')
    }
  }

  // const isOverflown = () => {
  //   const el = document.getElementsByClassName('parent')
  //   console.log(el.clientHeight)
  //   return el.scrollHeight > el.clientHeight
  // }

  return (
    <Paper elevation={5}>
      <Root className="postItem">
        <Sidebar
          postID={_id}
          userID={user?.id}
          like={numLikes}
          handleUpDownVote={isAuthenticated ? handleUpDownVote : handleNotLogin2}
          nameState={nameState}
          upvote={upvote}
          downvote={downvote}
        />
        <Stack sx={{ flexGrow: 1 }}>
          <Container>
            <Header
              nameState={nameState} // this is action
              postID={_id}
              createdAt={createdAt}
              createdBy={createdBy}
              createByName={createByName}
              user={user}
              avatar={userImg}
              handleAction={isAuthenticated ? handleAction : handleNotLogin2}
            />
            <Box sx={{ my: 1.5 }}>
              <Box sx={{ cursor: cursor ? 'pointer' : 'unset' }}>
                <Typography
                  onClick={!isAuthenticated && handleNotLogin}
                  component={isAuthenticated && Link}
                  to={isAuthenticated && `${PATH_PAGE.forums}/${_id}`}
                  sx={{
                    lineHeight: '16px',
                    fontWeight: 700,
                    fontSize: 16,
                    ...lineClamp(10),
                  }}
                >
                  {title}
                </Typography>

                {/* <Typography sx={{ fontSize: 14, marginTop: 2, ...lineClamp({ line: 10 }) }}>{content}</Typography> */}
                <div ref={ref} style={{ height: isOverflown && 210, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <div dangerouslySetInnerHTML={{ __html: content }} style={{ fontSize: 14, marginTop: 2 }} />
                </div>

                {isOverflown && (
                  <Typography
                    onClick={!isAuthenticated && handleNotLogin}
                    component={isAuthenticated && Link}
                    to={isAuthenticated && `${PATH_PAGE.forums}/${_id}`}
                    sx={{ marginTop: 1, fontSize: 12, color: '#ca3636', fontStyle: 'italic' }}
                  >
                    Read more
                  </Typography>
                )}

                {!isInDetail ? <Typography style={{ backgroundColor: 'red' }} /> : null}

                {/* <Typography
                  variant="body1"
                  className={`${!matches || isInDetail ? '' : 'fade-out'}`}
                  sx={{
                    mt: 1,
                    fontSize: 12,
                    lineHeight: 1.75,
                    // maxHeight: matches ? 100 : null,
                    // overflow: 'hidden',
                  }}
                >
                  {content}
                  
                </Typography> */}
              </Box>

              <Box sx={{ mt: 2.5 }}>
                <ForumPostItemImage handleImageLightBox={handleImageLightBox} images={path} />
                <ImageLightbox open={isLightBox.open} ind={isLightBox.ind} listImages={path} />
              </Box>
            </Box>
            <ForumActions
              handleNavigate={handleNavigate}
              numComments={numComments}
              handleShare={() => handleSharePost(_id)}
            />
            {children}
          </Container>
        </Stack>
      </Root>
    </Paper>
  )
}
