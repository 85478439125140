const { createSlice } = require('@reduxjs/toolkit')

const docSlice = createSlice({
  name: 'doc',
  initialState: {
    area: '',
    areaName: '',
    areaIndex: -1,
    type: '',
    docs: [],
    docSearch: [],
    docMostView: [],
    docRelated: [],
  },
  reducers: {
    UPDATE_AREA: (state, action) => {
      state.area = action.payload
    },
    UPDATE_AREA_NAME: (state, action) => {
      state.areaName = action.payload
    },
    UPDATE_AREA_INDEX: (state, action) => {
      state.areaIndex = action.payload
    },
    UPDATE_TYPE: (state, action) => {
      state.type = action.payload
    },
    UPDATE_RESULT_SEARCH: (state, action) => {
      state.docSearch = action.payload
    },
    SET_DOCS: (state, action) => {
      state.docs = action.payload
      state.docSearch = []
    },
    SET_DOCS_RELATED: (state, action) => {
      state.docRelated = action.payload
    },
    SET_DOCS_MOST_VIEW: (state, action) => {
      state.docMostView = action.payload
    },
    SET_SEARCH_RESULT: (state, action) => {
      state.docSearch = action.payload
      state.area = ''
      state.type = ''
    },
  },
})

const { reducer, actions } = docSlice

export const {
  UPDATE_AREA,
  UPDATE_AREA_NAME,
  UPDATE_AREA_INDEX,
  UPDATE_TYPE,
  SET_DOCS,
  SET_SEARCH_RESULT,
  SET_DOCS_RELATED,
  SET_DOCS_MOST_VIEW,
} = actions

export default reducer
