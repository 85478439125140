import React from 'react'
import { styled, useTheme } from '@mui/styles'
import {
  // IconButton,
  Typography,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  // ListItemIcon,
  Stack,
  Button,
  Avatar,
  useMediaQuery,
} from '@mui/material'
import { Link } from 'react-router-dom'
// import PersonAdd from '@mui/icons-material/PersonAdd'
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

import { useI18n } from 'hooks'

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
  gap: theme.spacing(1),
  '& a': {
    fontSize: 14,
    color: '#909096',
  },
  '&:hover': {
    background: 'rgba(238, 238, 238, 0.6)',
    '& a': {
      color: '#000',
    },
  },
}))

const TypographyStyle = styled(Typography)(() => ({
  color: '#909096',
  fontSize: 14,
}))

const linksRouter = [
  {
    name: 'Profile',
    nameVn: 'Thông tin',
    path: '/profile',
  },
  {
    name: 'Internal VNLIS',
    nameVn: 'Nội bộ VNLIS',
    path: 'https://www.lntinternal.com/',
  },
  {
    name: 'Edit Profile',
    nameVn: 'Sửa thông tin',
    path: '/profile/edit',
  },
  {
    name: 'Account Setting',
    nameVn: 'Cài đặt',
    path: '/profile/setting',
  },
  {
    name: 'Log out',
    nameVn: 'Đăng xuất',
    path: '/auth/logout',
  },
]

export default function ProfileHeader(props) {
  const { getLanguage } = useI18n()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const { user } = props
  const [links, setLinks] = React.useState(linksRouter)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const prevOpen = React.useRef(open)

  React.useEffect(() => {
    const newLinks = links.map(link => {
      if (link.name === 'Profile') {
        link.avatar = user?.image_url
        return { ...link, name: user?.fullname || '' }
      }
      return link
    })

    setLinks(newLinks)
  }, [user])

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <Stack direction="row" spacing={2}>
      <Button
        sx={{
          // '&:hover': {
          //   backgroundColor: '#dedede !important',
          // },
          p: 0,
          minWidth: 0,
          borderRadius: '5px',
          padding: '3px 10px',
          display: { xs: 'none', sm: 'block', md: 'flex' },
          flexDirection: 'row',
          alignItems: 'center',
        }}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Avatar src={user?.image_url || true} sx={{ cursor: 'pointer', width: 32, height: 32 }} />
        {!matches && (
          <Typography sx={{ fontWeight: '600', color: '#ca3636', marginLeft: '10px' }}>{user?.fullname}</Typography>
        )}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="top-end" transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              sx={{
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                width: 200,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 14,
                  right: 75,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  {links.map(link => {
                    if (link.name === 'Internal VNLIS') {
                      return (
                        <MenuItemStyle key={link.name}>
                          <a href={link.path} target="_blank" onClick={handleClose} rel="noreferrer">
                            <TypographyStyle>{getLanguage === 'en' ? link.name : link.nameVn}</TypographyStyle>
                          </a>
                        </MenuItemStyle>
                      )
                    }
                    return (
                      <MenuItemStyle key={link.name}>
                        {link.avatar && (
                          <Avatar sx={{ width: 24, height: 24 }} src={link.avatar} alt={user?.fullname} />
                        )}
                        <TypographyStyle component={Link} to={link.path} onClick={handleClose}>
                          {getLanguage === 'en' ? link.name : link.nameVn}
                        </TypographyStyle>
                      </MenuItemStyle>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  )
}
