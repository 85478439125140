// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_PAGE = '/'
const ROOTS_AUTH = '/auth/'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_PAGE,
  login: path(ROOTS_AUTH, 'login'),
  signup: path(ROOTS_AUTH, 'signup'),
  forgotPassword: path(ROOTS_AUTH, 'forgot-password'),
  resetPassword: path(ROOTS_AUTH, 'reset-password'),
  verify: path(ROOTS_AUTH, 'verify'),
}

export const PATH_PAGE = {
  root: '/home',
  about: '/about-us',
  documents: '/documents',
  // contract: '/contract',
  forums: '/forums',
  group: '/group',
  profile: '/profile',
  contact: '/contact',
  news: '/news',
  pipeline: '/pipeline-tracker',
}
export const PATH_PAGE_ERROR = {
  notFound: '/404',
}
