import React from 'react'
import { useSnackbar } from 'notistack'

// import { useSelector, useDispatch } from 'react-redux'
// import { UPLOAD_IMAGE_FILE, DELETE_IMAGE_FILEM } from 'store/reducers/FileSlice'

const acceptImage = ['png', 'jpg', 'jpeg']

export default function useUploadFiles() {
  // const dispatch = useDispatch()
  const [imgFiles, setImgFiles] = React.useState(null)

  const { enqueueSnackbar } = useSnackbar()

  const validateFile = info => {
    const { name, size } = info
    const regex = new RegExp('[^.]+$')
    const extension = name.match(regex)
    const mb = size / 10000000
    const find = acceptImage.findIndex(ext => ext === extension[0])
    if (find === -1) {
      enqueueSnackbar('File không hợp lệ', { variant: 'warning' })
      return false
    }
    if (mb > 10) {
      enqueueSnackbar('File tối đa 10MB', { variant: 'warning' })
      return false
    }
    return true
  }
  //---------------------

  const handleNewField = ({ file }) => {
    setImgFiles(file)
  }

  //----------------
  const handleChangeFile = event => {
    const { files } = event.target

    const response = validateFile(files[0])

    if (!response) {
      return enqueueSnackbar('Upload avatar not success', { variant: 'error' })
    }

    const reader = new FileReader()
    reader.onload = e => handleNewField({ file: files[0] })
    files[0] && reader.readAsDataURL(files[0])
    return false
  }

  // ---- example
  // const handleRemove = id => {
  //   const getIndex = sourceImg.findIndex(source => source.id === id)
  //   const newSource = sourceImg.filter(source => source.id !== id)
  //   const newFiles = imgFiles
  //   newFiles.splice(getIndex, 1)

  //   setSourceImg(newSource)
  //   setImgFiles(newFiles)
  // }

  return { handleChangeFile, imgFiles, validateFile }
}
