import React from 'react'
import { styled } from '@mui/styles'
import { Box, IconButton, Typography } from '@mui/material'
import { formatSizeFile } from 'utils/formatSizeFile'
import LazyLoad from 'react-lazyload'

import CloseIcon from '@mui/icons-material/Close'

const Item = styled(Box)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
}))

export default function GridImage(props) {
  const { images, files, handleRemove, isRemove = true } = props

  const Container = styled(Box)(({ theme, length }) => ({
    position: 'relative',
    flexDirection: 'row',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat( auto-fit, minmax(150px, 175px))',
    gridTemplateRows: 217,
    gap: theme.spacing(1),
    '& img': {
      height: 150,
    },
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(4),
    },
  }))

  return (
    <Container length={images.length}>
      {isRemove
        ? images.map((img, ind) => (
            <Item key={Math.random(3128321381238128)} className={`gridItem grid-${ind + 1}`}>
              <LazyLoad>
                <img src={img?.source} alt={`grid-${ind + 1}`} />
              </LazyLoad>
              <IconButton
                sx={{
                  p: 0,
                  position: 'absolute',
                  top: 16,
                  left: 16,
                  bgcolor: 'primary.main',
                  '& svg': { fill: '#fff', fontSize: 18 },
                  '&:hover': { bgcolor: 'primary.main' },
                }}
                onClick={() => handleRemove(img?.id)}
              >
                <CloseIcon />
              </IconButton>
            </Item>
          ))
        : images.map((img, ind) => (
            <Item key={Math.random(3128381238128)} className={`gridItem grid-${ind + 1}`}>
              <LazyLoad>
                <img src={img} alt={`grid-${ind + 1}`} />
              </LazyLoad>
            </Item>
          ))}
    </Container>
  )
}
