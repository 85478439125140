import React from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'
import navigateMain from 'routes/navigateMain'
import { styled, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Stack, IconButton, Typography } from '@mui/material'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'

// icon
import MenuIcon from '@mui/icons-material/Menu'

// components
import AuthHeader from 'components/authentication/AuthHeader'
import Hamburger from 'components/header/Hamburger'
import { useI18n } from 'hooks'

// Sub dropdown Menu
import { LandingLegal, LandingInnovation, LandingExpertise, LandingLegalNews } from 'components/_expand-page/Landing'

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  top: 0,
  background: '#fff',
  zIndex: 10,
  padding: theme.spacing(1.8, 8.8),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 5),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 2),
  },
}))

// const RouterLinkStyle = styled(RouterLink)(({ active, theme }) => ({
//   color: active ? theme.palette.primary.main : '#000',
//   fontWeight: active ? 700 : 400,
//   transition: 'all 300ms ease',
//   '&:hover': {
//     color: theme.palette.primary.main,
//     fontWeight: 700,
//   },
// }))

// const NavLinkField = ({ children, to, active }) => {
//   return (
//     <Typography component={RouterLinkStyle} variant="subtitle2" color="primary" to={to} active={active}>
//       {children}
//     </Typography>
//   )
// }

export default function Header() {
  const { pathname } = useLocation()
  const { getLanguage } = useI18n()

  const theme = useTheme()
  const [openHamburger, setOpenHamburger] = React.useState(true)

  const navigate = useNavigate()

  // responsive
  const matchesAuth = useMediaQuery(theme.breakpoints.down(1200))
  const matchesSize = useMediaQuery(theme.breakpoints.down(1285))

  React.useEffect(() => {
    if (!matchesAuth) return
    setOpenHamburger(false)
  }, [matchesAuth])

  const handleToggleHamburger = event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setOpenHamburger(!openHamburger)
  }

  const [open, setOpen] = React.useState('')
  const anchorRef = React.useRef(null)

  const closeDropdown = () => {
    setOpen('')
  }

  const landings = {
    'Your Toolkit': <LandingLegal closeDropdown={closeDropdown} />,
    Areas: <LandingExpertise closeDropdown={closeDropdown} />,
    News: <LandingLegalNews isDropdown closeDropdown={closeDropdown} />,
    Innovation: <LandingInnovation closeDropdown={closeDropdown} />,
  }

  const handleToggle = title => {
    setOpen(title)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  // const prevOpen = React.useRef(open)

  const getScreenWidth = () => {
    return window.outerWidth
  }

  // function handleListKeyDown(event) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault()
  //     setOpen(false)
  //   } else if (event.key === 'Escape') {
  //     setOpen(false)
  //   }
  // }
  return (
    <Root sx={{ boxShadow: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <Stack
        sx={{ maxWidth: '1285px', width: '100%' }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" alignItems="start" spacing={0.25}>
          <Typography
            component={RouterLink}
            to={PATH_PAGE.root}
            variant="h6"
            color="primary"
            sx={{ textTransform: 'uppercase', fontWeight: 900 }}
          >
            vietnamlawinsight
          </Typography>

          {/* <Typography
            component={RouterLink}
            to={PATH_PAGE.root}
            variant="subtitle2"
            mt="0"
            sx={{ fontWeight: 400, fontSize: 10, color: '#666' }}
          >
            a product by LNT & Partners
          </Typography> */}
        </Stack>
        <>
          {!matchesAuth ? (
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
              {navigateMain.map(link => {
                if (link?.hidden) return
                return (
                  <>
                    <Button
                      key={link.title}
                      // to={link.path}
                      // active={pathname ? pathname.includes(link.path) : false}
                      onClick={() => navigate(link.path)}
                      onMouseOver={() => handleToggle(link.title)}
                      // onMouseLeave={() => handleToggle('')}
                      sx={{
                        fontWeight: pathname && link.path && pathname === link.path ? '600' : '500',
                        color: pathname && link.path && pathname === link.path ? '#ca3636' : 'black !important',
                      }}
                    >
                      {getLanguage === 'en' ? link.title : link.titleVn}
                    </Button>

                    {link.title !== 'Home' && link.title !== 'About Us' && link.title !== 'News' ? (
                      <Popper
                        open={open === link.title}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom"
                        transition
                        disablePortal
                        style={{
                          alignSelf: 'center',
                          maxWidth: matchesSize ? '100%' : '1285px',
                          top: '69px',
                          left: matchesSize ? 40 : (getScreenWidth() - 1290) / 2 - 37,
                          right: matchesSize ? 80 : (getScreenWidth() - 1290) / 2 - 37,
                          borderRadius: '0px !important',
                          // padding: 10,
                          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 20px -5px, rgba(0, 0, 0, 0.3) 0px 5px 10px -5px',
                          // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                        }}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === 'bottom-center' ? 'left top' : 'right top',
                              paddingLeft: 10,
                              paddingRight: 10,
                              maxWidth: '1295px',
                              height: '530px',
                              borderRadius: '0px !important',
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  // onKeyDown={handleListKeyDown}
                                  sx={{ height: '100%' }}
                                >
                                  {landings[link.title]}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    ) : null}
                  </>
                )
              })}
            </Stack>
          ) : (
            ''
          )}
        </>
        <AuthHeader>
          {matchesAuth ? (
            <>
              <IconButton sx={{ p: 0 }} onClick={handleToggleHamburger}>
                <MenuIcon />
              </IconButton>
              <Hamburger open={openHamburger} toggleOpen={handleToggleHamburger} />
            </>
          ) : (
            ''
          )}
        </AuthHeader>
      </Stack>
    </Root>
  )
}
