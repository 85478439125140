import axios from 'utils/axios'
import { GET_API, POST_API, UPDATE_API, DELETE_API } from 'routes/api'
import { SET_POSTS_GROUP, HANDLE_MESSAGE } from './PostSlice'

const { createSlice } = require('@reduxjs/toolkit')

const groupSlice = createSlice({
  name: 'group',
  initialState: {
    groups: [],
    groupDetail: null,
    alertJoinedGroup: { open: false, id: null },
  },
  reducers: {
    SET_GROUPS: (state, action) => {
      state.groups = action.payload
    },
    SET_GROUP_DETAIL: (state, action) => {
      state.groupDetail = action.payload
      state.isError = false
    },
    SET_ALERT_JOINED_GROUP: (state, action) => {
      state.alertJoinedGroup = action.payload
    },
  },
})

const { reducer, actions } = groupSlice

export const { SET_GROUPS, SET_ALERT_JOINED_GROUP } = actions

export default reducer

export function GET_GROUPS() {
  return async dispatch => {
    // dispatch(groupSlice.actions.LOGIN_LOADING())
    try {
      const response = await axios.get(GET_API().groups)

      dispatch(groupSlice.actions.SET_GROUPS(response.data.groups))
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function GET_GROUP_BY_ID(id, query) {
  return async dispatch => {
    try {
      const response = await axios.get(GET_API(id, query).groupById)
      if (response.status === 'error') {
        return dispatch(HANDLE_MESSAGE({ status: response.data.status, message: response.data.message }))
      }
      
      dispatch(groupSlice.actions.SET_GROUP_DETAIL(response.data.group))
      dispatch(SET_POSTS_GROUP(response.data.posts)) // set post of group in postSlice
    } catch (error) {
      console.log('err', error)
    }
  }
}

export function HANDLE_JOIN_GROUP(id, value = false, isGroupDetail = false) {
  return async (dispatch, getState) => {
    try {
      // remove join group

      const currentState = getState().group
      const getUser = getState().auth.user

      // out group
      if (value) {
        const response = await axios.patch(UPDATE_API(id).removeJoinGroup)
        if (response.status === 'error') {
          return dispatch(HANDLE_MESSAGE({ status: response.data.status, message: response.data.message }))
        }

        const newGroups = currentState.groups.map(group => {
          if (group?._id !== id) return group
          return {
            ...group,
            members: group.members.filter(member => member !== getUser?.id),
            numMembers: group.numMembers - 1,
          }
        })
        dispatch(groupSlice.actions.SET_GROUPS(newGroups))

        const newGroup = { ...currentState.groupDetail }

        if (newGroup?._id) {
          newGroup.members = newGroup.members.filter(member => member !== getUser?.id)
          newGroup.numMembers -= 1
          dispatch(groupSlice.actions.SET_GROUP_DETAIL(newGroup))
        }
      } else {
        // add group
        const response = await axios.patch(UPDATE_API(id).joinGroup)
        if (response.status === 'error') {
          return dispatch(HANDLE_MESSAGE({ status: response.data.status, message: response.data.message }))
        }

        const newGroups = currentState.groups.map(group => {
          if (group?._id !== id) return group
          return { ...group, members: [getUser?.id, ...group.members], numMembers: group.numMembers + 1 }
        })
        dispatch(groupSlice.actions.SET_GROUPS(newGroups))

        // update group detail

        const newGroup = { ...currentState.groupDetail }
        if (newGroup?._id) {
          newGroup.members = [...newGroup.members, getUser?.id]
          newGroup.numMembers += 1
          dispatch(groupSlice.actions.SET_GROUP_DETAIL(newGroup))
        }
      }
      console.log('handle mesage')
      dispatch(HANDLE_MESSAGE({ status: 'success', message: 'You have joined this group' }))
      if (value) {
        dispatch(groupSlice.actions.SET_ALERT_JOINED_GROUP({ open: false, id: null }))
      }
    } catch (error) {
      console.log('err', error)
    }
  }
}
