import React from 'react'

import { useAuth, useGroup, useI18n } from 'hooks'
import { styled, useTheme } from '@mui/styles'
import { Box, Typography, Skeleton, Stack, useMediaQuery } from '@mui/material'
import { TitleMain } from 'components/_expand-page/Forum/styles'

import { GroupItem } from './index'
import DialogAlert from 'components/DialogAlert'
import { useNavigate } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'

const Root = styled(Box)(({ theme }) => ({
  borderRadius: 5,
  overflow: 'hidden',
  boxShadow:
    '0px 2px 3px -1px rgb(144 144 150 / 20%), 0px 5px 6px 0px rgb(144 144 150 / 14%), 0px 1px 10px 0px rgb(144 144 150 / 12%)',

}))

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 2.5),
}))

const SkeletonCustom = () => {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Skeleton variant="circular" width={32} height={32} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1 }} spacing={2}>
        <Stack sx={{ flexGrow: 1 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" width="60%" />
        </Stack>
        <Skeleton variant="rectangular" width={50} />
      </Stack>
    </Stack>
  )
}

export default function Groups(props) {
  const { groups, handleJoin, alertJoinedGroup, handleOpenAlertJoinedGroup, isDisabled = false } = props
  const { user } = useAuth()
  const { t } = useI18n({ keyPrefix: 'forum' })

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down(320))
  const navigate = useNavigate()

  // {/* isDisabled is a landing page-only attribute */}
  const handleJoinCustom = props => {
    console.log('handleJoinCustom', props)
    if (!isDisabled) return handleJoin(props)
    navigate(`${PATH_PAGE.group}/${props.id}`)
  }

  return (
    <Root>
      <TitleMain>
        <Typography variant="subtitle2Res" sx={{ color: '#fff' }}>
          {t('title.group')}
        </Typography>
      </TitleMain>
      <Container spacing={2}>
        {groups?.length
          ? groups.map((d, index) => (
              <GroupItem
                key={d._id}
                title={d.title}
                groupID={d._id}
                numMembers={d.numMembers}
                members={d.members}
                handleClick={handleJoinCustom}
                userID={user?.id}
                bg={d.ImgUrl}
                matchesMobile={matchesMobile}
                lastItem={groups.length === index + 1}
                t={t}
              />
            ))
          : [1, 2, 3, 4].map(item => <SkeletonCustom key={item} />)}
      </Container>

      <DialogAlert
        open={alertJoinedGroup?.open}
        handleClose={() => handleOpenAlertJoinedGroup({ open: false, id: null })}
        handleAccept={(id, value) => handleJoin({ id, value, acceptJoined: true })}
        id={alertJoinedGroup?.id}
        title={t('dialogJoined.title')}
        textYes={t('input.continue')}
      >
        {t('dialogJoined.text')}
      </DialogAlert>
    </Root>
  )
}
