import React from 'react'
import { styled } from '@mui/styles'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@mui/material'
import { MButton } from 'components/_material-extend'
import LoadingForm from 'components/LoadingForm'
import { useI18n } from 'hooks'

const MButtonStyle = styled(MButton)(({ theme }) => ({
  padding: theme.spacing(1, 4),
  fontSize: 12,
}))

export default function ForumPostDelete(props) {
  const { handleClose, open, handleSubmit, name } = props
  const { t } = useI18n({ keyPrefix: 'forum' })

  const [disableBtn, setDisableBtn] = React.useState(false)

  React.useEffect(() => {
    setDisableBtn(false)
  }, [open])

  const handleDelete = () => {
    setDisableBtn(true)
    handleSubmit(name, 'delete')
  }
  return (
    <Dialog
      open={open}
      onClose={() => handleClose('')}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 9999999 }}
    >
      <DialogTitle id="alert-dialog-title">{t('dialogDeletePost.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t('dialogDeletePost.text')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MButtonStyle variant="gray" onClick={() => handleClose('')}>
          {t('input.cancel')}
        </MButtonStyle>
        <MButtonStyle variant="contained" onClick={handleDelete} disabled={disableBtn}>
          {!disableBtn ? t('input.accept') : <CircularProgress sx={{ color: '#fff' }} size={20} />}
        </MButtonStyle>
      </DialogActions>

      <LoadingForm open={disableBtn} handleClose={e => console.log(e)} />
    </Dialog>
  )
}
