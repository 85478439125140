import { Typography, Box } from '@mui/material'
import { styled, useTheme } from '@mui/styles'
import lineClamp from 'components/LineClamp'

const TitleField = ({ title, children, grid, isDropdown }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        gridArea: isDropdown && `grid-${grid}`,
        textAlign: isDropdown ? 'start' : 'center',
        width: isDropdown ? '90%' : '80%',
        margin: '0 auto',
        mt: 4,
        mb: isDropdown ? 0 : 5,
        transition: 'all 300ms ease',
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
        },
        [theme.breakpoints.down('sm')]: {
          mb: 4,
        },
      }}
    >
      <Typography sx={{ mb: isDropdown ? 1 : 1 }} variant="h4">
        {title}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          color: '#909096',
          fontSize: isDropdown ? 14 : 18,
          
          // ...lineClamp(2),
          textAlign: isDropdown ? 'start' : 'center',
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
          },
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

const Root = styled(Box)(({ theme, isDropdown }) => ({
  padding: !isDropdown ? theme.spacing(0, 10, 0) : 0,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(10, 5, 0),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(10, 1, 0),
  },
}))

export { TitleField, Root }
