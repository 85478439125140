import React from 'react'
import Slider from 'react-slick'
import { useI18n, useNews } from 'hooks'

import { makeStyles, styled, useTheme } from '@mui/styles'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { TitleField, Root } from './styles'

import LandingLegalNewsItem from './LandingLegalNewsItem'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Rectangle41 from 'assets/images/news/Rectangle 2.png'
// import { Stack } from 'immutable'

const styles = makeStyles(theme => ({
  slide: {
    '& .slick-arrow': {
      zIndex: 2,
      '&.slick-prev': {
        left: -35,
      },
      '&::before': {
        color: theme.palette.primary.main,
        fontSize: 32,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none!important',
      },
    },
  },
}))

const GridContainer = styled(Box)(({ theme }) => ({
  height: 400,
  display: 'grid!important',
  gridTemplateColumns: '7fr 7fr 7fr 1fr 7fr 7fr 7fr',
  gridTemplateRows: '50% 50%',

  gridTemplateAreas: `
    'grid-0 grid-0 grid-0 grid-9 grid-1 grid-2 grid-3'
    'grid-0 grid-0 grid-0 grid-9 grid-4 grid-5 grid-6'
  `,
}))

export default function LandingLegalNews({ isDropdown, closeDropdown }) {
  const classes = styles()
  const { handleGetNews, news } = useNews()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'))
  const matches1080 = useMediaQuery(theme.breakpoints.down(1080))
  const { t, getLanguage } = useI18n({ keyPrefix: 'home.legalNews' })

  React.useEffect(() => {
    handleGetNews()
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 4,
        },
      },
    ],
  }
  if (!isDropdown) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Root sx={{ mb: '15px', display: 'flex', flexDirection: 'column', maxWidth: '1285px' }}>
          <TitleField title={t('title')}>{t('title_des')}</TitleField>
          <Box sx={{ mb: 5, px: matches1080 ? 12 : matches ? 8 : 2 }}>
            <Slider {...settings} className={classes.slide}>
              {news.map(d => (
                <LandingLegalNewsItem
                  id={d.id}
                  title={d.title}
                  date={new Date()}
                  poster={d?.banner_url || Rectangle41}
                  key={d.id}
                >
                  {d?.html}
                </LandingLegalNewsItem>
              ))}
            </Slider>
          </Box>
        </Root>
      </div>
    )
  }
  return (
    <Root isDropdown={isDropdown} sx={{ mb: isDropdown ? 0 : 15, backgroundColor: isDropdown ? 'white' : null }}>
      <GridContainer>
        <TitleField isDropdown grid={0} title={t('title')}>
          {t('title_des')}
        </TitleField>
        <div style={{ gridArea: 'grid-9', height: 400, alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ marginRight: 1, borderRight: '1px solid #eeeded', height: 400 }} />
        </div>
        {news.map((d, index) => {
          if (index > 5) return
          return (
            <LandingLegalNewsItem
              grid={index + 1}
              id={d.id}
              title={d.title}
              date={new Date()}
              poster={d?.banner_url || Rectangle41}
              key={d.id}
              isDropdown={isDropdown}
              closeDropdown={closeDropdown}
            >
              {d?.html}
            </LandingLegalNewsItem>
          )
        })}
      </GridContainer>
    </Root>
  )
}
