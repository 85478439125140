const lineClampStyle = ({ line = 1, isDropdown, isInDetail, fontSize }) => {
  return {
    display: ' -webkit-box',
    WebkitLineClamp: !isInDetail ? `${line}` : '',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'left',
    fontWeight: isDropdown && 500,
    fontSize: fontSize ? fontSize : '14px',
    lineHeight: '19px',
    maxHeight: '57px',
  }
}

export default lineClampStyle
