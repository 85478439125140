import React from 'react'
import { useSnackbar } from 'notistack'

// import { useSelector, useDispatch } from 'react-redux'
// import { UPLOAD_IMAGE_FILE, DELETE_IMAGE_FILEM } from 'store/reducers/FileSlice'

const acceptImage = ['png', 'jpg', 'jpeg', 'pdf', 'JPG']

export default function useUploadFiles() {
  // const dispatch = useDispatch()
  const [sourceImg, setSourceImg] = React.useState([])
  const [imgFiles, setImgFiles] = React.useState([])
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {}, [])

  const validateFile = info => {
    const { name, size } = info
    const regex = new RegExp('[^.]+$')
    const extension = name.match(regex)
    const mb = size / 10000000
    const find = acceptImage.findIndex(ext => ext === extension[0])
    if (find === -1) {
      enqueueSnackbar('File không hợp lệ', { variant: 'warning' })
      return false
    }
    if (mb > 10) {
      enqueueSnackbar('File tối đa 10MB', { variant: 'warning' })
      return false
    }
    return true
  }

  //---------------------
  let arrNew = []
  const arrSource = []
  let timer = null

  const handleNewField = ({ file, ...props }) => {
    arrNew.push(file)
    arrSource.push(props)

    if (file) {
      clearTimeout(timer)
    }
    // setDocFiles
    timer = setTimeout(() => {
      // dispatch(UPLOAD_IMAGE_FILE({file: arrNew}))
      setImgFiles([...imgFiles, ...arrNew])
      setSourceImg([...arrSource, ...sourceImg])
      arrNew = []
    }, 1000)
  }

  //----------------
  const handleChangeFile = event => {
    const { files } = event.target

    const response = Object.entries(files).map(key => validateFile(key[1]))
    const results = response.every(result => result)
    // files is : [true, true, false] => return false
    if (!results) {
      return enqueueSnackbar('Upload file không thành công', { variant: 'error' })
    }

    Object.entries(files).map(file => {
      const reader = new FileReader()
      reader.onload = e =>
        handleNewField({ file: file[1], source: e.target.result, id: Math.floor(Math.random() * 100000000 + 1) })
      file && reader.readAsDataURL(file[1])
      return false
    })
  }

  // ---- example
  // const handleRemove = id => {
  //   const getIndex = sourceImg.findIndex(source => source.id === id)
  //   const newSource = sourceImg.filter(source => source.id !== id)
  //   const newFiles = imgFiles
  //   newFiles.splice(getIndex, 1)

  //   setSourceImg(newSource)
  //   setImgFiles(newFiles)
  // }

  const handleReset = () => {
    setSourceImg([])
    setImgFiles([])
  }

  return { handleChangeFile, handleReset, sourceImg, imgFiles, validateFile }
}
